import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import schemaClassification from '../child-nodes-schema/schemaType-classification.json'
import { ElementUIFacade } from '../ElementUIFacade'
import { TreeElementObject } from './constructArrayFlattenElementsBuildTree'

const buildChildrenTree = (
	listFlattenElements: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	selectorValueRef: MutableRefObject<number>,
	eleObj: TreeElementObject
) => {
	const prefixes: Array<string> = schemaClassification[eleObj.id as keyof typeof schemaClassification].arrayChild
	const groupElements = prefixes.reduce((acc: Array<ElementUIFacade>, prefix: string) => {
		const elementList = listFlattenElements.filter((element) => element.elementId.includes('_' + prefix))
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: selectorValueRef,
				isSelectedByDefault: eleObj.isRequiredByDefault?.indexOf(prefix) > -1
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			acc.push(groupParent)
		}
		return acc
	}, [])
	return groupElements
}

export const buildTree = (
	listFlattenElements: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	selectorValueRef: MutableRefObject<number>,
	eleObj: TreeElementObject
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElements.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElements[0].blockId || ''),
			elementId: eleObj.id,
			displayName: t(`${eleObj.id}.element`),
			description: t(`${eleObj.id}.description`),
			level: eleObj.level,
			dataType: t('ARRAY'),
			showChildrenBlocks: eleObj.showChildrenBlocks,
			containsChildrenSelector: eleObj.containsChildrenSelector,
			considerChildrenForCount: eleObj.considerChildrenForCount,
			maximumElementsInOutput: selectorValueRef,
			outputDropdownOptions: [...Array(eleObj.optionsLength).keys()].map((i) => ({
				value: (i + 1).toString(),
				label: (i + 1).toString()
			}))
		}

		if (eleObj.isClassificationsPattern) {
			root.isClassificationsPattern = true
			const optionsClassification: Array<string> =
				schemaClassification[eleObj.id as keyof typeof schemaClassification].classifications
			root.classifications = optionsClassification.map((classification: string) => {
				return { value: classification, id: classification, isChecked: false, label: classification }
			})
		}

		tree.push(root)
		root.childs = buildChildrenTree(listFlattenElements, root, t, selectorValueRef, eleObj)
	}
	return tree
}
