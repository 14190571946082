import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import organizationEmailPrefixes from '../organization-email-prefixes.json'

const buildChildrenTree = (
	listFlattenElementsOrganizationEmail: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	organizationEmailSelectorValueRef: MutableRefObject<number>
) => {
	const groupElements = organizationEmailPrefixes.reduce((acc: Array<ElementUIFacade>, prefix: string) => {
		const elementList = listFlattenElementsOrganizationEmail.filter((element) =>
			element.elementId.startsWith(prefix)
		)
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: organizationEmailSelectorValueRef,
				isSelectedByDefault: prefix === 'organization_email_address_'
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			acc.push(groupParent)
		}
		return acc
	}, [])
	return groupElements
}

export const buildOrganizationEmailTree = (
	listFlattenElementsOrganizationEmail: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	organizationEmailSelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsOrganizationEmail.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsOrganizationEmail[0].blockId || ''),
			elementId: 'organizationEmailId',
			displayName: t('organizationEmailId.element'),
			description: t('organizationEmailId.description'),
			level: '1',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: organizationEmailSelectorValueRef,
			outputDropdownOptions: [...Array(5).keys()].map((i) => ({
				value: (i + 1).toString(),
				label: (i + 1).toString()
			}))
		}
		tree.push(root)

		root.childs = buildChildrenTree(
			listFlattenElementsOrganizationEmail,
			root,
			t,
			organizationEmailSelectorValueRef
		)
	}
	return tree
}
