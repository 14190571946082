import { ColorGray } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton, DNBDialog, DNBInputLabel, DNBSelect, DNBSelectOption } from '@dnb-uux-design-system/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../local-core-ui'
import styles from './order-reason-modal.module.scss'

interface OrderReasonModalProps {
	onModalClose(): void
	showOrderReason: boolean
	onAddOrderReason(value: string): void
}
export const OrderReasonModal = ({ showOrderReason, onAddOrderReason, onModalClose }: OrderReasonModalProps) => {
	const [orderReason, setOrderReason] = useState<string>('')
	const { t } = useTranslation()

	const orderReasonCollection = [
		{
			value: '6332',
			label: '6332 (Credit Decision)'
		},
		{
			value: '6333',
			label: '6333 (Credit Check - Intended Business Connection)'
		},
		{
			value: '6334',
			label: '6334 (Credit Check - Ongoing Business Connection)'
		},
		{
			value: '6335',
			label: '6335 (Debt Collection)'
		},
		{
			value: '6336',
			label: '6336 (Commercial Credit Insurance)'
		},
		{
			value: '6337',
			label: '6337 (Insurance Contract)'
		},
		{
			value: '6338',
			label: '6338 (Lease Agreement)'
		},
		{
			value: '6339',
			label: '6339 (Rental Agreement)'
		}
	]

	const handleAddOrderReason = () => {
		onAddOrderReason(orderReason)
		onModalClose()
	}

	return (
		<DNBDialog open={showOrderReason} onClose={onModalClose} title={t('order.reason.modal.title') ?? ''}>
			<p>{t('order.reason.modal.content')}</p>
			<DNBInputLabel size="default">{t('order.reason.modal.select.label')}</DNBInputLabel>
			<DNBSelect
				size="default"
				value={orderReason}
				id={`order-reason-selector`}
				placeholder={t('order.reason.modal.select.placeholder') ?? ''}
				onChange={(_event: any, selection: string) => setOrderReason(selection)}
			>
				{orderReasonCollection.map((orderReason) => (
					<DNBSelectOption key={orderReason.value} value={orderReason.value}>
						{orderReason.label}
					</DNBSelectOption>
				))}
			</DNBSelect>
			<Divider color={ColorGray} />
			<div className={styles.containerButtonClose}>
				<DNBButton
					size="small"
					variant="secondary"
					onClick={() => onModalClose()}
					data-testid={'close-modal-button'}
				>
					{t('template.deprecated.field.modal.cancel')}
				</DNBButton>
				<DNBButton
					size="small"
					variant="primary"
					onClick={handleAddOrderReason}
					data-testid={'add-modal-button'}
				>
					{t('user.tile.button.add')}
				</DNBButton>
			</div>
		</DNBDialog>
	)
}
