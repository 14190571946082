import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../../../../design-tokens/build/shared/token-colors.json'
import { Divider, Modal } from '../../../../local-core-ui'
import styles from './deleted-custom-fields-modal.module.scss'

export interface DeletedField {
	name: string
	apiFileName: string
}

interface DeletedCustomFieldsModalProps {
	open: boolean
	testId: string
	deletedFields?: DeletedField[]

	onCloseModal(): void
}

export const DeletedCustomFieldsModal = ({
	open,
	onCloseModal,
	deletedFields,
	testId
}: DeletedCustomFieldsModalProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<Modal open={open} testId={testId} isContainer={true} showButtonClose={false} fixWidth={false}>
			<div className={styles.modalContainer}>
				<div className={styles.title}>{t('enrich.crm.deleted.custom.fields.title')}</div>
				<div className={styles.message}>{t('enrich.crm.deleted.custom.fields.message')}</div>
				<div className={styles.message}>{t('enrich.crm.deleted.custom.fields.message.2')}</div>
				<section className={styles.tableContainer}>
					<div className={styles.divider} />
					<div className={styles.tableHeader}>
						<div className={`${styles.nameCol} ${styles.col}`}>
							{t('enrich.crm.deleted.custom.fields.label')}
						</div>
						<div className={`${styles.connectorCol} ${styles.col}`}>
							{t('enrich.crm.deleted.custom.fields.api.label')}
						</div>
					</div>
					{deletedFields?.map((field, key) => (
						<div key={`${field.name}-${key}`}>
							{key === 0 ? <div className={styles.dividerG} /> : <Divider color={ColorGrayDark} />}
							<div className={styles.row}>
								<div className={`${styles.nameCol} ${styles.col}`}>{field.name}</div>
								<div className={`${styles.connectorCol} ${styles.col}`}>{field.apiFileName}</div>
							</div>
						</div>
					))}
					<Divider color={ColorGrayDark} />
				</section>
			</div>
			<div className={styles.closeContainer}>
				<DNBButton
					size="small"
					onClick={() => onCloseModal()}
					data-testid={`${testId}-close`}
					className={styles.closeButton}
				>
					{t('enrich.crm.deleted.custom.fields.continue')}
				</DNBButton>
			</div>
		</Modal>
	)
}
