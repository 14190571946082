import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import world from '../../../local-core-ui/assets/data/world.json'
import { setConnectManageSubStep } from '../../../store/projectWizard/actions/setConnectManageSubStep'
import { createOrUpdateMatchRules, createOrUpdateSource } from '../../../store/projectWizard/thunks'
import { MatchRuleInfo, Project, ProjectWizardState, SPECIAL_RULE } from '../../../store/projectWizard/types'
import { EntityType } from '../../../types/sources/EntityType'
import { WizardComposerChooseMatch } from './choose-match/WizardComposerChooseMatch'
import { MatchConnectManage } from './match-connect-manage/match-connect-manage'
import { WizardComposerMatching } from './match-step/WizardComposerMatching'
import { MatchSummary } from './match-summary/match-summary'

const isMatchRulesReadyToValidate = (matchRules: Array<MatchRuleInfo>): boolean => {
	let allRequiredFieldsFilled = true
	if (matchRules.length > 0) {
		let totalSelected = 0
		for (const matchRuleInfo of matchRules) {
			totalSelected += matchRuleInfo.matchRule.allowedValues?.length || 0
			if (
				matchRuleInfo.matchRule.ruleType === SPECIAL_RULE &&
				(!matchRuleInfo.matchRule.allowedValues || matchRuleInfo.matchRule.allowedValues.length <= 0)
			) {
				allRequiredFieldsFilled = false
				break
			}
		}
		if (totalSelected === world.features.length) {
			allRequiredFieldsFilled = false
		}
	} else {
		allRequiredFieldsFilled = false
	}

	return allRequiredFieldsFilled
}

export const WizardComposerMatchingStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	const hasConnectManageInfo =
		context.projectWizardState.currentProject.source.integrationInfo?.connectManageInfo !== undefined

	function baseRuleChanged(projectWizardState: ProjectWizardState): boolean {
		if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId) {
			return true
		} else {
			const baseRule = projectWizardState.currentProject.matchRules[0]
			return (
				baseRule?.advancedSettingsCollapsed === false ||
				projectWizardState.currentProject.matchRules.length > 1 ||
				baseRule?.matchRule.displayName !== 'Global Rule' ||
				baseRule?.matchRule.acceptCriterion.LowestConfidenceCode !== 6
			)
		}
	}

	function isNextEnabled(projectWizardState: ProjectWizardState): boolean {
		return (
			projectWizardState.currentProject.matchRules.length > 0 &&
			isMatchRulesReadyToValidate(projectWizardState.currentProject.matchRules) &&
			!projectWizardState.loadingNextStep
		)
	}

	function isNextEnabledConnectManage(project: Project): boolean {
		const connectManageInfo = project.source.integrationInfo?.connectManageInfo
		if (connectManageInfo?.connectManageEnabled === true) {
			return !!(connectManageInfo?.connectionName && connectManageInfo?.externalSourceId)
		} else return true
	}

	const basicSubSteps = [
		{
			title: 'project.wizard.step.choose.match',
			state: () =>
				context.projectWizardState.currentProject.matchingApproach !== undefined
					? WizardComposerSubStepStates.complete
					: WizardComposerSubStepStates.pending,
			onNext: undefined,
			onPrevious: undefined,
			isNextEnabled: () => baseRuleChanged(context.projectWizardState),
			route: { path: '/chooseMatch', component: WizardComposerChooseMatch }
		},
		{
			title: 'file.matching.matching',
			state: () =>
				context.projectWizardState.currentProject.matchRulesValidated
					? WizardComposerSubStepStates.complete
					: WizardComposerSubStepStates.pending,
			onNext: async () => {
				await context
					.dispatch(createOrUpdateMatchRules())
					.then(async () => {
						if (context.projectWizardState.currentProject.source.entityType === EntityType.CONTACTS)
							await context
								.dispatch(
									createOrUpdateSource(
										false,
										context.enableApi,
										context.salesforcePlatform,
										context.enableEnrichedOnlyMixedFile
									)
								)
								.then(() => {
									context.queryClient.invalidateQueries([
										'getSources',
										{
											sourceId: context.projectWizardState.currentProject.source.id,
											inflateMetadata: false
										}
									])
									context.queryClient.invalidateQueries([
										'getProjects',
										context.projectWizardState.currentProject.id
									])
								})
					})
					.catch((err) => {
						throw err
					})
			},
			onPrevious: undefined,
			isNextEnabled: () => isNextEnabled(context.projectWizardState),
			route: { path: '/matching', component: WizardComposerMatching }
		},
		{
			title: 'matching.step.summary.title',
			state: () => {
				return WizardComposerSubStepStates.pending
			},
			onNext: undefined,
			onPrevious: undefined,
			isNextEnabled: () => {
				return true
			},
			route: { path: '/summary', component: MatchSummary }
		}
	]

	const connectManageSubstep = {
		title: 'matching.step.connect.manage',
		state: () => {
			return context.projectWizardState.currentProject.source.integrationInfo?.completedConnectManageSubStep ===
				true
				? WizardComposerSubStepStates.complete
				: WizardComposerSubStepStates.pending
		},
		onNext: async () => {
			await context
				.dispatch(
					createOrUpdateSource(
						false,
						context.enableApi,
						context.salesforcePlatform,
						context.enableEnrichedOnlyMixedFile
					)
				)
				.then(() =>
					context.queryClient.invalidateQueries([
						'getSources',
						{
							sourceId: context.projectWizardState.currentProject.source.id,
							inflateMetadata: false
						}
					])
				)
				.catch((err) => {
					throw err
				})
			await context.dispatch(setConnectManageSubStep(true))
		},
		onPrevious: undefined,
		isNextEnabled: () => {
			return isNextEnabledConnectManage(context.projectWizardState.currentProject)
		},
		route: { path: '/connect_manage', component: MatchConnectManage }
	}

	const getSubsteps = (): WizardComposerSubStepDefinition[] => {
		const subSteps = basicSubSteps
		if (
			context.projectWizardState.currentProject.source.isFile &&
			(context.isConnectManage || hasConnectManageInfo)
		) {
			subSteps.push(connectManageSubstep)
		}
		return subSteps
	}

	return {
		title: 'project.wizard.step.title.configure.match',
		progress: () =>
			context.projectWizardState.currentProject.matchRules.length > 0 &&
			context.projectWizardState.currentProject.matchRulesValidated
				? 100
				: isMatchRulesReadyToValidate(context.projectWizardState.currentProject.matchRules) &&
				  context.projectWizardState.currentProject.matchingApproach
				? 50
				: 0,
		onNext: async () => {
			await context
				.dispatch(createOrUpdateMatchRules())
				.then()
				.catch((err) => {
					throw err
				})
		},
		onPrevious: undefined,
		subSteps: () => getSubsteps()
	}
}
