import { TFunction } from 'react-i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import thirdPartyAssessmentsPrefixes from '../third-party-assessments-element-prefixes.json'
import thirdPartyAssessmentsSuffixes from '../third-party-assessments-element-suffixes.json'

const extractThirdPartyAssessmentOptions = (t: TFunction<'translation'>) =>
	thirdPartyAssessmentsPrefixes.map((prefix) => {
		return { label: t(`${prefix}.element`), value: prefix }
	})

const extractSelectedOptions = (selectedElements: Array<ElementUIFacade>) => {
	const selectedOptions: Array<string> = []
	thirdPartyAssessmentsPrefixes.forEach((prefix) => {
		if (selectedElements.some((elem) => elem.elementId.startsWith(prefix))) {
			selectedOptions.push(prefix)
		}
	})
	return selectedOptions
}

const buildChildrenTree = (
	listFlattenElementsTPA: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>
) => {
	const groupElements: Array<ElementUIFacade> = []
	thirdPartyAssessmentsSuffixes.forEach((suffix) => {
		const elementList = listFlattenElementsTPA.filter((tpaElement) => tpaElement.elementId.endsWith(suffix))
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${suffix}Id`,
				displayName: t(`${suffix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				isSelectedByDefault: suffix === '_thirdpartyassessment_val'
			}
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildThirdPartyAssessmentsTree = (
	listFlattenElementsTPA: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	selectedElements: Array<ElementUIFacade>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsTPA.length) {
		const dropdownOptions = extractThirdPartyAssessmentOptions(t)
		const selectedOptions: Array<string> = extractSelectedOptions(selectedElements)
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsTPA[0].blockId || ''),
			elementId: 'thirdPartyAssessmentId',
			displayName: t('thirdPartyAssessment.element'),
			description: t('thirdPartyAssessment.description'),
			level: '3',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			considerChildrenForCount: true,
			multipleChildrenSelector: {
				dropdownOptions: dropdownOptions,
				selectedOptions: selectedOptions
			}
		}
		tree.push(root)

		root.childs = buildChildrenTree(listFlattenElementsTPA, root, t)
	}
	return tree
}
