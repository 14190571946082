import { AxiosInstance, AxiosResponse } from 'axios'
import { SnowflakeData } from '../../components/snowflake-form/snowflake-form'
import { System } from './getConnections'

export interface SuccessResponse {
	Success?: boolean
	Errors?: string[]
	name?: string
	system?: string
	displayName?: string
	created?: number
	updated?: number
	status?: string
	user?: string
}

export interface DataConnection {
	apiUrl?: string
	apiKey?: string
	nameConnection: string
	displayName: string
}

export const updateConnection = async (
	apiClient: AxiosInstance,
	data: SnowflakeData | DataConnection,
	systemType: System
): Promise<SuccessResponse> => {
	const url = `/pls/integration/${systemType}/organization/connection`
	return apiClient.post(url, data).then((response: AxiosResponse<SuccessResponse>) => response.data)
}
