import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { FlattenedArrayConfiguration } from '../types'
import { getFlattenedArraysConfiguration } from './api/getFlattenedArraysConfiguration'

export const useFlattenedArraysConfiguration = (): UseQueryResult<Array<FlattenedArrayConfiguration>> => {
	const apiClient = useApi()

	const queryKey = ['getFlattenedArraysConfiguration']
	const queryFunction = () => getFlattenedArraysConfiguration(apiClient)
	return useQuery(queryKey, queryFunction)
}
