import { LicenseType } from './LicenseType'
import { SessionAuthenticator } from './SessionAuthenticator'
import { SessionState } from './SessionState'
import { SessionStatus } from './SessionStatus'

export const InitialSessionState: SessionState = {
	status: SessionStatus.unauthenticated,
	authenticator: SessionAuthenticator.enterpriseSSO,
	token: '',
	tenant: '',
	tenants: [],
	username: '',
	entitlements: {},
	featureFlags: [],
	moduleFlags: [],
	user: null,
	signoutreason: undefined,
	environment: '',
	platform: '',
	orgid: '',
	isSandbox: false,
	integrationDomain: '',
	integrationUsername: '',
	currentLicense: { licenseType: LicenseType.LEGACY }
}
