import { ChangeEvent, DragEvent, FC, Ref, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RecordLimitExceeded } from '../../../../../components/record-limit-exceeded/record-limit-exceeded'
import { useWizardRouteMatch } from '../../../../../components/wizardComposer/hooks/useWizardRouteMatch'
import UploadParser from '../../../../../helpers/uploadParser'
import { useFeatures } from '../../../../../hooks/useEntitlements'
import { DragDrop, ErrorState, LoadingState, Modal } from '../../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../store'
import {
	setPercentageUploadedAction,
	updateCurrentProjectAction,
	uploadingFileAction
} from '../../../../../store/projectWizard/actions'
import { InitialProjectWizardState } from '../../../../../store/projectWizard/types'
import styles from '../selectInput.module.scss'

interface UploadFileProps {
	testId: string
	selectedDelimiter?: string
}

export const UploadFile: FC<UploadFileProps> = ({ testId, selectedDelimiter }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const currentLicense = session.currentLicense

	const enableFileDelimiters = useFeatures(['EnableFileDelimiters'])
	const sampleFileEl: Ref<HTMLAnchorElement> = useRef(null)
	const sampleFile = '/DnB Connect Sample CSV Template.csv'
	const [errorMessage, setErrorMessage] = useState<string | undefined | JSX.Element>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isRecordLimitModalOpen, setIsRecordLimitModalOpen] = useState<boolean>(false)
	const [retryText, setRetryText] = useState<string>('')
	const [totalRows, setTotalRows] = useState<number>(0)
	const [fileState, setFileState] = useState<File | null>(null)
	const [fileValid, setFileValid] = useState(false)
	const [fileApproved, setFileApproved] = useState(false)
	const [sizeLimitExceeded, setSizeLimitExceeded] = useState<boolean>(false)

	const fileSizeLimit = currentLicense.recordLimit !== undefined ? currentLicense.recordLimit : -1
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)

	const disabled =
		projectWizardState.currentProject.source.id !== undefined ||
		projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined

	const isFileReplaceable = projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id === undefined

	let delimiter = ''

	const cleanFile = () => {
		setIsLoading(false)
		setFileApproved(false)
		setFileValid(false)
		setFileState(null)
	}

	const isDragEvent = (
		event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>
	): event is DragEvent<HTMLInputElement> => {
		return (event as DragEvent<HTMLInputElement>).dataTransfer !== undefined
	}

	const getTotalRows = async (file: File, delimiter: string) => {
		return await UploadParser.totalRows(file, delimiter)
	}

	const mapFileAndRowsToFileInfo = async (file: File, firstRows: [][], delimiter: string) => {
		const firstElement = firstRows.shift()
		return {
			headers: firstElement || [],
			data: firstRows,
			file: file,
			name: file.name,
			uploadInfo: undefined,
			delimiter: delimiter,
			totalRows: totalRows
		}
	}

	const { next } = useWizardRouteMatch()

	const onFileSelect = async (file: File, firstRows: [][], delimiter: string): void => {
		dispatch(setPercentageUploadedAction(0))
		dispatch(
			updateCurrentProjectAction({
				fileInfo: await mapFileAndRowsToFileInfo(file, firstRows, delimiter),
				mappingInfo: InitialProjectWizardState.currentProject.mappingInfo
			})
		)
		dispatch(uploadingFileAction(false))
		next()
	}

	const getErrorMessage = (errorType: string, errorVariable?: string): string | JSX.Element => {
		switch (errorType) {
			case 'maxSizeError':
				return (
					<Trans i18nKey={'upload.tile.error.size.warning'}>
						your file exceeds limit
						<br />
						upload a smaller file
						<br />
					</Trans>
				)
			case 'rejectedFileError':
				return (
					<Trans
						i18nKey="upload.chooser.modal.content.drag.drop.error"
						tOptions={{ fileName: errorVariable, delimitedFiles: '.csv' }}
					>
						The file is not supported.
						<br /> Currently only files with extensions .csv are supported.
					</Trans>
				)
			case 'emptyFileError':
				return t('upload.tile.error.empty.file') as string
			case 'duplicityError':
				return (
					<Trans i18nKey={'upload.tile.error.duplicate.warning'}>
						your file contains duplicates
						<br />
						please resolve duplicates
						<br />
						<br />
						<strong>5</strong>
						<span>{{ errorVariable }}</span>
					</Trans>
				)
			case 'emptyHeader':
				return (
					<Trans i18nKey={'upload.tile.error.empty.header'}>
						detected missing column headers
						<br />
						please update your file
						<br />
						replace the file
						<br />
						you’ll be on your way
					</Trans>
				)
			case 'unsupportedCharError':
				return (
					<Trans i18nKey="upload.tile.error.unsupported.char" tOptions={{ fileName: errorVariable }}>
						The file name contains unsupported special characters.
						<br />
						We dont support the following special characters: @&$#[]()
					</Trans>
				)
			default:
				return ''
		}
	}

	const fileSelected = async (event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>, valid: boolean) => {
		let file: File | null = null
		let fileRows = 0

		setIsLoading(true)

		if (isDragEvent(event)) {
			file = event?.dataTransfer?.files[0]
		} else {
			const changeEvent: ChangeEvent<HTMLInputElement> = event
			if (changeEvent.target?.files) file = changeEvent.target.files[0]
		}

		if (file) {
			fileRows = await getTotalRows(file, delimiter)
			setTotalRows(fileRows)
			setSizeLimitExceeded(fileSizeLimit !== undefined && fileSizeLimit > -1 && fileRows > fileSizeLimit)
		}

		setFileState(file)
		setFileValid(valid)
	}

	const isEmpty = (text: string | null | undefined): boolean => {
		return text == null || text.match(/^\s*$/) !== null
	}

	const getDuplicateElements = (elements: Array<string>): Array<string> => {
		const elementList = elements.map((element) => element.toLowerCase().trim())
		return elementList.filter((element, index, arr) => arr.indexOf(element) !== index)
	}

	const onContinueRecordLimitModal = async () => {
		setIsRecordLimitModalOpen(false)
		setSizeLimitExceeded(false)
	}

	const onCancelRecordLimitModal = () => {
		setIsRecordLimitModalOpen(false)
		setSizeLimitExceeded(false)
		cleanFile()
	}

	useEffect(() => {
		const validateFile = async () => {
			const file = fileState
			const maxSize = 100 * 1024 * 1024 // 100Meg
			const minSize = 15 // 15 bytes
			const unsupportedChars = new RegExp(/[@&$#\[\]()]/, 'g') // Unsupported special characters @&$#[]()

			if (file) {
				setRetryText('')

				if (fileValid) {
					if (enableFileDelimiters) {
						delimiter =
							selectedDelimiter ||
							(await UploadParser.delimiter(file).catch(() => {
								delimiter = 'NOT_SUPPORTED'
							})) ||
							''
					}

					if (file.size > maxSize) {
						setErrorMessage(getErrorMessage('maxSizeError'))
						cleanFile()
					} else if (file.size < minSize) {
						setErrorMessage(getErrorMessage('emptyFileError'))
						cleanFile()
					} else if (unsupportedChars.test(file.name)) {
						setErrorMessage(getErrorMessage('unsupportedCharError', file.name))
						cleanFile()
					} else {
						if (sizeLimitExceeded) {
							setIsRecordLimitModalOpen(true)
						}

						setFileApproved(true)
					}
				} else {
					setErrorMessage(getErrorMessage('rejectedFileError', file.name))
					cleanFile()
				}
			}
		}

		validateFile()
	}, [fileState, fileValid, sizeLimitExceeded])

	useEffect(() => {
		const file = fileState

		const ingestFile = async (file: File) => {
			const numberOfRows = 20
			const results = await UploadParser.parse(file, numberOfRows, delimiter)

			getDuplicateElements(results[0])

			if (results && results.length > 0 && results[0]) {
				const emptyHeaders = results[0].filter((column) => isEmpty(column))
				const firstColumn = results[0]
				const header = firstColumn[0]
				const regExp = RegExp(/\d/, 'g')

				if (emptyHeaders.length || (firstColumn.length <= 1 && regExp.test(header))) {
					setErrorMessage(getErrorMessage('emptyHeader'))
					setIsLoading(false)
					setRetryText('upload.tile.error.retry.text')
				} else {
					const duplicateElements = getDuplicateElements(results[0])

					if (duplicateElements.length === 0) {
						if (results[1] && results[1].toString().length > 0) {
							onFileSelect(file, results, delimiter)
							setIsLoading(false)
						} else {
							setErrorMessage(getErrorMessage('emptyFileError'))
							setIsLoading(false)
						}
					} else {
						const duplicateElementsString = duplicateElements.join(', ')
						setErrorMessage(getErrorMessage('duplicityError', duplicateElementsString))
						setIsLoading(false)
					}
				}
			} else {
				setErrorMessage(getErrorMessage('emptyFileError'))
				setIsLoading(false)
			}
		}

		if (file && fileApproved && !sizeLimitExceeded) {
			ingestFile(file)
		}
	}, [fileApproved, sizeLimitExceeded])

	return (
		<>
			<div className={styles.dragAndDropComponentContainer}>
				{isLoading && <LoadingState loadingMessage={t('upload.chooser.loading.message') as string} />}
				{errorMessage && (
					<ErrorState
						errorMessage={errorMessage}
						onClose={() => setErrorMessage(undefined)}
						testId={testId + '-error'}
						retryText={t(retryText) as string}
						onRetry={() => {
							cleanFile()
							setErrorMessage(undefined)
						}}
					/>
				)}
				<Modal
					open={isRecordLimitModalOpen}
					showButtonClose={false}
					testId={`${testId}-record-limit-modal`}
					onClose={() => {
						console.log('without use but mandatpry prop')
					}}
				>
					<RecordLimitExceeded
						maxFileRows={fileSizeLimit}
						totalRows={totalRows}
						onCancel={onCancelRecordLimitModal}
						onContinue={onContinueRecordLimitModal}
						testId={`${testId}-record-limit`}
					/>
				</Modal>
				<DragDrop
					id="drag-and-drop"
					accept=".csv"
					label={
						<p className="choose-file-text">
							<Trans i18nKey="upload.chooser.choose.file">
								<span className="text-link">Choose a file</span>
								<br />
							</Trans>
						</p>
					}
					onSelection={fileSelected}
					disabled={!isFileReplaceable || disabled}
					testId={testId + '-dd'}
				/>
			</div>
			<p className={styles.sampleCsvText}>
				<Trans i18nKey="upload.chooser.data.sample">
					Use the
					<a
						ref={sampleFileEl}
						className="text-link"
						href={sampleFile}
						download
						data-testid={testId + '-sample-link'}
					/>
					<br />
				</Trans>
			</p>
		</>
	)
}
