import { DNBDialog } from '@dnb-uux-design-system/react'
import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDefinition } from '../../../../components/collapsable-dropdown-collection-manager/collapsable-dropdown-collection-manager'
import { useWizardRouteMatch } from '../../../../components/wizardComposer/hooks/useWizardRouteMatch'
import { useApi } from '../../../../hooks'
import { useFeatures, useModules, usePlatform } from '../../../../hooks/useEntitlements'
import { Grid, LoadingState } from '../../../../local-core-ui'
import { getDataBlocksEntitlements } from '../../../../queries/api/getDataBlocksEntitlements'
import { useSource } from '../../../../queries/useSource'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../../../../store/projectWizard/actions'
import { getV2Mapping } from '../../../../store/projectWizard/thunks'
import { EntityType } from '../../../../types/sources/EntityType'
import { DataMapper } from './data-mapper'
import styles from './file-mapping.module.scss'

export function FileMappingV2(): ReactElement {
	const { t } = useTranslation()
	const { next } = useWizardRouteMatch()

	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch: TDispatch = useAppDispatch()
	const apiClient = useApi()

	const [isLoading, setIsLoading] = useState(true)
	const passDelimiterInfo = useFeatures(['EnableFileDelimiters'])
	const EnableMatchRecommendation = useFeatures(['EnableMatchRecommendation'])
	const EnableMatchRecommendationC4S = useFeatures(['EnableMatchRecommendationC4S'])
	const EnableInternationalContacts = useFeatures(['InternationalContacts'])
	const isEnableEnrichedOnlyMixedFile = useFeatures(['EnableEnrichedOnlyMixedFile'])

	const salesforcePlatform = usePlatform('salesforce')
	const isC4S = useModules(['C4S']) && salesforcePlatform
	const [fieldDefinitionsRecordsMap, setFieldDefinitionsRecordsMap] = useState(
		projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap
	)
	const [showWarnings, setShowWarnings] = useState<boolean>(false)
	const [isDirectOnly, setIsDirectOnly] = useState<boolean>(false)

	const enableAlternateFieldsRecomm = (EnableMatchRecommendation && !isC4S) || (EnableMatchRecommendationC4S && isC4S)
	const containerDataMapper = useRef<HTMLDivElement>(null)
	const sourceQuery = useSource(projectWizardState.currentProject.source.id || '')

	if (
		projectWizardState.currentProject.mappingInfo.haveGroupsIncomplete &&
		projectWizardState.currentProject.mappingInfo.areDataGroupsValidated &&
		showWarnings
	) {
		setShowWarnings(false)
	}

	useEffect(() => {
		if (sourceQuery !== undefined && sourceQuery.data !== undefined) {
			setIsDirectOnly(
				isEnableEnrichedOnlyMixedFile
					? sourceQuery.data.direct_enrich !== undefined
						? sourceQuery.data.direct_enrich
						: false
					: false
			)
		} else if (isEnableEnrichedOnlyMixedFile) {
			setIsDirectOnly(true)
		}
	}, [sourceQuery.isFetching])

	useEffect(() => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					directEnrich: isDirectOnly
				}
			})
		)
	}, [isDirectOnly])

	useEffect(() => {
		let mounted = true
		setIsLoading(true)
		dispatch(updateLoadingNextStep(true))
		dispatch(getV2Mapping(passDelimiterInfo)).then(() => {
			if (mounted) setIsLoading(false)
			dispatch(updateLoadingNextStep(false))
		})
		const recordsMap =
			projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord
				.fieldDefinitionsRecordsMap
		setFieldDefinitionsRecordsMap(recordsMap)
		if (
			isEnableEnrichedOnlyMixedFile &&
			(isEmpty(recordsMap) || (!isEmpty(recordsMap) && recordsMap.dunsNumber[0].columnName === undefined))
		) {
			setIsDirectOnly(false)
		}
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when the dispatch promise changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		if (
			isLoading &&
			!isEmpty(
				projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord
					.fieldDefinitionsRecordsMap
			)
		) {
			setIsLoading(false)
		}
	}, [
		isLoading,
		projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap
	])

	let columns: Array<ColumnDefinition> = []
	let columnKeys = Object.keys(projectWizardState.currentProject.mappingInfo.mapping.autodetectionResultsMap)
	if (columnKeys.length > 0) {
		columns = columnKeys.map((key) => {
			const autodectectedColumn =
				projectWizardState.currentProject.mappingInfo.mapping.autodetectionResultsMap[key]
			return {
				name: autodectectedColumn.columnName,
				description: autodectectedColumn.screenName
			} as ColumnDefinition
		})
	} else {
		const isC4SSource = projectWizardState.currentProject.source.integrationInfo !== undefined
		columnKeys = Object.keys(projectWizardState.currentProject.mappingInfo.mapping.existingFieldDefinitionsMap)
		// When the BE doesn't send anything we need to check on the currentFieldDefinitionsRecord and extract whatever info
		// we can grab from there. The BE returns the autodetection mostly on C4S, and proper is left alone in the dark, thank
		// you wise BE developer / architect
		if (columnKeys.length > 0) {
			columns = columnKeys.map((key) => {
				const autodectectedColumn =
					projectWizardState.currentProject.mappingInfo.mapping.existingFieldDefinitionsMap[key]
				return {
					name: autodectectedColumn.columnName,
					description: isC4SSource
						? autodectectedColumn.screenName || autodectectedColumn.columnName
						: autodectectedColumn.columnName
				} as ColumnDefinition
			})
		}
	}

	useEffect(() => {
		if (projectWizardState?.currentProject?.source?.entityType === EntityType.CONTACTS) {
			const purposeOfUse = projectWizardState?.currentProject?.purposeOfUse
			const checkEVEntitlement = getDataBlocksEntitlements(apiClient, purposeOfUse, EntityType.CONTACTS)
				.then((domains) => domains?.find((domain) => domain.domain === purposeOfUse.domain))
				.then((domain) => {
					const entitledBlocks = domain?.recordTypes[purposeOfUse.recordType]
					return (
						entitledBlocks != undefined &&
						entitledBlocks?.findIndex((entitledBlock) => entitledBlock.blockId === 'emailverification') > -1
					)
				})
			checkEVEntitlement?.then((res) => {
				localStorage.setItem('isEVEntitled', JSON.stringify(!!res))
			})
		}
	}, [])

	useEffect(() => {
		if (showWarnings) {
			const warnPos = document.querySelectorAll('[aria-label="warning"]')
			if (warnPos.length) {
				const currentScrollTop = document.documentElement.scrollTop
				const iconWarning = warnPos[0].getBoundingClientRect().y
				window.scrollTo({ top: currentScrollTop + iconWarning - 150 || 0, behavior: 'smooth' })
			}
		}
	}, [showWarnings])

	return (
		<Grid testId="container-mapping" container>
			<Grid testId="mapping-panel">
				<div data-testid="mapping-panel" className={styles.mappingPanel}>
					<h1>
						{t(
							`file.mapping.line.1.${
								projectWizardState.currentProject.source.entityType === EntityType.CONTACTS
									? 'contact'
									: 'company'
							}`
						)}
					</h1>
					<span className={styles.spanBold}>{t('file.mapping.v2.line.2')}</span>
					<span>{t('file.mapping.v2.line.3')}</span>
					{isLoading && (
						<div className={styles.mappingPanelLoader}>
							<LoadingState />
						</div>
					)}
					<DNBDialog
						open={
							projectWizardState.currentProject.mappingInfo.requireAdditionalCountry &&
							!projectWizardState.currentProject.mappingInfo.continueWithoutAddCountry
						}
						hasStickyFooter={true}
						primaryCTALabel={t('modal.warning.missing.additional.country.button.close') as string}
						primaryCTA={() => {
							dispatch(
								updateCurrentProjectAction({
									mappingInfo: {
										...projectWizardState.currentProject.mappingInfo,
										requireAdditionalCountry: false,
										continueWithoutAddCountry: false
									}
								})
							)
						}}
						secondaryCTALabel={t('modal.warning.missing.additional.country.button.continue') as string}
						secondaryCTA={() => {
							dispatch(
								updateCurrentProjectAction({
									mappingInfo: {
										...projectWizardState.currentProject.mappingInfo,
										requireAdditionalCountry: false,
										continueWithoutAddCountry: true
									}
								})
							)
							next()
						}}
						onClose={() => {
							/* Function required for the component*/
						}}
						title={t('modal.warning.missing.additional.country.title') as string}
					>
						<div>{t('modal.warning.missing.additional.country.description')}</div>
					</DNBDialog>
					<DNBDialog
						open={
							projectWizardState.currentProject.mappingInfo.haveGroupsIncomplete &&
							projectWizardState.currentProject.mappingInfo.areDataGroupsValidated
						}
						hasCloseButton={true}
						onClose={() => {
							dispatch(
								updateCurrentProjectAction({
									mappingInfo: {
										...projectWizardState.currentProject.mappingInfo,
										haveGroupsIncomplete: true,
										areDataGroupsValidated: false,
										continueWithoutAddCountry: false,
										requireAdditionalCountry: false
									}
								})
							)
							setShowWarnings(true)
						}}
						title={t('title.modal.incompleteGroups') as string}
						scrim={true}
						hasStickyFooter={true}
						size={'default'}
						primaryCTALabel={t('button.continue.modal.incompleteGroups') as string}
						primaryCTA={() => {
							setShowWarnings(false)
							next()
						}}
						secondaryCTALabel={t('button.return.modal.incompleteGroups') as string}
						secondaryCTA={() => {
							dispatch(
								updateCurrentProjectAction({
									mappingInfo: {
										...projectWizardState.currentProject.mappingInfo,
										haveGroupsIncomplete: true,
										areDataGroupsValidated: false,
										continueWithoutAddCountry: false,
										requireAdditionalCountry: false
									}
								})
							)
							setShowWarnings(true)
						}}
					>
						<div>{t('description.modal.incompleteGroups')}</div>
						<div>
							<ul>
								{Object.entries(
									projectWizardState.currentProject.mappingInfo.counterGroupsMapping || []
								)
									.filter((entry) => {
										return (
											entry[1].fieldsRequiredFull > 0 &&
											entry[1].totalRequired > entry[1].fieldsRequiredFull
										)
									})
									.map((incompleteGroup) => {
										return (
											<li key={`${incompleteGroup[0]}-id-incompleteGroup`}>
												{t(incompleteGroup[0])}
											</li>
										)
									})}
							</ul>
						</div>
					</DNBDialog>
					<div ref={containerDataMapper}>
						<DataMapper
							fieldDefinitionsRecordsMap={
								projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord
									.fieldDefinitionsRecordsMap
							}
							fieldValidationMessagesMap={
								projectWizardState.currentProject.mappingInfo.validationResponse
									?.fieldValidationMessagesMap
							}
							columns={columns}
							disabled={projectWizardState.loadingNextStep || isLoading}
							showAmeText={projectWizardState.currentProject.source.enableAme}
							onChangeFunction={(updatedData) => {
								dispatch(
									updateCurrentProjectAction({
										mappingInfo: {
											...projectWizardState.currentProject.mappingInfo,
											mapping: {
												...projectWizardState.currentProject.mappingInfo.mapping,
												currentFieldDefinitionsRecord: {
													...projectWizardState.currentProject.mappingInfo.mapping
														.currentFieldDefinitionsRecord,
													fieldDefinitionsRecordsMap: { ...updatedData }
												}
											},
											isDataMappingValidated: false,
											isDataTypesValidated: false,
											haveGroupsIncomplete: true,
											areDataGroupsValidated: false,
											continueWithoutAddCountry: false,
											requireAdditionalCountry: false
										}
									})
								)
							}}
							enableAlternateFieldsRecomm={enableAlternateFieldsRecomm}
							isEnabledInternationalContacts={EnableInternationalContacts}
							infoGroupsCounter={
								showWarnings ? projectWizardState.currentProject.mappingInfo.counterGroupsMapping : []
							}
							isDirectOnly={isDirectOnly}
							onChangeEnrich={(newValue: string | undefined) => {
								setIsDirectOnly(newValue ? JSON.parse(newValue) : undefined)
							}}
						/>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}
