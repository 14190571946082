import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import { DisplayedElement, FlattenedArrayConfiguration } from '../../../types'
import { ElementUIFacade } from '../ElementUIFacade'

const buildArrayElements = (
	entitledElements: Array<ElementUIFacade>,
	flattenedConfiguration: FlattenedArrayConfiguration,
	root: ElementUIFacade,
	selectorValueRef: MutableRefObject<number>
) => {
	return flattenedConfiguration.patternConfig.displayedElements.reduce(
		(groupedElements: Array<ElementUIFacade>, displayedElement: DisplayedElement) => {
			const includedElements = entitledElements.filter((element) =>
				displayedElement.elements.includes(element.elementId)
			)
			if (includedElements.length > 0) {
				const groupParent: ElementUIFacade = {
					parent: root,
					elementId: `${displayedElement.name.replace(/ /g, '')}Id`,
					displayName: displayedElement.name,
					description: includedElements[0].description,
					level: includedElements[0].level,
					dataType: includedElements[0].dataType,
					example: includedElements[0].example,
					showChildrenBlocks: false,
					considerChildrenForCount: false,
					maximumElementsInOutput: selectorValueRef,
					isSelectedByDefault: displayedElement.isSelectedByDefault,
					belongsToRequiredGroup: displayedElement.belongsToRequiredGroup
				}
				includedElements.sort((a: ElementUIFacade, b: ElementUIFacade) => {
					if (a.elementId > b.elementId) {
						return 1
					} else if (a.elementId < b.elementId) {
						return -1
					} else {
						return 0
					}
				})
				groupParent.childs = includedElements
				groupedElements.push(groupParent)
			}
			return groupedElements
		},
		[]
	)
}

export const buildSingleDropdownFlattenedArray = (
	entitledElements: Array<ElementUIFacade>,
	flattenedConfiguration: FlattenedArrayConfiguration,
	t: TFunction<'translation'>,
	selectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (entitledElements.length) {
		const root: ElementUIFacade = {
			blockId: t(entitledElements[0].blockId || ''),
			elementId: flattenedConfiguration.arrayId,
			displayName: flattenedConfiguration.arrayName,
			description: flattenedConfiguration.arrayDescription,
			level: entitledElements[0].level || '',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: selectorValueRef,
			outputDropdownOptions: [...Array(flattenedConfiguration.patternConfig.dropdownMaxValue).keys()].map(
				(i) => ({
					value: (i + 1).toString(),
					label: (i + 1).toString()
				})
			),
			dropdownDisabled: false,
			pattern: flattenedConfiguration.pattern
		}
		tree.push(root)

		root.childs = buildArrayElements(entitledElements, flattenedConfiguration, root, selectorValueRef)
	}
	return tree
}
