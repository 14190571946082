import { cloneDeep, merge } from 'lodash-es'
import { getApiClient4Thunks, TDispatch } from '../../store'
import { MatchingApproach, MatchStyle, MatchType } from '../../types'
import { getDefaultElementList } from '../../utils'
import {
	ADD_ELEMENT_IN_PREVIEW,
	ADD_MATCH_RULE,
	ADD_TEAM_MEMBER,
	CLEAR_CURRENT_MATCH_CONFIG_TEMPLATE,
	CLEAR_ENRICHING_APPROACH,
	CLEAR_MATCHING_APPROACH,
	CREATE_PROJECT,
	DELETE_ADVANCED_MATCH_RULES,
	DELETE_MATCH_RULE,
	InitialProjectWizardState,
	MatchRuleInfo,
	NEW_PROJECT,
	ProjectWizardActionTypes,
	ProjectWizardState,
	REMOVE_ELEMENT_IN_PREVIEW,
	REMOVE_PROJECT_FROM_WIZARD,
	REMOVE_TEAM_MEMBER,
	REPLACE_FILE,
	SAVING_LIBRARY_FLAG,
	SELECT_PROJECT,
	SET_ALTERNATE_COMPANY_INFO,
	SET_CONNECT_MANAGE_SUBSTEP,
	SET_ENRICHING_APPROACH,
	SET_ENRICHMAPPING_VIEWED,
	SET_FILE_ALREADY_PROCESSED,
	SET_MATCHING_APPROACH,
	SET_MATCH_DEFINITION_VALIDATED,
	SET_OVERVIEW_VIEWED,
	SET_PERCENTAGE_UPLOADED,
	SET_SF_VERSION,
	SHOW_MODAL_CONFLICT_FAMILY_TREE,
	SHOW_MODAL_ORDER_REASON,
	SYNC_ELEMENTS_IN_PREVIEW,
	UPDATE_CURRENT_PROJECT,
	UPDATE_DEPRECATED_ELEMENTS,
	UPDATE_DEPRECATED_MODAL,
	UPDATE_ENRICHING_LAYOUT_HASHES,
	UPDATE_LOADING_NEXT_STEP,
	UPDATE_MATCH_RULE,
	UPDATE_PROCEED_CONFIRMATION_REQUIRED,
	UPDATE_STATUS_START_IMPORT,
	UPLOADING_FILE
} from './types'

const syncCurrentProject = (state: ProjectWizardState): ProjectWizardState => {
	if (state.currentProject.shouldSync && state.currentProject.id) {
		const newVersionOfProjects = { ...state.projects }
		newVersionOfProjects[state.currentProject.id] = state.currentProject
		return { ...state, projects: newVersionOfProjects }
	} else return state
}

export function projectWizardReducer(
	state: ProjectWizardState | undefined = undefined,
	action: ProjectWizardActionTypes,
	dispatch: TDispatch
): ProjectWizardState {
	const apiClient = getApiClient4Thunks(dispatch)
	if (state === undefined) state = cloneDeep(InitialProjectWizardState)
	switch (action.type) {
		case UPDATE_CURRENT_PROJECT:
			const newCurrentProject = cloneDeep(state.currentProject)
			if (action.payload.id) newCurrentProject.id = action.payload.id
			if (action.payload.name !== undefined) newCurrentProject.name = action.payload.name
			if (action.payload.shouldSync !== undefined) newCurrentProject.shouldSync = action.payload.shouldSync
			if (action.payload.deleteOnCancel !== undefined)
				newCurrentProject.deleteOnCancel = action.payload.deleteOnCancel
			if (action.payload.projectDescription !== undefined)
				newCurrentProject.projectDescription = action.payload.projectDescription
			if (action.payload.team) newCurrentProject.team = action.payload.team
			if (action.payload.source) {
				newCurrentProject.source = { ...newCurrentProject.source, ...action.payload.source }
			}
			if (action.payload.thirdPartyIntegration) {
				// If no source was specified, that means we can't have 3rd party integration. This is being used on the
				// Select Source to wipe crm scenario
				if (action.payload.thirdPartyIntegration.crmSource === undefined) {
					newCurrentProject.thirdPartyIntegration = undefined
				} else newCurrentProject.thirdPartyIntegration = { ...action.payload.thirdPartyIntegration }
				if (action.payload.thirdPartyIntegration.connectionName) {
					newCurrentProject.thirdPartyIntegration = { ...action.payload.thirdPartyIntegration }
				}
			}
			if (action.payload.fileInfo) {
				newCurrentProject.fileInfo = { ...newCurrentProject.fileInfo, ...action.payload.fileInfo }
			}
			if (action.payload.mappingInfo) {
				newCurrentProject.mappingInfo = { ...newCurrentProject.mappingInfo, ...action.payload.mappingInfo }
			}
			if (action.payload.matchingApproach) {
				newCurrentProject.matchingApproach = action.payload.matchingApproach
			}
			if (action.payload.matchRules) {
				newCurrentProject.matchRules = action.payload.matchRules
			}
			if (action.payload.initialMatchRules) {
				newCurrentProject.initialMatchRules = action.payload.initialMatchRules
			}
			if (action.payload.matchRulesValidated !== undefined)
				newCurrentProject.matchRulesValidated = action.payload.matchRulesValidated
			if (action.payload.purposeOfUse?.domain) {
				newCurrentProject.purposeOfUse.domain = action.payload.purposeOfUse.domain
				newCurrentProject.purposeOfUse.recordType = ''
			}
			if (action.payload.purposeOfUse?.recordType) {
				newCurrentProject.purposeOfUse.recordType = action.payload.purposeOfUse.recordType
			}
			if (action.payload.enrichingLayout) {
				newCurrentProject.enrichingLayout = action.payload.enrichingLayout
			}
			if (action.payload.layoutId) {
				newCurrentProject.layoutId = action.payload.layoutId
			}
			if (action.payload.currentTemplate) {
				newCurrentProject.currentTemplate = {
					...newCurrentProject.currentTemplate,
					...action.payload.currentTemplate
				}
			}
			if (action.payload.currentMatchConfigTemplate) {
				newCurrentProject.currentMatchConfigTemplate = {
					...newCurrentProject.currentMatchConfigTemplate,
					...action.payload.currentMatchConfigTemplate
				}
			}
			if (action.payload.generateJson !== undefined) {
				newCurrentProject.generateJson = action.payload.generateJson
			}
			if (action.payload.source?.sourceDescription !== undefined) {
				newCurrentProject.source.sourceDescription = action.payload.source.sourceDescription
			}
			if (action.payload.enrichmentRules) {
				newCurrentProject.enrichmentRules = action.payload.enrichmentRules
			}
			if (action.payload.source?.entityType) {
				newCurrentProject.source.entityType = action.payload.source.entityType
			}
			if (action.payload.isValidIntegrationEnrichmentLayout !== undefined) {
				newCurrentProject.isValidIntegrationEnrichmentLayout = action.payload.isValidIntegrationEnrichmentLayout
			}
			if (action.payload.tradeUp !== undefined) {
				newCurrentProject.tradeUp = action.payload.tradeUp
			}
			if (action.payload.entityTradeUp !== undefined) {
				newCurrentProject.entityTradeUp = action.payload.entityTradeUp
			}
			if (action.payload.counterGroupsMapping) {
				newCurrentProject.mappingInfo.counterGroupsMapping = action.payload.counterGroupsMapping
			}
			if (action.payload.includeBranches !== undefined) {
				newCurrentProject.includeBranches = action.payload.includeBranches
			}
			if (action.payload.orderReason) {
				newCurrentProject.orderReason = action.payload.orderReason
			}

			return syncCurrentProject({
				...state,
				currentProject: newCurrentProject
			})
		case CREATE_PROJECT:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					...action.payload
				}
			})
		case UPLOADING_FILE:
			return {
				...state,
				selectFileStep: {
					...state.selectFileStep,
					uploadingFile: action.payload
				}
			}
		case SET_PERCENTAGE_UPLOADED:
			return {
				...state,
				selectFileStep: {
					...state.selectFileStep,
					percentageUploaded: action.payload
				}
			}
		case REPLACE_FILE:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					fileInfo: undefined
				},
				selectFileStep: {
					...state.selectFileStep,
					uploadingFile: false,
					percentageUploaded: 0
				}
			})
		case ADD_MATCH_RULE:
			const newMatchRules = [...state.currentProject.matchRules]
			newMatchRules.push({
				matchRule: action.payload,
				matchQualityType: MatchType.EASY,
				advancedSettingsCollapsed: true
			})
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					matchRules: newMatchRules
				}
			})
		case SELECT_PROJECT:
			const currentProject = cloneDeep(InitialProjectWizardState.currentProject)
			if (state.projects[action.payload]) {
				// If we have a project on the local store, it might be correspond to an old version of the project structure
				// and when using it, might make the app crash, so we'll merge it with the defaults to try to avoid that
				merge(currentProject, state.projects[action.payload])
			}
			return {
				...state,
				currentProject: currentProject
			}
		case NEW_PROJECT:
			const clonedInitialProject = cloneDeep(InitialProjectWizardState.currentProject)
			// now evaluate defaults for the source since we can't know in advance them (depend on whether we are on API enabled tenant, C4S, etc)
			if (action.payload.isC4S) {
				clonedInitialProject.source.isApi = false
				clonedInitialProject.source.isFile = false
			} else {
				if (action.payload.isAPIEnabled) {
					clonedInitialProject.source.isApi = false
					clonedInitialProject.source.isFile = false
				}
			}

			return {
				...state,
				currentProject: clonedInitialProject,
				loadingNextStep: InitialProjectWizardState.loadingNextStep,
				selectFileStep: cloneDeep(InitialProjectWizardState.selectFileStep),
				overviewStep: cloneDeep(InitialProjectWizardState.overviewStep),
				alternateCompanyMatch: cloneDeep(InitialProjectWizardState.alternateCompanyMatch),
				startImport: InitialProjectWizardState.startImport
			}
		case REMOVE_PROJECT_FROM_WIZARD:
			const projectId = action.payload
			const newVersionOfProjects = { ...state.projects }
			delete newVersionOfProjects[projectId]
			return { ...state, projects: newVersionOfProjects }

		case UPDATE_MATCH_RULE:
			const updatedMatchRules = [...state.currentProject.matchRules]
			updatedMatchRules[action.payload.index] = action.payload.matchRuleInfo
			return syncCurrentProject({
				...state,
				currentProject: { ...state.currentProject, matchRules: updatedMatchRules, matchRulesValidated: false }
			})
		case DELETE_MATCH_RULE:
			const reducedMatchRules = [...state.currentProject.matchRules]
			reducedMatchRules.splice(action.payload, 1)
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					matchRules: reducedMatchRules
				}
			})
		case SET_MATCH_DEFINITION_VALIDATED:
			const matchRules = state.currentProject.matchRulesValidated
				? [...state.currentProject.matchRules]
				: [...state.currentProject.initialMatchRules]
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					matchRulesValidated: true,
					initialMatchRules: matchRules
				}
			})
		case UPDATE_STATUS_START_IMPORT:
			return {
				...state,
				startImport: action.payload
			}
		case UPDATE_LOADING_NEXT_STEP:
			return {
				...state,
				loadingNextStep: action.payload
			}
		case DELETE_ADVANCED_MATCH_RULES:
			const baseRule = state.currentProject.matchRules.slice(0, 1)
			return syncCurrentProject({
				...state,
				currentProject: { ...state.currentProject, matchRules: baseRule }
			})
		case ADD_TEAM_MEMBER:
			const newTeamMembers = [...state.currentProject.team.TeamMembers]
			newTeamMembers.push(action.payload)
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					team: { ...state.currentProject.team, TeamMembers: newTeamMembers }
				}
			})
		case REMOVE_TEAM_MEMBER:
			const teamMembers = [...state.currentProject.team.TeamMembers]
			const indexUser = teamMembers
				.map(function (user) {
					return user.Email
				})
				.indexOf(action.payload)
			teamMembers.splice(indexUser, 1)
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					team: { ...state.currentProject.team, TeamMembers: teamMembers }
				}
			})
		case SET_OVERVIEW_VIEWED:
			return {
				...state,
				overviewStep: {
					...state.overviewStep,
					viewed: true
				}
			}
		case SET_ALTERNATE_COMPANY_INFO:
			return {
				...state,
				alternateCompanyMatch: {
					...state.alternateCompanyMatch,
					alternateCompanyExpanded: action.payload.alternateCompanyExpanded,
					alternateAdrsExpanded: action.payload.alternateAdrsExpanded,
					alternateRegNumExpanded: action.payload.alternateRegNumExpanded
				}
			}
		case SET_ENRICHMAPPING_VIEWED:
			return {
				...state,
				enrichmentMappingStep: {
					...state.enrichmentMappingStep,
					viewed: action.payload
				}
			}
		case SET_FILE_ALREADY_PROCESSED:
			return {
				...state,
				selectFileStep: {
					...state.selectFileStep,
					fileAlreadyProcessed: true
				}
			}
		case SET_ENRICHING_APPROACH:
			//DCP-5594,5595,5598 concating default elements with contact default elements
			const isRecommendationMatch = state.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
			const defaultElementList = getDefaultElementList(
				state.currentProject?.source?.entityType,
				state.currentProject?.source?.enable_email_verification,
				apiClient,
				isRecommendationMatch
			)
			const hasCRMSource = state.currentProject.thirdPartyIntegration !== undefined
			const enrichingLayout = hasCRMSource ? [] : [...defaultElementList]
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					enrichingApproach: action.payload,
					currentTemplate: undefined,
					enrichingLayout: enrichingLayout
				}
			})
		case ADD_ELEMENT_IN_PREVIEW:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					removedElements: state.enrichmentPreviewSubStep.removedElements.filter(
						(removedElement) => removedElement.elementId !== action.payload.elementId
					)
				}
			}
		case REMOVE_ELEMENT_IN_PREVIEW:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					removedElements: state.enrichmentPreviewSubStep.removedElements.concat(action.payload)
				}
			}
		case SYNC_ELEMENTS_IN_PREVIEW:
			return {
				...state,
				currentProject: {
					...state.currentProject,
					enrichingLayout: state.currentProject.enrichingLayout
						.map((enrichingBlock) => {
							return {
								...enrichingBlock,
								elements: enrichingBlock.elements.filter(
									(element) =>
										!state?.enrichmentPreviewSubStep.removedElements.find(
											(removedElement) => removedElement.elementId === element.elementId
										)
								)
							}
						})
						.filter((enrichingBlock) => enrichingBlock.elements.length > 0)
				},
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					removedElements: []
				}
			}
		case SAVING_LIBRARY_FLAG:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					isUpdatingOrSaving: action.payload
				},
				loadingNextStep: action.payload
			}
		case SET_MATCHING_APPROACH:
			const matchRulesUpdated = state.currentProject.matchRules
			if (
				(action.payload === MatchingApproach.START_SCRATCH || MatchingApproach.CHOOSE_TEMPLATE) &&
				matchRulesUpdated.length > 0
			) {
				delete matchRulesUpdated[0].matchRule.stewardshipProfile
				delete matchRulesUpdated[0].matchRule.precedenceProfile
				delete matchRulesUpdated[0].matchRule.passProfile
				delete matchRulesUpdated[0].matchRule.precedenceCriterion
				matchRulesUpdated[0].matchRule.matchStyle = MatchStyle.MATCH_QUALITY
			} else if (action.payload === MatchingApproach.START_SCRATCH_MR) {
				matchRulesUpdated[0].matchRule = {
					...matchRulesUpdated[0].matchRule,
					stewardshipProfile: 'Standard',
					precedenceProfile: 'Standard',
					passProfile: 'Standard',
					matchStyle: MatchStyle.MATCH_RECOMMENDATION
				}
			}
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					matchingApproach: action.payload,
					matchRules: matchRulesUpdated as Array<MatchRuleInfo>
				}
			})
		case CLEAR_MATCHING_APPROACH:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					matchingApproach: undefined
				}
			})
		case CLEAR_CURRENT_MATCH_CONFIG_TEMPLATE:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					currentMatchConfigTemplate: undefined
				}
			})
		case CLEAR_ENRICHING_APPROACH:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					enrichingApproach: undefined
				}
			})
		case UPDATE_ENRICHING_LAYOUT_HASHES:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					initialEnrichingLayoutHashes: action.payload
				}
			}
		case UPDATE_PROCEED_CONFIRMATION_REQUIRED:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					proceedConfirmationRequired: action.payload
				}
			}
		case SET_SF_VERSION:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					versionSF: action.payload
				}
			})
		case UPDATE_DEPRECATED_ELEMENTS:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					deprecatedElements: action.payload
				}
			}
		case UPDATE_DEPRECATED_MODAL:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					deprecatedModal: action.payload
				}
			}
		case SHOW_MODAL_CONFLICT_FAMILY_TREE:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					showConflictFamilyTreeModal: action.payload
				}
			}
		case SHOW_MODAL_ORDER_REASON:
			return {
				...state,
				enrichmentPreviewSubStep: {
					...state.enrichmentPreviewSubStep,
					showOrderReasonModal: action.payload
				}
			}
		case SET_CONNECT_MANAGE_SUBSTEP:
			return syncCurrentProject({
				...state,
				currentProject: {
					...state.currentProject,
					source: {
						...state.currentProject.source,
						integrationInfo: {
							...state.currentProject.source.integrationInfo,
							completedConnectManageSubStep: action.payload
						}
					}
				}
			})
		default:
			return state
	}
}
