import { AxiosError } from 'axios'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { useAppDispatch } from '../store'
import { PlsErrorData, UserDetail } from '../types'
import { createUser } from './api/createUser'

export interface CreateUserParams {
	user: UserDetail
	withSameDomain?: boolean
}

export const useCreateUser = (): UseMutationResult<
	{ errorMsg: string; errorCode: string } | void,
	unknown,
	CreateUserParams,
	unknown
> => {
	const apiClient = useApi((error: AxiosError<PlsErrorData | unknown>) => Promise.reject(error))
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()

	const mutationFn = ({ user, withSameDomain }: CreateUserParams) =>
		createUser(apiClient, user, dispatch, withSameDomain)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUsers')
		}
	})
}
