import { AxiosInstance } from 'axios'
import { TDispatch } from '../../store'
import { ApiErrorState, setApiErrorAction } from '../../store/apiError'
import { CreateUserRequest, CreateUserResponse, UpdateUserRequest, UserDetail } from '../../types'

export const createUser = async (
	apiClient: AxiosInstance,
	user: UserDetail,
	dispatch: TDispatch,
	withSameDomain?: boolean
): Promise<{ errorMsg: string; errorCode: string } | void> => {
	const url = `/pls/users?withSameDomain=${withSameDomain !== undefined ? withSameDomain : true}`

	const createUserRequest: CreateUserRequest = {
		User: {
			Username: user.Email,
			FirstName: user.FirstName,
			LastName: user.LastName,
			Email: user.Email,
			AccessLevel: user.AccessLevel || 'BUSINESS_ANALYST'
		},
		Credentials: {
			Username: user.Email,
			Password: 'WillBeResetImmediately'
		},
		UseIDaaS: true
	}
	user.Username = user.Email

	return apiClient
		.post<CreateUserResponse>(url, createUserRequest)
		.then((response) => {
			if (response.data.Result?.ConflictingUser) {
				// user exists in another tenant, so update it to add it to this tenant
				user.FirstName = response.data.Result.ConflictingUser.FirstName
				user.LastName = response.data.Result.ConflictingUser.LastName
				//TODO: useMutation to replace dispatch(updateUserAction({ ...newUser, isBusy: true }))
				const updateUserObj: UpdateUserRequest = {
					AccessLevel: createUserRequest.User.AccessLevel || 'BUSINESS_ANALYST',
					targetUsername: user.Email
				}
				apiClient.put(url, updateUserObj).then(() => {
					//TODO: useMutation to replace dispatch(updateUserAction({ ...newUser, isBusy: false }))
				})
			} else if (response.data.Success) {
				// user added successfully
				//TODO: useMutation to replace dispatch(updateUserAction({ ...newUser, isBusy: false }))
			}
		})
		.catch((e) => {
			const errorCodeDiffEmailsDomains = 'LEDP_18271'
			const errorCodeNotPrimaryDomain = 'LEDP_18275'
			const response = JSON.parse(e.request.response)
			const responseData = e.response.data
			const errorMessage = response.UIAction
			if (errorMessage) {
				const errorData: ApiErrorState = {
					errorCode: errorMessage.code,
					message: errorMessage.message
				}
				dispatch(setApiErrorAction(errorData))
			} else {
				if (
					response.errorCode === errorCodeDiffEmailsDomains ||
					response.errorCode === errorCodeNotPrimaryDomain
				) {
					return { errorMsg: response.errorMsg, errorCode: response.errorCode }
				} else {
					window.history.pushState({}, 'users', '/all-users')
					const errorData: ApiErrorState = {
						message: e.response.data.Errors[0]
					}
					alert(JSON.stringify(responseData) || e)
					dispatch(setApiErrorAction(errorData))
				}
			}
		})
}
