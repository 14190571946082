import { ColorGrayDarker } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { AxiosResponse } from 'axios/index'
import cx from 'classnames'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataBlockRow } from '../../../../components/data-block-row/data-block-row'
import { DataBlockSearcher } from '../../../../components/data-block-searcher/data-block-searcher'
import { ElementUIFacade } from '../../../../components/data-block-searcher/ElementUIFacade'
import { sideBlocksStringReplace } from '../../../../components/data-block-searcher/helpers/side-blocks-string-replace'
import { NewElemsVersionModal } from '../../../../components/new-elems-version-modal/new-elems-version-modal'
import { Snackbar, SnackType } from '../../../../components/snackbar/snackbar'
import { filterDeprecatedElements, filterElementsByEntitlements } from '../../../../helpers'
import { filterUpdatableElems } from '../../../../helpers/filterUpdatableElems'
import { mapReplacements } from '../../../../helpers/mapReplacements'
import { removeFamily } from '../../../../helpers/removeFamily'
import { fullFamilyTreeSelected } from '../../../../helpers/validateFullFamilyTree'
import { isTradeUpEnabled } from '../../../../helpers/validateTradeUp'
import { useApi } from '../../../../hooks'
import { useFeatures, useModules, usePlatform } from '../../../../hooks/useEntitlements'
import { Grid, Icon } from '../../../../local-core-ui'
import { useFlattenedArraysConfiguration } from '../../../../queries/useFlattenedArraysConfiguration'
import { useC4SSourcePackageVersion } from '../../../../queries/useSFPackageVersion'
import { useSource } from '../../../../queries/useSource'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EnrichingBlock, UPDATE_CURRENT_PROJECT } from '../../../../store/projectWizard/types'
import {
	Element,
	ElementDetail,
	ElementsToUpdate,
	FlattenedArrayConfiguration,
	LayoutResponse,
	LevelDetail,
	MatchingApproach
} from '../../../../types'
import { getDefaultElementList } from '../../../../utils'
import { FullFamilyTreeSelector } from '../full-family-tree-selector/full-family-tree-selector'
import { TradeUpModal } from '../trade-up-modal/trade-up-modal'
import { TradeUpSelector } from '../trade-up-selector/trade-up-selector'
import {
	deleteTradeUpElements,
	getSelectedElements,
	getTradeUpElements,
	updateAllEnrichingLayoutBlocks,
	updateEnrichingLayout,
	updateEnrichingLayoutWithTradeUp
} from '../utils'
import { sortDataBlockList } from '../utils/sortDataBlockList'
import styles from './select-your-data.module.scss'

export function SelectYourData(): ReactElement {
	const { t } = useTranslation()
	const [selectedBlock, setSelectedBlock] = useState<ElementDetail>()
	const [isDataBlockElementSearcherOpen, setIsDataBlockElementSearcherOpen] = useState<boolean>(false)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch = useAppDispatch()
	const hasCRMSource = projectWizardState.currentProject.thirdPartyIntegration !== undefined
	const isRecommendationMatch =
		projectWizardState.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
	//DCP-5594,5595,5598 concating default elements with contact default elements
	const apiClient = useApi()
	const defaultElementList = getDefaultElementList(
		projectWizardState.currentProject?.source?.entityType,
		projectWizardState.currentProject?.source?.enable_email_verification,
		apiClient,
		isRecommendationMatch
	)

	const defaultElements: Array<EnrichingBlock> = hasCRMSource
		? [...defaultElementList.filter((block) => block.blockId !== 'entityresolution')]
		: [...defaultElementList]
	const selectedBlockIdx = projectWizardState.currentProject.enrichingLayout.findIndex(
		(block) => block.blockId === selectedBlock?.blockId
	)
	const selectedDefaultIdx = defaultElements.findIndex((block) => block.blockId === selectedBlock?.blockId)
	const [isAllDataBlockSearcherOpen, setIsAllDataBlockSearcherOpen] = useState<boolean>(false)
	const [flattenTradeUp, setFlattenTradeUp] = useState<Array<ElementUIFacade>>([])
	const [blockList, setBlockList] = useState<Array<ElementDetail>>([])
	const [enableTradeUp, setEnableTradeUp] = useState<boolean>(!!projectWizardState.currentProject.tradeUp)
	const [proceededTradeUp, setProceededTradeUp] = useState(false)
	const [initialLoad, setInitialLoad] = useState(true)
	const [selectedTradeUpEntity, setSelectedTradeUpEntity] = useState(
		projectWizardState.currentProject.entityTradeUp || 'hq'
	)

	const [openNewElemsVersionModal, setOpenNewElemsVersionModal] = useState<boolean>(false)
	const [elementsUpdatable, setElementsUpdatable] = useState<Record<string, ElementsToUpdate> | undefined>(undefined)
	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState<boolean>(false)
	const EnhancedCompanyEntityResolution = useFeatures(['EnhancedCompanyEntityResolution'])
	const EnableUpwardFamilyTreeSideFile = useFeatures(['EnableUpwardFamilyTreeSideFile'])
	const EnableFullFamilyTreeSideFile = useFeatures(['EnableFullFamilyTreeSideFile'])
	const salesforcePlatform = usePlatform('salesforce')
	const isC4S = useModules(['C4S']) && salesforcePlatform
	const sourceId = projectWizardState?.currentProject?.source?.id || ''
	const sourceQuery = useSource(sourceId)
	const packageVersion = useC4SSourcePackageVersion(
		sourceId,
		projectWizardState.currentProject.versionSF,
		hasCRMSource
	)
	const showTradeUp = isTradeUpEnabled(
		useFeatures(['EnableTradeUp']),
		useFeatures(['EnableTradeUpC4S']),
		sourceQuery.data?.integrationInfo?.integrationType,
		projectWizardState?.currentProject?.source?.entityType,
		packageVersion.data?.version
	)
	const emailVerificationFF = useFeatures(['EnableEmailVerification'])
	const emailVerificationC4SFF = useFeatures(['EnableEmailVerificationC4S'])
	const isEnableEmailVerification = isC4S ? emailVerificationFF && emailVerificationC4SFF : emailVerificationFF
	// New Implementation of flattened arrays configuration
	const [flattenedArraysConfiguration, setFlattenedArraysConfiguration] = useState<
		Array<FlattenedArrayConfiguration>
	>([])
	const flattenedArraysConfigurationQuery = useFlattenedArraysConfiguration()
	// New Implementation of flattened arrays configuration

	const getBlockList = async () => {
		const isRecommendationMatch =
			projectWizardState.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
		const selectedElements = getSelectedElements(
			projectWizardState.currentProject.enrichingLayout,
			hasCRMSource,
			projectWizardState.currentProject?.source?.entityType,
			projectWizardState.currentProject?.source?.enable_email_verification,
			apiClient,
			isRecommendationMatch
		)
		const dataBlockList = await filterElementsByEntitlements(
			apiClient,
			dispatch,
			selectedElements,
			projectWizardState.currentProject.purposeOfUse,
			undefined,
			projectWizardState.currentProject.source.entityType
		)
		if (showTradeUp) {
			setFlattenTradeUp(getTradeUpElements(dataBlockList))
		} else if (isC4S) {
			deleteTradeUpElements(dataBlockList)
		}
		if (!EnableUpwardFamilyTreeSideFile) {
			removeFamily(dataBlockList, 'hierarchyconnections', 'upward')
		}
		if (!EnableFullFamilyTreeSideFile) {
			removeFamily(dataBlockList, 'hierarchyconnections', 'fullfamily')
		}
		const updatableElems = filterUpdatableElems(dataBlockList, selectedElements)
		mapReplacements(dataBlockList, updatableElems)
		setElementsUpdatable(updatableElems)
		setBlockList(filterDeprecatedElements(dataBlockList, selectedElements, isEnableEmailVerification))
	}

	const [isMounted, setIsMounted] = useState(false)
	useEffect(() => {
		setIsMounted(true)
	}, [])

	useEffect(() => {
		if (isMounted) {
			getBlockList()
		}
	}, [isMounted, projectWizardState.currentProject.source.enable_email_verification])

	const verifyLayout = async () => {
		const url = `/pls/enrichment-layouts/sourceId/${sourceId}`
		await apiClient.get(url).then((response: AxiosResponse<LayoutResponse>) => {
			if (response.data) {
				dispatch(
					updateCurrentProjectAction({
						layoutId: response.data.layoutId
					})
				)
			}
		})
	}

	// New Implementation of flattened arrays configuration
	useEffect(() => {
		if (flattenedArraysConfigurationQuery.isSuccess && flattenedArraysConfigurationQuery.data) {
			setFlattenedArraysConfiguration(flattenedArraysConfigurationQuery.data)
		}
	}, [flattenedArraysConfigurationQuery.isFetching])
	// New Implementation of flattened arrays configuration

	useEffect(() => {
		if (elementsUpdatable) {
			if (Object.keys(elementsUpdatable).length > 0) {
				setOpenNewElemsVersionModal(true)
			}
		}
	}, [elementsUpdatable])

	useEffect(() => {
		getBlockList().then()
		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (sourceId != '' && !projectWizardState.currentProject.layoutId) {
			verifyLayout()
		}
	}, [sourceId])

	useEffect(() => {
		getBlockList().then()
	}, [projectWizardState.currentProject.source.enable_email_verification])

	useEffect(() => {
		if (
			!openNewElemsVersionModal &&
			elementsUpdatable !== undefined &&
			Object.entries(elementsUpdatable).length === 0
		) {
			getBlockList()
		}
	}, [openNewElemsVersionModal])

	const getElementsAvailable = (block: ElementDetail): number => {
		let elementsAvailable = 0
		block.levels.forEach((level: LevelDetail) => {
			elementsAvailable += level.elements.length
		})
		return elementsAvailable
	}

	const getElementsSelected = (block: ElementDetail): number | undefined => {
		const layoutBlock = projectWizardState?.currentProject?.enrichingLayout?.find(
			(el) => el.blockId === block.blockId
		)
		return layoutBlock?.elements?.length
	}

	const filterBlockListForSalesforce = (element: ElementDetail) => {
		const elementName = element.blockId || element.displayName
		return (hasCRMSource || salesforcePlatform) && !projectWizardState.currentProject.source.isFile
			? elementName !== 'entityresolution'
			: true
	}
	useEffect(() => {
		if (selectedBlock && !isAllDataBlockSearcherOpen) {
			setIsDataBlockElementSearcherOpen(true)
		}
		/**
		 * We only want to run this effect when selectedBlock changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBlock])

	const tradeUpOnChange = () => {
		const newStateSwitch = !projectWizardState.currentProject.tradeUp
		!newStateSwitch && setProceededTradeUp(false)
		setInitialLoad(false)
		setEnableTradeUp(newStateSwitch)
		dispatch({
			type: UPDATE_CURRENT_PROJECT,
			payload: { tradeUp: newStateSwitch }
		})
	}

	const onChangeTradeUpEntity = (event: ChangeEvent<HTMLInputElement>) => {
		setSelectedTradeUpEntity(event.target.value as 'hq' | 'domhq')
		dispatch(updateCurrentProjectAction({ entityTradeUp: event.target.value as 'hq' | 'domhq' }))
	}

	const showTradeUpModel = () => {
		return enableTradeUp && !proceededTradeUp && !initialLoad
	}

	const onModalProceed = () => {
		setProceededTradeUp(true)
	}

	const onModalTurnOff = () => {
		setProceededTradeUp(false)
		tradeUpOnChange()
	}

	useEffect(() => {
		if (flattenTradeUp.length && showTradeUp) {
			const newEnrichingLayout = [...projectWizardState.currentProject.enrichingLayout]
			updateEnrichingLayoutWithTradeUp(dispatch, flattenTradeUp, newEnrichingLayout, enableTradeUp)
		}
	}, [enableTradeUp])

	const removeReplacements = (selectedElements: Array<Element>) => {
		if (elementsUpdatable) {
			Object.entries(elementsUpdatable).forEach((itemUpdateByBlock) => {
				itemUpdateByBlock[1].elements.forEach((elemOfBlock) => {
					selectedElements = selectedElements.filter((currentElementSelected) => {
						if (elemOfBlock.replacements.length > 0) {
							return currentElementSelected.elementId !== elemOfBlock.replacements[0].elementId
						}
						return currentElementSelected
					})
				})
			})
			updateAllEnrichingLayoutBlocks(dispatch, selectedElements, flattenedArraysConfiguration)
		}
	}

	const [enableFullFamilyTree, setEnableFullFamilyTree] = useState<boolean>(
		projectWizardState.currentProject.includeBranches ?? true
	)
	const [showFullFamilyToggle, setShowFullFamilyToggle] = useState<boolean>(false)

	const fullFamilyTreeOnChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
		const checked = currentTarget.checked
		setEnableFullFamilyTree(checked)
		dispatch({
			type: UPDATE_CURRENT_PROJECT,
			payload: { includeBranches: checked }
		})
	}
	useEffect(() => {
		if (EnableFullFamilyTreeSideFile) {
			const isFullFamilySelected = fullFamilyTreeSelected(projectWizardState?.currentProject?.enrichingLayout)
			setShowFullFamilyToggle(isFullFamilySelected)

			const includesBranches = isFullFamilySelected ? enableFullFamilyTree : null
			if (includesBranches !== projectWizardState.currentProject.includeBranches) {
				dispatch({
					type: UPDATE_CURRENT_PROJECT,
					payload: { includeBranches: includesBranches }
				})
			}
		}
	}, [projectWizardState.currentProject.enrichingLayout])

	return (
		<Grid testId="container-select-your-data" container>
			<Grid testId="data-blocks-searcher">
				{elementsUpdatable && (
					<NewElemsVersionModal
						isOpen={openNewElemsVersionModal}
						elementsToUpdate={elementsUpdatable}
						onClose={() => {
							const currentSelectedElements: Array<Element> = []
							projectWizardState.currentProject.enrichingLayout.forEach((blockData) => {
								blockData.elements.forEach((el: Element) => {
									currentSelectedElements.push({ ...el, blockId: blockData.blockId })
								})
							})
							removeReplacements(currentSelectedElements)
							setOpenNewElemsVersionModal(false)
						}}
						onUpdate={() => {
							const selectedReplacements: Array<Element> = []
							let currentSelectedElements: Array<Element> = []
							projectWizardState.currentProject.enrichingLayout.forEach((blockData) => {
								blockData.elements.forEach((el: Element) => {
									currentSelectedElements.push({ ...el, blockId: blockData.blockId })
								})
							})
							Object.entries(elementsUpdatable).forEach((itemUpdateByBlock) => {
								itemUpdateByBlock[1].elements.forEach((elemOfBlock) => {
									elemOfBlock.replacements.forEach((replace) => {
										selectedReplacements.push({ ...replace, blockId: itemUpdateByBlock[0] })
									})
									currentSelectedElements = currentSelectedElements.filter(
										(previousElementSelected) =>
											previousElementSelected.elementId !== elemOfBlock.original.elementId
									)
								})
							})
							currentSelectedElements = currentSelectedElements.concat(selectedReplacements)
							updateAllEnrichingLayoutBlocks(
								dispatch,
								currentSelectedElements,
								flattenedArraysConfiguration
							)
							setElementsUpdatable({})
							setOpenNewElemsVersionModal(false)
							setShowSuccessSnackbar(true)
							setTimeout(() => {
								setShowSuccessSnackbar(false)
							}, 10000)
						}}
					/>
				)}
				{isAllDataBlockSearcherOpen ? (
					<DataBlockSearcher
						title={projectWizardState.currentProject.purposeOfUse.domain}
						dataBlockList={blockList}
						selectedElements={projectWizardState.currentProject.enrichingLayout}
						defaultElements={defaultElements}
						open={isAllDataBlockSearcherOpen}
						onClose={() => setIsAllDataBlockSearcherOpen(false)}
						onConfirm={(selectedElements) => {
							updateAllEnrichingLayoutBlocks(dispatch, selectedElements, flattenedArraysConfiguration)
							setIsAllDataBlockSearcherOpen(false)
						}}
						testId="adbs-layout"
						expandedSearch={true}
						showBlockInformation={true}
						showSelectedCount={true}
						activeTradeUp={showTradeUp}
						showBannerUpdateElements={
							elementsUpdatable !== undefined && Object.keys(elementsUpdatable).length > 0
						}
						onShowModalAffectedElements={() => {
							setIsAllDataBlockSearcherOpen(false)
							setOpenNewElemsVersionModal(true)
						}}
						flattenedArraysConfiguration={flattenedArraysConfiguration}
					/>
				) : undefined}
				{isDataBlockElementSearcherOpen ? (
					<DataBlockSearcher
						title={
							selectedBlock
								? sideBlocksStringReplace(selectedBlock?.blockId, selectedBlock?.displayName)
								: ''
						}
						dataBlockList={selectedBlock}
						selectedElements={
							selectedBlock && projectWizardState.currentProject.enrichingLayout[selectedBlockIdx]
								? projectWizardState.currentProject.enrichingLayout[selectedBlockIdx].elements
								: []
						}
						defaultElements={
							selectedBlock && defaultElements[selectedDefaultIdx]
								? defaultElements[selectedDefaultIdx].elements
								: undefined
						}
						open={isDataBlockElementSearcherOpen}
						onClose={() => {
							setSelectedBlock(undefined)
							setIsDataBlockElementSearcherOpen(false)
						}}
						onConfirm={(selectedElements) => {
							if (selectedBlock) {
								const newEnrichingLayout = [...projectWizardState.currentProject.enrichingLayout]
								updateEnrichingLayout(
									dispatch,
									newEnrichingLayout,
									selectedElements,
									selectedBlock.blockId
								)
							}
							setSelectedBlock(undefined)
							setIsDataBlockElementSearcherOpen(false)
						}}
						testId="dbes-layout"
						showBlockInformation={false}
						showSelectedCount={false}
						activeTradeUp={showTradeUp}
						showBannerUpdateElements={
							elementsUpdatable !== undefined &&
							Object.keys(elementsUpdatable).includes(selectedBlock?.blockId || '')
						}
						onShowModalAffectedElements={() => {
							setSelectedBlock(undefined)
							setIsDataBlockElementSearcherOpen(false)
							setOpenNewElemsVersionModal(true)
						}}
						flattenedArraysConfiguration={flattenedArraysConfiguration}
					/>
				) : undefined}
				<div className={styles.selectYourDataPanel}>
					<h1 className={styles.title}>{t('select.your.data.title')}</h1>
					<span className={styles.description}>{t('select.your.data.description')}</span>
					<div className={cx(styles.searchButtonSection, `${showTradeUp ? styles.justifyToTradeUp : ''}`)}>
						{showTradeUp && (
							<TradeUpSelector
								enableTradeUp={enableTradeUp}
								selectedTradeUpEntity={selectedTradeUpEntity}
								onChangeTradeUpEntity={onChangeTradeUpEntity}
								onChangeTradeUp={tradeUpOnChange}
								testId={'tradeUpSelector-SelectData'}
							/>
						)}
						{showTradeUp && isC4S && (
							<TradeUpModal
								showTradeUp={showTradeUpModel()}
								onModalProceed={onModalProceed}
								onModalTurnOff={onModalTurnOff}
							/>
						)}
						<div
							className={styles.searchButtonContainer}
							onClick={() => setIsAllDataBlockSearcherOpen(true)}
							data-testid="create-layout-search-btn-cont"
						>
							<label data-testid="label-layout-search" className={styles.searchLabel}>
								{t('select.your.data.search.label')}
							</label>
							<div className={styles.searchIcon}>
								<Icon testId="search-select-data" type={'search'} color={ColorGrayDarker} />
							</div>
						</div>
					</div>
					{EnableFullFamilyTreeSideFile && showFullFamilyToggle && (
						<FullFamilyTreeSelector
							testId={'fullFamilyTreeSelector-enrichmentPreviewer'}
							enableFullFamilyTree={enableFullFamilyTree}
							onChangeFullFamilyTree={fullFamilyTreeOnChange}
						/>
					)}
					{elementsUpdatable && Object.keys(elementsUpdatable).length > 0 && (
						<div className={styles.containerBannerUpdateElms}>
							<Snackbar
								title={t('title.banner.update.elements')}
								message={t('description.banner.update.elements') as string}
								type={SnackType.information}
								isBanner
								linkText={t('link.banner.update.elements') as string}
								onClickLink={() => {
									setOpenNewElemsVersionModal(true)
								}}
							/>
						</div>
					)}
					{blockList && blockList.length > 0 && (
						<div className={styles.selectYourDataList}>
							<div data-testid="data-block-row-list" className={styles.dataBlockRowList}>
								{blockList
									.sort(sortDataBlockList)
									.filter(filterBlockListForSalesforce)
									.map((block, idx) => {
										const name: string = block.blockId || block.displayName
										const key = `data-block-row-${name}-${idx}`
										const description: string = t(`${block.blockId}.description`, 'Description')
										const available: number = getElementsAvailable(block)
										const selected: number | undefined = getElementsSelected(block)
										return (
											<DataBlockRow
												key={key}
												name={name}
												description={description}
												available={available}
												onEditionClicked={() => {
													setSelectedBlock(block)
												}}
												selected={selected}
												editionLocked={
													name === 'entityresolution'
														? !EnhancedCompanyEntityResolution
														: false
												}
												testId={'dbr-' + idx}
												haveElementsDeprecated={
													elementsUpdatable !== undefined &&
													Object.keys(elementsUpdatable).includes(block.blockId || '')
												}
											/>
										)
									})}
							</div>
						</div>
					)}
					{showSuccessSnackbar && (
						<div className={styles.snackbar}>
							<Snackbar title={t('snackbar.update.elems.successfully')} type={SnackType.success} />
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	)
}
