import { ColorGreen } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import { Element } from '../../types'
import { ElementUIFacade } from '../data-block-searcher/ElementUIFacade'
import styles from './data-block-element-selector.module.scss'
import { CellData, DataTable } from './data-table'

interface DataBlockElementSelectorProps {
	element: Element | ElementUIFacade
	dataBlockName: string
	showBlockInformation?: boolean
	level?: string
	dataTable?: Array<CellData>
}

export const DataBlockElementSelector = ({
	element,
	dataBlockName,
	showBlockInformation = true,
	level,
	dataTable
}: DataBlockElementSelectorProps): ReactElement => {
	const { t } = useTranslation()
	const [completeDescription, setCompleteDescription] = useState(true)
	const [descriptionTooLong, setDescriptionTooLong] = useState(false)
	const descriptionRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const currentHeight = descriptionRef.current?.clientHeight || 0
		if (currentHeight > 37) {
			setDescriptionTooLong(true)
		} else {
			setDescriptionTooLong(false)
		}
	}, [])

	useEffect(() => {
		setCompleteDescription(false)
	}, [descriptionTooLong])

	return (
		<div className={styles.dataBlockElementSelector}>
			<div key={element.elementId + '-element-selector'} className={styles.checkboxWrapper}>
				<div data-testid="data-block-container" className={styles.dataBlockContainer}>
					{showBlockInformation && (
						<div className={styles.dataBlockNameContainer}>
							<label title={t(dataBlockName)} className={styles.textDataBlockName}>
								{t(dataBlockName)}
							</label>
						</div>
					)}
					{(element as ElementUIFacade).isSelectedByDefault && (
						<div className={styles.dataBlockRequired}>
							<Icon
								testId="icon-lock-data-blocks-element-selector"
								key="iconLock"
								type="lock"
								color={ColorGreen}
								size="small"
							/>
							<div className={styles.dataBlockRequiredText}>{t('required.by.default')}</div>
						</div>
					)}
					<div>
						<div data-testid="data-block-type" className={styles.dataBlockType}>
							{level ? <b>{t(`data.block.element.selector.level`)}</b> : undefined}
							{level}
							{element.dataType && (
								<b>
									{t(`data.block.element.selector.type`)}
									{': '}
								</b>
							)}
							{element.dataType}
							{element.example && (
								<b>
									{t(`data.block.element.selector.example`)}
									{': '}
								</b>
							)}
							{element.example}
						</div>
					</div>
					<div ref={descriptionRef}>
						<label
							data-testid="data-block-description"
							className={
								completeDescription ? styles.dataBlockDescriptionLong : styles.dataBlockDescriptionShort
							}
						>
							{element.description}
						</label>
						{descriptionTooLong && (
							<div onClick={() => setCompleteDescription(!completeDescription)}>
								<label data-testid="more-less-button" className={styles.dataBlockHyperlink}>
									{completeDescription
										? t('data.block.element.selector.less')
										: t('data.block.element.selector.more')}
								</label>
							</div>
						)}
					</div>
				</div>
			</div>
			{dataTable !== undefined && (
				<div className={styles.containerTable}>
					<DataTable dataTable={dataTable} />
				</div>
			)}
		</div>
	)
}
