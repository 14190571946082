import { isEqual } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Pattern } from '../../types'
import { CheckboxGroupPattern } from './checkbox-group-pattern'
import { ChildrenTree } from './children-tree'
import { ElementUIFacade } from './ElementUIFacade'
import { MultiSelect } from './multi-select'
import { NestedSelectorChildrenTree } from './nested-selector-children-tree'
import { Select } from './select'
import { TreeHeader } from './tree-header'
import { CheckboxClassifications } from './typeSection/checkbox-classifications'

interface ElementTreeProps {
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const ElementTree = ({
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	onDropSelectorChange,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: ElementTreeProps): ReactElement => {
	const [selectedOptions, setSelectedOptions] = useState<Array<string>>(
		element.multipleChildrenSelector?.selectedOptions || []
	)
	const [counterColumns, setCounterColumns] = useState(0)
	useEffect(() => {
		if (
			element.multipleChildrenSelector &&
			!isEqual(selectedOptions, element.multipleChildrenSelector.selectedOptions)
		) {
			setSelectedOptions(element.multipleChildrenSelector.selectedOptions)
		}
		/**
		 * selectedOptions is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedElementList])

	return (
		<>
			<TreeHeader element={element} showBlockInformation={showBlockInformation} />
			{element.isClassificationsPattern && element.classifications !== undefined && (
				<CheckboxClassifications
					element={element}
					selectedElementList={selectedElementList}
					onDropSelectorChange={onDropSelectorChange}
					counterColumns={counterColumns}
				/>
			)}
			{element.containsChildrenSelector && (
				<Select
					element={element}
					selectedElementList={selectedElementList}
					onDropSelectorChange={onDropSelectorChange}
					mandatoryIdArray={mandatoryIdArray}
					setMandatoryIdArray={setMandatoryIdArray}
					errorBannerVisibility={errorBannerVisibility}
				/>
			)}
			{element.multipleChildrenSelector && (
				<MultiSelect
					key={`mds-${element.elementId}-${selectedOptions}`}
					element={element}
					selectedElementList={selectedElementList}
					onDropSelectorChange={onDropSelectorChange}
				/>
			)}
			{element.isRangesAndFiguresPattern || element.pattern === Pattern.CHECKBOX_GROUP_PATTERN ? (
				<CheckboxGroupPattern
					index={index}
					element={element}
					testId={testId}
					selectedElementList={selectedElementList}
					onChange={onChange}
					defaultElements={defaultElements}
					showBlockInformation={showBlockInformation}
					onDropSelectorChange={onDropSelectorChange}
					mandatoryIdArray={mandatoryIdArray}
					setMandatoryIdArray={setMandatoryIdArray}
					errorBannerVisibility={errorBannerVisibility}
				/>
			) : element.isNestedSelector ? (
				<NestedSelectorChildrenTree
					index={index}
					element={element}
					testId={testId}
					selectedElementList={selectedElementList}
					onChange={onChange}
					defaultElements={defaultElements}
					showBlockInformation={showBlockInformation}
					mandatoryIdArray={mandatoryIdArray}
					counterUpdated={(selectedInBlock: number) => {
						setCounterColumns(selectedInBlock)
					}}
					onDropSelectorChange={onDropSelectorChange}
					setMandatoryIdArray={setMandatoryIdArray}
					errorBannerVisibility={errorBannerVisibility}
				/>
			) : (element.maximumElementsInOutput && element.maximumElementsInOutput.current === 0) ||
			  (element.multipleChildrenSelector && selectedOptions.length === 0) ? undefined : (
				<ChildrenTree
					index={index}
					element={element}
					testId={testId}
					selectedElementList={selectedElementList}
					onChange={onChange}
					defaultElements={defaultElements}
					showBlockInformation={showBlockInformation}
					mandatoryIdArray={mandatoryIdArray}
					counterUpdated={(selectedInBlock: number) => {
						setCounterColumns(selectedInBlock)
					}}
				/>
			)}
		</>
	)
}
