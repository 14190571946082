import { ReactElement, useEffect, useState } from 'react'
import { Divider, Panel, RadioButton } from '../../local-core-ui'
import Input from '../../local-core-ui/input/input'
import {
	RootState,
	SetCurrentLicensePayload,
	setInfoCurrentLicenseAction,
	useAppDispatch,
	useAppSelector
} from '../../store'
import { LicenseType } from '../../store/session/types/LicenseType'
import style from './licenses-tests-section.module.scss'

export function LicensesTestsSection(): ReactElement {
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const dispatch = useAppDispatch()

	const [currentLicense, setCurrentLicense] = useState<SetCurrentLicensePayload>(session.currentLicense)

	const defaultValues: Record<string, { purchasedSeats: number; recordLimit: number }> = {
		[LicenseType.LEGACY]: { purchasedSeats: -1, recordLimit: -1 },
		[LicenseType.BASIC_LICENSE]: { purchasedSeats: 2, recordLimit: 10000 },
		[LicenseType.ADVANCED_LICENSE]: { purchasedSeats: 6, recordLimit: 1000000 }
	}

	const updateCurrentLicense = (licenseType: LicenseType) => {
		setCurrentLicense({
			licenseType: licenseType,
			recordLimit: defaultValues[licenseType].recordLimit,
			usersLimit: defaultValues[licenseType].purchasedSeats
		})
	}

	useEffect(() => {
		dispatch(setInfoCurrentLicenseAction(currentLicense))
	}, [currentLicense])

	return (
		<Panel>
			<p>{'Module for test of licenses (Dev,QA env)'}</p>
			<Divider />
			<p>
				{
					'When changing the license type, the values for the purchased seats and the record limit are set to the default values indicated in the placeholder.'
				}
			</p>
			<div className={style.radiosLicenseTypeContainer}>
				<div className={style.subtitle}>{'Select License type'}</div>
				<RadioButton
					value={LicenseType.LEGACY}
					group={'license-type'}
					id={'legacy-type'}
					label={LicenseType.LEGACY}
					onChange={() => {
						updateCurrentLicense(LicenseType.LEGACY)
					}}
					testId={'legacy-license-type'}
					checked={currentLicense.licenseType === LicenseType.LEGACY}
				/>
				<RadioButton
					value={LicenseType.BASIC_LICENSE}
					group={'license-type'}
					id={'basic-type'}
					label={LicenseType.BASIC_LICENSE}
					onChange={() => updateCurrentLicense(LicenseType.BASIC_LICENSE)}
					testId={'basic-license-type'}
					checked={currentLicense.licenseType === LicenseType.BASIC_LICENSE}
				/>
				<RadioButton
					value={LicenseType.ADVANCED_LICENSE}
					group={'license-type'}
					id={'advanced-type'}
					label={LicenseType.ADVANCED_LICENSE}
					onChange={() => updateCurrentLicense(LicenseType.ADVANCED_LICENSE)}
					testId={'advanced-license-type'}
					checked={currentLicense.licenseType === LicenseType.ADVANCED_LICENSE}
				/>
			</div>
			<div className={style.subtitle}>{'Select Purchased seats'}</div>
			<Input
				id={'seats'}
				hint={`Default value: ${currentLicense.usersLimit}`}
				onChangeFunction={(newValue) => {
					setCurrentLicense({ ...currentLicense, usersLimit: parseInt(newValue) })
				}}
				value={
					(currentLicense.usersLimit && currentLicense.usersLimit > -1
						? currentLicense.usersLimit
						: '') as string
				}
				testId={'test-seats'}
				label={''}
				disabled={currentLicense.usersLimit !== undefined && currentLicense.usersLimit < 0}
				onBlurFunction={() => {
					if (
						isNaN(currentLicense.usersLimit as number) ||
						(currentLicense.usersLimit &&
							currentLicense.usersLimit <
								defaultValues[currentLicense.licenseType || LicenseType.LEGACY].purchasedSeats)
					) {
						setCurrentLicense({
							...currentLicense,
							usersLimit: defaultValues[currentLicense.licenseType || LicenseType.LEGACY].purchasedSeats
						})
					}
				}}
				showIconClean={false}
			/>
			<div className={style.subtitle}>{'Select # Records limit per upload'}</div>
			<Input
				id={'Records Limit'}
				hint={`Default value: ${currentLicense.recordLimit}`}
				onChangeFunction={(newValue) => {
					setCurrentLicense({ ...currentLicense, recordLimit: parseInt(newValue) })
				}}
				value={
					(currentLicense.recordLimit && currentLicense.recordLimit > -1
						? currentLicense.recordLimit
						: '') as string
				}
				label={''}
				testId={'test-record-limit'}
				disabled={currentLicense.recordLimit !== undefined && currentLicense.recordLimit < 0}
				showIconClean={false}
			/>
		</Panel>
	)
}
