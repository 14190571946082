import { DNBTooltip } from '@dnb-uux-design-system/react'
import { AxiosError, AxiosResponse } from 'axios'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconCheck from '../../assets/images/icon-green-check.svg'
import spinnerGif from '../../assets/images/spinner.gif'
import { useApi } from '../../hooks'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import { Button, Icon, LoadingState } from '../../local-core-ui'
import { useSource } from '../../queries/useSource'
import { useUpload } from '../../queries/useUpload'
import { JobDetailsResponse, MatchErrorSummary, Upload, UploadJobStep } from '../../types'
import { AppIdCopyToClipboardModal } from '../appID-s3pathway-copy-modal/components/appIdCopyToClipboardModal'
import { startDownloadProcess } from '../upload-tile/downloadFiles'
import { FilesDownloadModal } from '../upload-tile/files-download-modal'
import { buttonStates, defaultButtonColors } from '../upload-tile/upload-tile'
import styles from './upload-status-details.module.scss'
import { copyToClipboard, setShowCopiedMessage } from './upload-status-utilities'

export interface UploadProgressProps {
	upload: Upload
	buttonColors?: buttonStates
	s3Pathway: string
	downloadableFiles?: Array<string>
	testId: string
	sourceId: string
}

export const UploadStatusDetails = ({
	upload,
	buttonColors = defaultButtonColors,
	s3Pathway,
	downloadableFiles,
	testId,
	sourceId
}: UploadProgressProps): ReactElement => {
	const { t } = useTranslation()

	const apiClient = useApi()

	const [jobDetails, setJobDetails] = useState<JobDetailsResponse>()
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const [displayFilesDownloadModal, setDisplayFilesDownloadModal] = useState<boolean>(false)
	const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false)
	const [ingestionStep, setIngestionStep] = useState<UploadJobStep>()
	const [matchAppendStep, setMatchAppendStep] = useState<UploadJobStep>()
	const [matchAppendEmailVerificationStep, setMatchAppendEmailVerificationStep] = useState<UploadJobStep>()
	const [analysisStep, setAnalysisStep] = useState<UploadJobStep>()
	const [uploadStep, setUploadStep] = useState<UploadJobStep>()
	const [notifyStep, setNotifyStep] = useState<UploadJobStep>()
	const [salesforceSource, setSalesforceSource] = useState<boolean>(false)
	const [isConnectManageSource, setIsConnectManageSource] = useState<boolean>(false)
	const [showCopyToClipboardModal, setShowCopyToClipboardModal] = useState(false)
	const [textToClipboard, setTextToClipboard] = useState('')
	const [isAppId, setIsAppId] = useState(false)
	const sourceQuery = useSource(sourceId, true)
	const [enrichOnly, setEnrichOnly] = useState(false)
	const applicationId = useRef('')
	const isC4S = useModules(['C4S'])
	const enableDataRetentionPolicy = useFeatures(['EnableDataRetentionPolicy'])
	const [downloadableFilesAPI, setDownloadableFilesAPI] = useState<string[]>([])
	const [existsTXNErrors, setExistsTXNErrors] = useState<boolean>(false)

	const uploadQuery = useUpload(upload.uploadId)

	const createdDate = new Date(uploadQuery.data?.uploadCreatedTime ?? 0)
	const getMatchErrorSummary = () => {
		apiClient
			.get(`/pls/transactional-match/matchErrorSummary/${sourceId}/ALL`)
			.then((response: AxiosResponse<MatchErrorSummary>) => {
				const matchErrorResponse: MatchErrorSummary = response.data
				const matchError: string[] = []
				let dateTile = ''
				if (uploadQuery.data?.uploadCreatedTime !== undefined)
					dateTile = createdDate.toISOString().slice(0, 7).replace(/-/g, '')

				matchErrorResponse.errorDates.INGESTION?.forEach((errorDate: string) => {
					if (errorDate === dateTile) matchError.push('IMPORT_ERRORS')
				})

				matchErrorResponse.errorDates.PUBLISHING?.forEach((errorDate: string) => {
					if (errorDate === dateTile) matchError.push('PUBLISH_ERRORS')
				})

				if (matchError.length === 0) setExistsTXNErrors(false)
				else setExistsTXNErrors(true)

				setDownloadableFilesAPI(matchError)
			})
			.catch((error: AxiosError) => {
				console.error(error.message)
			})
	}

	useEffect(() => {
		/**
		 * TODO: Evaluate if it is possible to remove isActive from useEffect
		 */
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		let isActive = true
		if (uploadQuery.data) {
			setJobDetails(uploadQuery.data)
			isEnrichOnly(upload)
			applicationId.current = uploadQuery.data.uploadDiagnostics.applicationId
			uploadQuery.data.uploadJobSteps.forEach((jobStep) => {
				switch (jobStep.stepName) {
					case 'Ingestion':
						setIngestionStep(jobStep)
						break
					case 'Match_Append':
						setMatchAppendStep(jobStep)
						break
					case 'Email_Verification':
						setMatchAppendEmailVerificationStep(jobStep)
						break
					case 'Analysis':
						setAnalysisStep(jobStep)
						break
					case 'Publishing':
						setUploadStep(jobStep)
						break
					case 'Notifying':
						setNotifyStep(jobStep)
						break
				}
			})
		}
		if (sourceQuery.isSuccess && !sourceQuery.isFetching) {
			sourceQuery.data.integrationInfo?.integrationType == 'Salesforce'
				? setSalesforceSource(true)
				: setSalesforceSource(false)
			sourceQuery.data.integrationInfo?.integrationType == 'ConnectManage'
				? setIsConnectManageSource(true)
				: setIsConnectManageSource(false)
		}

		return () => {
			isActive = false
		}

		/**
		 * We only want to run this effect when uploadId, isFetched or isFetching from sourceQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upload.uploadId, uploadQuery.isFetched, sourceQuery.isFetching])

	useEffect(() => {
		if (downloadableFilesAPI.length === 0 && uploadQuery.data?.isApiAggregation) getMatchErrorSummary()
	}, [uploadQuery.data?.isApiAggregation])

	const totalRows = jobDetails?.uploadStats?.import?.submitted || undefined
	const ingestedRows = jobDetails?.uploadStats?.import?.successfullyIngested || undefined
	const matchedRows = jobDetails?.uploadStats?.match?.matched || undefined
	const unmatchedRows = jobDetails?.uploadStats?.match?.unmatched || undefined
	const notifyRows = jobDetails?.uploadStats?.notify?.submitted || undefined

	const timestampFormatter = (timestamp: number | undefined): string => {
		if (timestamp) {
			const date = new Date(timestamp)
			const month = date.getMonth() + 1
			const day = date.getDate()
			const year = date.getFullYear().toString().substr(-2)
			let hours = date.getHours()
			const minutes = date.getMinutes()
			const ampm = hours >= 12 ? 'PM' : 'AM'
			hours = hours % 12
			hours = hours ? hours : 12
			return `${month}.${day}.${year} / ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`
		}
		return ''
	}

	const isEnrichOnly = (upload: Upload) => {
		const { uploadStats } = upload
		const { matched, pendingReviewCnt, unmatched } = uploadStats?.match ?? {}
		const onlyEnrich = uploadStats === undefined || (matched === 0 && pendingReviewCnt === 0 && unmatched === 0)
		setEnrichOnly(onlyEnrich)
	}

	const getFiles = (fileTypesSelected: string) => {
		const genericErrorMessage = t('upload.title.loading.container.error.message')
		startDownloadProcess(
			uploadQuery.data?.isApiAggregation == undefined ? 0 : uploadQuery.data.uploadCreatedTime,
			uploadQuery.data?.isApiAggregation == undefined ? false : uploadQuery.data.isApiAggregation,
			sourceId,
			fileTypesSelected,
			setIsDownloading,
			upload.uploadId,
			apiClient,
			genericErrorMessage,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			() => {}
		)
	}

	const validateCopyToClipboard = (
		textToCopy: string,
		isAppId: boolean,
		setShowCopiedMessage?: setShowCopiedMessage
	) => {
		if (sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce' || isC4S) {
			setTextToClipboard(textToCopy)
			setShowCopyToClipboardModal(true)
			setIsAppId(isAppId)
		} else {
			copyToClipboard(textToCopy, setShowCopiedMessage)
		}
	}

	return (
		<div className={styles.uploadProgressContainer}>
			<AppIdCopyToClipboardModal
				open={showCopyToClipboardModal}
				onClose={() => setShowCopyToClipboardModal(false)}
				showButtonClose={true}
				testId={'copy-to-clipboard-modal'}
				appId={isAppId}
				textToClipboard={textToClipboard}
			/>
			<FilesDownloadModal
				salesforceSource={salesforceSource}
				displayFilesDownloadModal={displayFilesDownloadModal}
				setDisplayFilesDownloadModal={setDisplayFilesDownloadModal}
				onDownloadButtonClick={getFiles}
				downloadableFiles={uploadQuery.data?.isApiAggregation ? downloadableFilesAPI : downloadableFiles}
				testId={testId + '-fdm'}
				enrichOnly={enrichOnly}
				isApiBased={uploadQuery.data?.isApiAggregation}
			/>
			{uploadQuery.isFetching && (
				<div className={styles.loadingUploadDetails}>
					<LoadingState />
				</div>
			)}
			{!upload.isApiAggregation ? (
				<div className={styles.uploadSteps}>
					<div data-testid="upload-step-container-first" className={styles.uploadStep}>
						<span
							data-testid="first-step"
							className={`${styles.stepName} ${jobDetails?.uploadCreatedTime ? '' : styles.disabled}`}
						>
							{t('upload.status.detail.upload.step.1')}
						</span>
						<div className={styles.uploadProgressBar}>
							<div style={{ width: jobDetails?.uploadCreatedTime ? '100%' : '0' }} />
						</div>
						{jobDetails?.uploadCreatedTime && (
							<div className={styles.checkContainer}>
								<img className={styles.checkIcon} src={iconCheck} alt="step complete" />
							</div>
						)}
						<div data-testid="processed-data-uploaded" className={styles.processedDataContainer}>
							<div data-testid="date-container-upload" className={styles.dateContainer}>
								<div data-testid="date-uploaded" className={styles.date}>
									{timestampFormatter(jobDetails?.dropFileTime)}
								</div>
								<div className={styles.label} data-testid="upload-uploaded-label">
									{t('upload.status.detail.upload.uploaded')}
								</div>
							</div>
							<div data-testid="date-container-received" className={styles.dateContainer}>
								<div data-testid="upload-received-date" className={styles.date}>
									{timestampFormatter(jobDetails?.uploadCreatedTime)}
								</div>
								<div className={styles.label} data-testid="upload-received-label">
									{t('upload.status.detail.upload.received')}
								</div>
							</div>
						</div>
					</div>
					<div data-testid="upload-step-container-ingested" className={styles.uploadStep}>
						<span
							className={`${styles.stepName} ${ingestionStep ? '' : styles.disabled}`}
							data-testid="upload-ingested-step"
						>
							{t('upload.status.detail.upload.step.2')}
						</span>
						<div className={styles.uploadProgressBar}>
							<div style={{ width: ingestionStep ? '100%' : '0' }} />
						</div>
						{ingestionStep?.endTimestamp && (
							<div className={styles.checkContainer}>
								{(jobDetails?.status === 'ERROR' &&
									jobDetails?.currentStep?.stepName === ingestionStep?.stepName) ||
								jobDetails?.uploadDiagnostics.lastErrorStep === ingestionStep?.stepName ? (
									<div className={styles.alertIcon}>
										<Icon testId="alert-big-1-upload-status-details" type="alert-big" />
									</div>
								) : (
									<img className={styles.checkIcon} src={iconCheck} alt="step complete" />
								)}
							</div>
						)}
						{ingestionStep && (
							<div data-testid="processed-data-ingested" className={styles.processedDataContainer}>
								<div data-testid="date-container-ingested-started" className={styles.dateContainer}>
									<div data-testid="date-ingested-started" className={styles.date}>
										{timestampFormatter(ingestionStep?.startTimestamp)}
									</div>
									<div className={styles.label} data-testid="ingested-started-label">
										{t('upload.status.detail.upload.started')}
									</div>
								</div>
								<div data-testid="date-container-ingested-completed" className={styles.dateContainer}>
									<div data-testid="date-ingested-completed" className={styles.date}>
										{timestampFormatter(ingestionStep?.endTimestamp)}
									</div>
									<div className={styles.label} data-testid="ingested-completed-label">
										{t('upload.status.detail.upload.completed')}
									</div>
								</div>
								{totalRows && (
									<div data-testid="stats-container-total-rows" className={styles.statsContainer}>
										<div className={styles.label} data-testid="total-rows-label">
											{t('upload.status.detail.upload.total.rows')}
										</div>
										<div data-testid="number-total-rows" className={styles.number}>
											{totalRows}
										</div>
									</div>
								)}
								{ingestedRows && (
									<div data-testid="stats-container-ingested-rows" className={styles.statsContainer}>
										<div className={styles.label} data-testid="ingested-rows-label">
											{t('upload.status.detail.upload.ingested.rows')}
										</div>
										<div data-testid="number-ingested-rows" className={styles.number}>
											{ingestedRows}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
					<div data-testid="upload-step-container-enriched" className={styles.uploadStep}>
						<span
							className={`${styles.stepName} ${
								matchAppendStep && matchAppendEmailVerificationStep
									? ''
									: matchAppendStep
									? ''
									: styles.disabled
							}`}
							data-testid="file-matched-enriched-step"
						>
							{t('upload.status.detail.upload.step.3')}
						</span>
						<div className={styles.uploadProgressBar}>
							<div
								style={{
									width:
										matchAppendStep && matchAppendEmailVerificationStep
											? '100%'
											: matchAppendStep
											? '100%'
											: '0'
								}}
							/>
						</div>
						{matchAppendStep?.endTimestamp && matchAppendEmailVerificationStep?.endTimestamp ? (
							<div className={styles.checkContainer}>
								{(jobDetails?.status === 'ERROR' &&
									jobDetails?.currentStep?.stepName === matchAppendStep?.stepName) ||
								(jobDetails?.status === 'ERROR' &&
									jobDetails?.currentStep?.stepName === matchAppendEmailVerificationStep?.stepName) ||
								jobDetails?.uploadDiagnostics.lastErrorStep === matchAppendStep?.stepName ||
								jobDetails?.uploadDiagnostics.lastErrorStep ===
									matchAppendEmailVerificationStep?.stepName ? (
									<div className={styles.alertIcon}>
										<Icon testId="alert-big-2-upload-status-details" type="alert-big" />
									</div>
								) : (
									<img className={styles.checkIcon} src={iconCheck} alt="step complete" />
								)}
							</div>
						) : (
							matchAppendStep?.endTimestamp && (
								<div className={styles.checkContainer}>
									{(jobDetails?.status === 'ERROR' &&
										jobDetails?.currentStep?.stepName === matchAppendStep?.stepName) ||
									jobDetails?.uploadDiagnostics.lastErrorStep === matchAppendStep?.stepName ? (
										<div className={styles.alertIcon}>
											<Icon testId="alert-big-2-upload-status-details" type="alert-big" />
										</div>
									) : (
										<img className={styles.checkIcon} src={iconCheck} alt="step complete" />
									)}
								</div>
							)
						)}
						{matchAppendEmailVerificationStep && (
							<div className={styles.subStepName}>{t('upload.status.detail.upload.step.3.title1')}</div>
						)}
						{matchAppendEmailVerificationStep && (
							<div
								data-testid="processed-data-enriched-ev"
								className={`${styles.processedDataContainer}`}
							>
								<div data-testid="date-container-enriched-started-ev" className={styles.dateContainer}>
									<div data-testid="date-file-matched-enriched-started-ev" className={styles.date}>
										{timestampFormatter(matchAppendEmailVerificationStep?.startTimestamp)}
									</div>
									<div className={styles.label} data-testid="file-matched-enriched-started-label-ev">
										{t('upload.status.detail.upload.started')}
									</div>
								</div>
								<div
									data-testid="date-container-enriched-completed-ev"
									className={styles.dateContainer}
								>
									<div data-testid="date-file-matched-enriched-completed-ev" className={styles.date}>
										{timestampFormatter(matchAppendEmailVerificationStep?.endTimestamp)}
									</div>
									<div
										className={styles.label}
										data-testid="file-matched-enriched-completed-label-ev"
									>
										{t('upload.status.detail.upload.completed')}
									</div>
								</div>
							</div>
						)}
						{matchAppendEmailVerificationStep && matchAppendStep && (
							<div className={styles.subStepName}>{t('upload.status.detail.upload.step.3.title2')}</div>
						)}
						{matchAppendStep && (
							<div data-testid="processed-data-enriched" className={styles.processedDataContainer}>
								<div data-testid="date-container-enriched-started" className={styles.dateContainer}>
									<div data-testid="date-file-matched-enriched-started" className={styles.date}>
										{timestampFormatter(matchAppendStep?.startTimestamp)}
									</div>
									<div className={styles.label} data-testid="file-matched-enriched-started-label">
										{t('upload.status.detail.upload.started')}
									</div>
								</div>
								<div data-testid="date-container-enriched-completed" className={styles.dateContainer}>
									<div data-testid="date-file-matched-enriched-completed" className={styles.date}>
										{timestampFormatter(matchAppendStep?.endTimestamp)}
									</div>
									<div className={styles.label} data-testid="file-matched-enriched-completed-label">
										{t('upload.status.detail.upload.completed')}
									</div>
								</div>
							</div>
						)}
					</div>
					<div data-testid="upload-step-container-analyze" className={styles.uploadStep}>
						<span
							className={`${styles.stepName} ${analysisStep ? '' : styles.disabled}`}
							data-testid="file-analyzed-step"
						>
							{t('upload.status.detail.upload.step.4.output')}
						</span>
						<div className={styles.uploadProgressBar}>
							<div style={{ width: analysisStep ? '100%' : '0' }} />
						</div>
						{analysisStep?.endTimestamp && (
							<div className={styles.checkContainer}>
								{(jobDetails?.status === 'ERROR' &&
									jobDetails?.currentStep?.stepName === analysisStep?.stepName) ||
								jobDetails?.uploadDiagnostics.lastErrorStep === analysisStep?.stepName ? (
									<div className={`${styles.alertIcon} ${styles.lastOne}`}>
										<Icon testId="alert-big-3-upload-status-details" type="alert-big" />
									</div>
								) : (
									<img
										className={`${styles.checkIcon} ${styles.lastOne}`}
										src={iconCheck}
										alt="step complete"
									/>
								)}
							</div>
						)}
						{analysisStep && (
							<div data-testid="processed-data-analyzed" className={styles.processedDataContainer}>
								<div data-testid="date-container-analyzed-started" className={styles.dateContainer}>
									<div data-testid="date-file-analyzed-started" className={styles.date}>
										{timestampFormatter(analysisStep?.startTimestamp)}
									</div>
									<div className={styles.label} data-testid="file-analyzed-started-label">
										{t('upload.status.detail.upload.started')}
									</div>
								</div>
								<div data-testid="date-container-analyzed-completed" className={styles.dateContainer}>
									<div data-testid="date-file-analyzed-completed" className={styles.date}>
										{timestampFormatter(analysisStep?.endTimestamp)}
									</div>
									<div className={styles.label} data-testid="file-analyzed-completed-label">
										{t('upload.status.detail.upload.completed')}
									</div>
								</div>
								{matchedRows && (
									<div data-testid="stats-container-total-matches" className={styles.statsContainer}>
										<div data-testid="label-file-total-matches" className={styles.label}>
											{t('upload.status.detail.upload.total.matches')}
										</div>
										<div className={styles.number} data-testid="number-file-total-matches">
											{matchedRows}
										</div>
									</div>
								)}
								{unmatchedRows && (
									<div data-testid="stats-container-unmatched-rows" className={styles.statsContainer}>
										<div data-testid="label-file-total-unmatchedRows" className={styles.label}>
											{t('upload.status.detail.upload.unmatched.matches')}
										</div>
										<div className={styles.number} data-testid="number-file-total-unmatchedRows">
											{unmatchedRows}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
					{salesforceSource && (
						<div data-testid="upload-step-container-progress-bar" className={styles.uploadStep}>
							<span
								data-testid="upload-step"
								className={`${styles.stepName} ${uploadStep ? '' : styles.disabled}`}
							>
								{t('upload.status.detail.upload.step.5')}
							</span>
							<div className={styles.uploadProgressBar}>
								<div style={{ width: uploadStep ? '100%' : '0' }} />
							</div>
							{uploadStep?.endTimestamp && (
								<div className={styles.checkContainer}>
									{(jobDetails?.status === 'ERROR' &&
										jobDetails?.currentStep?.stepName === uploadStep?.stepName) ||
									jobDetails?.uploadDiagnostics.lastErrorStep === uploadStep?.stepName ? (
										<div className={`${styles.alertIcon} ${styles.lastOne}`}>
											<Icon testId="alert-big-4-upload-status-details" type="alert-big" />
										</div>
									) : (
										<img
											className={`${styles.checkIcon} ${styles.lastOne}`}
											src={iconCheck}
											alt="step complete"
										/>
									)}
								</div>
							)}
							{uploadStep && (
								<div data-testid="processed-data-times" className={styles.processedDataContainer}>
									<div data-testid="date-container-step-started" className={styles.dateContainer}>
										<div className={styles.date}>
											{timestampFormatter(uploadStep?.startTimestamp)}
										</div>
										<div className={styles.label}>{t('upload.status.detail.upload.started')}</div>
									</div>
									<div data-testid="date-container-step-completed" className={styles.dateContainer}>
										<div className={styles.date}>
											{timestampFormatter(uploadStep?.endTimestamp)}
										</div>
										<div className={styles.label}>{t('upload.status.detail.upload.completed')}</div>
									</div>
								</div>
							)}
						</div>
					)}
					{isConnectManageSource && (
						<div data-testid="upload-step-container-progress-bar" className={styles.uploadStep}>
							<span
								data-testid="upload-step"
								className={`${styles.stepName} ${notifyStep ? '' : styles.disabled}`}
							>
								{t('upload.status.detail.upload.step.5.connect.manage')}
							</span>
							<div className={styles.uploadProgressBar}>
								<div style={{ width: notifyStep ? '100%' : '0' }} />
							</div>
							{notifyStep?.endTimestamp && (
								<div className={styles.checkContainer}>
									{(jobDetails?.status === 'ERROR' &&
										jobDetails?.currentStep?.stepName === notifyStep?.stepName) ||
									jobDetails?.uploadDiagnostics.lastErrorStep === notifyStep?.stepName ? (
										<div className={`${styles.alertIcon} ${styles.lastOne}`}>
											<Icon testId="alert-big-4-upload-status-details" type="alert-big" />
										</div>
									) : (
										<img
											className={`${styles.checkIcon} ${styles.lastOne}`}
											src={iconCheck}
											alt="step complete"
										/>
									)}
								</div>
							)}
							{notifyStep && (
								<div data-testid="processed-data-times" className={styles.processedDataContainer}>
									<div data-testid="date-container-step-started" className={styles.dateContainer}>
										<div className={styles.date}>
											{timestampFormatter(notifyStep?.startTimestamp)}
										</div>
										<div className={styles.label}>{t('upload.status.detail.upload.started')}</div>
									</div>
									<div data-testid="date-container-step-completed" className={styles.dateContainer}>
										<div className={styles.date}>
											{timestampFormatter(notifyStep?.endTimestamp)}
										</div>
										<div className={styles.label}>{t('upload.status.detail.upload.completed')}</div>
									</div>
									{notifyRows && (
										<div
											data-testid="stats-container-total-matches"
											className={styles.statsContainer}
										>
											<div data-testid="label-file-total-matches" className={styles.label}>
												{t('upload.status.detail.upload.connect.manage.total.submitted')}
											</div>
											<div className={styles.number} data-testid="number-file-total-matches">
												{notifyRows}
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			) : undefined}
			<div className={styles.uploadButtonsContainer}>
				<div className={styles.uploadButtonContainer}>
					<Button
						type={'primary'}
						size={'medium'}
						short={true}
						text={t('upload.status.detail.upload.copy.path')}
						onClick={() => validateCopyToClipboard(s3Pathway, false, setShowCopiedMessage)}
						colors={buttonColors}
						testId={testId + '-copy-path'}
					/>
				</div>
				<div className={styles.uploadButtonContainer}>
					<Button
						type={'primary'}
						size={'medium'}
						short={true}
						text={t('upload.status.detail.upload.get.id')}
						onClick={() => validateCopyToClipboard(applicationId.current, true, setShowCopiedMessage)}
						colors={buttonColors}
						testId={testId + '-upload-id'}
					/>
				</div>
				<div className={styles.uploadButtonContainer}>
					{isDownloading ? (
						<div data-testid="spinner-upload-status" className={styles.wizardSpinner}>
							<img src={spinnerGif} alt="loading" />
						</div>
					) : (
						<>
							<DNBTooltip
								arrow
								content={
									enableDataRetentionPolicy && jobDetails?.status === 'CLEANED_UP'
										? t('upload.tile.button.download.disabled.tooltip')
										: null
								}
								placement="top"
							>
								<div>
									<Button
										type={'primary'}
										size={'medium'}
										short={true}
										text={t('upload.status.detail.upload.download')}
										onClick={() => setDisplayFilesDownloadModal(true)}
										isDisabled={
											downloadableFiles === undefined ||
											(jobDetails?.status !== 'FINISHED' && jobDetails?.status !== 'ERROR') ||
											(downloadableFilesAPI.length === 0 && uploadQuery.data?.isApiAggregation)
										}
										colors={buttonColors}
										testId={testId + '-detail-upload-download'}
									/>
								</div>
							</DNBTooltip>
						</>
					)}
				</div>
			</div>
			{showCopiedMessage && <div className={styles.copiedClipboardMessage}>{t('copied.to.clipboard')}</div>}
		</div>
	)
}
