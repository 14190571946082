import { AxiosInstance } from 'axios'
import { TFunction, useTranslation } from 'react-i18next'
import { QueryClient, useQueryClient } from 'react-query'
import { useFeatures, useModules, usePlatform } from '../../../hooks/useEntitlements'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../store'
import { ProjectWizardState } from '../../../store/projectWizard/types'

export class WizardContext {
	public isConnectManage = false
	public isC4S = false
	public enrichmentLibraryEnabled = false
	public enableApi = false
	public passDelimiterInfo = false
	public salesforcePlatform = false
	public c4sBetaEnabled = false
	public projectWizardState: ProjectWizardState
	public dispatch: TDispatch
	public queryClient: QueryClient
	public t: TFunction<'translation', undefined>
	public enableContactMatch = false
	public enableTradeUp = false
	public enableTradeUpC4S = false
	public apiClient: AxiosInstance
	public enableEmailVerification = false
	public enableInternationalContacts = false
	public enableAlternateFieldsRecomm = false
	public enableEnrichedOnlyMixedFile = false

	constructor(
		modulesHelper: typeof useModules,
		featuresHelper: typeof useFeatures,
		platformHelper: typeof usePlatform,
		selectorHelper: typeof useAppSelector,
		dispatchHelper: typeof useAppDispatch,
		queryClient: typeof useQueryClient,
		translationFunction: typeof useTranslation,
		apiClient: AxiosInstance
	) {
		this.isConnectManage = modulesHelper(['ConnectManage'])
		this.isC4S = modulesHelper(['C4S'])
		this.enrichmentLibraryEnabled = featuresHelper(['EnrichmentLibrary'])
		this.enableApi = featuresHelper(['EnableAPI'])
		this.passDelimiterInfo = featuresHelper(['EnableFileDelimiters'])
		this.salesforcePlatform = platformHelper('salesforce')
		this.c4sBetaEnabled = featuresHelper(['C4SBeta'])
		this.projectWizardState = selectorHelper((state: RootState) => state.projectWizard)
		this.dispatch = dispatchHelper()
		this.queryClient = queryClient()
		const { t } = translationFunction()
		this.t = t
		this.enableContactMatch = featuresHelper(['EnableContactMatching'])
		this.apiClient = apiClient
		this.enableTradeUp = featuresHelper(['EnableTradeUp'])
		this.enableTradeUpC4S = platformHelper('salesforce')
			? featuresHelper(['EnableTradeUpC4S']) && featuresHelper(['EnableTradeUp'])
			: featuresHelper(['EnableTradeUp'])
		this.enableEmailVerification = platformHelper('salesforce')
			? featuresHelper(['EnableEmailVerification']) && featuresHelper(['EnableEmailVerificationC4S'])
			: featuresHelper(['EnableEmailVerification'])
		this.enableInternationalContacts = featuresHelper(['InternationalContacts'])
		this.enableAlternateFieldsRecomm =
			(featuresHelper(['EnableMatchRecommendation']) &&
				!(modulesHelper(['C4S']) && platformHelper('salesforce'))) ||
			(featuresHelper(['EnableMatchRecommendationC4S']) && modulesHelper(['C4S']) && platformHelper('salesforce'))
		this.enableEnrichedOnlyMixedFile = featuresHelper(['EnableEnrichedOnlyMixedFile'])
	}
}
