import { DNBButton, DNBSnackbar } from '@dnb-uux-design-system/react'
import { ReactElement, useEffect, useState } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './edit-connect-manage-connection.module.scss'

import { Divider } from '@mui/material'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
	ColorBlueBrand,
	ColorGray,
	ColorGreenBottleLight
} from '../../../../design-tokens/build/shared/token-colors.json'
import { useApi } from '../../hooks'
import { ConnectionDetail } from '../../queries/api/getConnection'
import { System } from '../../queries/api/getConnections'
import { DataConnection, SuccessResponse } from '../../queries/api/updateConnection'
import { useUpdateConnection } from '../../queries/useUpdateConnection'
import { DeleteConnectionModal } from '../delete-connection-modal/delete-connection-modal'

type SnackbarVariant = 'info' | 'success' | 'warning' | 'error'
interface snacknarConfiguration {
	variant: SnackbarVariant
	isOpen: boolean
	text: string
}

interface EditConnectManageConnectionProps {
	connection: ConnectionDetail
	onDeleteConnection: () => void
	onCancelEdit: () => void
	onSuccessEdit: (updatedConnectionDetail: ConnectionDetail) => void
}

export const EditConnectManageConnection = ({
	connection,
	onDeleteConnection,
	onCancelEdit,
	onSuccessEdit
}: EditConnectManageConnectionProps): ReactElement => {
	// Maximum length allowed for a URL based on browser standards
	const MAX_URL_LENGTH = 2083
	const STAR_PREFIX = '*********'

	const { t } = useTranslation()
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [data, setData] = useState<DataConnection>({
		apiUrl: connection.authenticationSummary?.apiUrl || '',
		apiKey: connection.authenticationSummary?.apiKey
			? STAR_PREFIX.concat(connection.authenticationSummary?.apiKey)
			: '',
		displayName: connection.displayName || '',
		nameConnection: connection.name || ''
	})
	const [disableVerifier, setDisableVerifier] = useState<boolean>(false)
	const [isDataValid, setIsDataValid] = useState<boolean>(false)
	const [dataVerified, setDataVerified] = useState<boolean | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isNameModified, setIsNameModified] = useState<boolean>(false)
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
	const [isApiKeyMasked, setIsApiKeyMasked] = useState<boolean>(false)
	const modifyConnectionMutation = useUpdateConnection()
	const [snackbarConfiguration, setSnackbarConfiguration] = useState<snacknarConfiguration>({
		isOpen: false,
		variant: 'info',
		text: ''
	})
	const apiClient = useApi((error: AxiosError) => {
		const connectManageVerificationFailure = 'LEDP_90010'
		const errorMalformedApiUrl = 'LEDP_90011'
		const errorConnectManageUnauthorized = 'LEDP_19001'

		const responseData = error.response?.data as { errorCode?: string }
		const errorCode = responseData?.errorCode
		if (
			errorCode !== connectManageVerificationFailure &&
			errorCode !== errorMalformedApiUrl &&
			errorCode !== errorConnectManageUnauthorized
		) {
			alert(JSON.stringify(responseData) || error)
		}

		return Promise.reject(error)
	})

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newName = e.target.value.trim()

		setIsNameModified(newName !== '' && newName !== connection.displayName)

		setData({ ...data, displayName: newName })
	}

	const maskApiKey = (apiKey: string) => {
		return apiKey.length > 4 ? `${'*'.repeat(apiKey.length - 4)}${apiKey.substring(apiKey.length - 4)}` : apiKey
	}

	const isURL = (str: string) => {
		const urlRegex =
			'^(https?://)((?!-)[a-zA-Z0-9]{1,63}(?<!-).)+[a-zA-Z]{2,63}.com(.[a-zA-Z]{2,63})?(/[a-zA-Z0-9-/]*)?$'
		const url = new RegExp(urlRegex, 'i')
		return str.length < MAX_URL_LENGTH && url.test(str)
	}

	const change = (value: boolean) => {
		setIsDataValid(value)
		setIsLoading(value)
	}

	const verifiedData = async (systemType: string, data: DataConnection) => {
		const { apiUrl, apiKey } = { ...data }
		const checkDataAPI = `/pls/integration/${systemType}/organization/verifyConnection?apiKey=${apiKey}&apiUrl=${apiUrl}`
		await apiClient
			.get(checkDataAPI)
			.then((response) => {
				setIsDataValid(response.data.Success)
			})
			.catch((error) => {
				const errorMsg = error?.response?.data?.errorMsg || error.message
				change(false)
				setIsApiKeyMasked(false)
				setSnackbarConfiguration({
					isOpen: true,
					text: errorMsg,
					variant: 'warning'
				})
			})
		setDataVerified(true)
	}

	const onEditConnection = () => {
		const dataConnection: DataConnection = {
			displayName: data.displayName,
			nameConnection: data.nameConnection,
			apiKey: isApiKeyMasked ? data.apiKey : undefined,
			apiUrl: isApiKeyMasked ? data.apiUrl : undefined
		}

		const handleSuccess = (displayName: string, name: string, updated: number) => {
			onSuccessEdit({
				...connection,
				displayName,
				name,
				updated,
				authenticationSummary: {
					...(connection.authenticationSummary ?? {}),
					apiUrl: data.apiUrl
				}
			})
			initializeData('Applied')
			setSnackbarConfiguration({
				isOpen: true,
				text:
					dataVerified && isDataValid
						? t('connections.edit.connection.detail.saved')
						: t('connections.edit.connection.name.saved'),
				variant: 'success'
			})
		}

		const handleError = (errors?: string[]) => {
			setSnackbarConfiguration({
				isOpen: true,
				text: errors ? errors.join(' - ') : t('connections.edit.connection.error'),
				variant: 'warning'
			})
		}

		modifyConnectionMutation.mutateAsync(
			{ connectionData: dataConnection, systemType: System.ConnectManage },
			{
				onSuccess: (response) => {
					const responseUpdate = response as SuccessResponse

					if (responseUpdate.Success !== undefined) {
						responseUpdate.Success
							? handleSuccess(data.displayName, data.nameConnection, Date.now())
							: handleError(responseUpdate.Errors)
					} else {
						responseUpdate.name !== undefined && responseUpdate.name.trim() !== ''
							? handleSuccess(
									responseUpdate.displayName || data.displayName,
									responseUpdate.name || data.nameConnection,
									responseUpdate.updated || Date.now()
							  )
							: handleError(responseUpdate.Errors)
					}
				}
			}
		)
	}

	const initializeData = (cleanType: string) => {
		const maskedKey = connection.authenticationSummary?.apiKey
			? STAR_PREFIX + connection.authenticationSummary.apiKey.slice(-4)
			: ''

		if (cleanType === 'Canceled') {
			setData({
				apiUrl: connection.authenticationSummary?.apiUrl || '',
				apiKey: maskedKey,
				displayName: connection.displayName || '',
				nameConnection: connection.name || ''
			})
		} else if (cleanType === 'Applied') {
			setData({
				apiUrl: data.apiUrl || '',
				apiKey: maskedKey,
				displayName: data.displayName || '',
				nameConnection: data.nameConnection || ''
			})
		}

		setIsApiKeyMasked(false)
		setIsDataValid(false)
		setDataVerified(undefined)
		setIsNameModified(false)
		setIsButtonDisabled(true)
	}

	useEffect(() => {
		if (data.apiUrl !== '' && isURL(data.apiUrl || '') && data.apiKey !== '') {
			setDisableVerifier(false)
		} else {
			setDisableVerifier(true)
		}
	}, [data])

	useEffect(() => {
		setIsLoading(false)
		if (data.apiKey !== '') setIsApiKeyMasked(isDataValid)
	}, [dataVerified, isDataValid])

	useEffect(() => {
		const shouldEnableButton =
			(data.displayName.trim() !== '' && data.displayName !== connection.displayName) ||
			(dataVerified && isDataValid && data.displayName.trim() !== '')

		setIsButtonDisabled(!shouldEnableButton)
	}, [dataVerified, isDataValid, data.displayName, isNameModified])

	return (
		<>
			<DeleteConnectionModal
				openModal={openDeleteModal}
				setOpenModal={setOpenDeleteModal}
				isLoading={isLoading}
				connectionName={connection.name || ''}
				onDeleteConnection={onDeleteConnection}
				displayName={connection.displayName}
			></DeleteConnectionModal>
			<div className={styles.buttonsSection}>
				<div className={styles.leftButtons}>
					<DNBButton
						size="small"
						variant="secondary"
						onClick={() => setOpenDeleteModal(true)}
						data-testid="create-project"
						className={styles.button}
					>
						<div className={styles.icon}>
							<Icon testId="chevron-left-user-menu" type="trash" size="mini" color={ColorBlueBrand} />
						</div>
						{t('connections.delete.title')}
					</DNBButton>
				</div>
				<div className={styles.rightButtons}>
					<DNBButton
						size="small"
						variant="secondary"
						onClick={() => {
							onCancelEdit()
							initializeData('Canceled')
						}}
						data-testid="cancel-update"
						className={styles.button}
					>
						{t('confirmation.modal.cancel')}
					</DNBButton>
					<DNBButton
						size="small"
						variant="primary"
						onClick={() => {
							onEditConnection()
						}}
						data-testid="confirm-update"
						className={styles.button}
						disabled={isButtonDisabled}
					>
						{t('text.apply')}
					</DNBButton>
				</div>
			</div>

			<div className={styles.inputSection}>
				<div className={styles.sectionTitle}>{t('connections.edit.connection.name')}</div>
				<Divider color={ColorGray} />
				<div className={styles.sectionQuestion}>{t('connections.edit.connection.name.question')}</div>

				<div>
					<input
						id={`name-input`}
						className={styles.textInput}
						type="text"
						placeholder={t('connections.edit.name.placeholder') || ''}
						disabled={false}
						onChange={(e) => {
							handleNameChange(e)
						}}
						value={data.displayName}
					/>
				</div>
			</div>

			<div className={styles.inputSection}>
				<div className={styles.sectionTitle}>{t('connections.edit.detail')}</div>
				<Divider color={ColorGray} />
				<div className={styles.sectionQuestion}>{t('connections.edit.detail.question')}</div>

				<div className={styles.fieldTitle}>{t('title.input.url.modal.new.connection')}</div>
				<div>
					<input
						id={`url-input`}
						className={styles.textInput}
						type="text"
						placeholder={t('connections.edit.url.placeholder') || ''}
						onChange={(e) => {
							setData({ ...data, apiUrl: e.target.value, apiKey: '' })
							change(false)
							setIsApiKeyMasked(false)
						}}
						disabled={false}
						value={data.apiUrl}
					/>
				</div>

				<div className={styles.fieldTitle}>{t('title.input.key.modal.new.connection')}</div>
				<div>
					<input
						id={`key-input`}
						className={styles.textInput}
						type="text"
						placeholder={t('connections.edit.api.placeholder') || ''}
						onChange={(e) => {
							if (e.target.value === '') {
								setIsApiKeyMasked(false)
							}
							setData({ ...data, apiKey: e.target.value })
							change(false)
						}}
						onFocus={() => {
							if (data.apiKey === STAR_PREFIX.concat(connection.authenticationSummary?.apiKey || '')) {
								setData({ ...data, apiKey: '' })
							}
						}}
						disabled={isApiKeyMasked}
						value={isApiKeyMasked ? maskApiKey(data.apiKey || '') : data.apiKey}
						autoComplete="off"
					/>
				</div>

				{dataVerified && isDataValid ? (
					<div className={styles.containerMsgSucces}>
						<Icon type={'accepted'} color={ColorGreenBottleLight} size={'mini'} />
						<span className={styles.textSuccess}>{t('text.modal.validation.success')}</span>
					</div>
				) : (
					<DNBButton
						disabled={
							data.apiKey === STAR_PREFIX.concat(connection.authenticationSummary?.apiKey || '')
								? true
								: disableVerifier
						}
						isLoading={isLoading}
						variant={'secondary'}
						onClick={() => {
							setIsLoading(true)
							verifiedData('connectManage', data)
							setIsApiKeyMasked(true)
						}}
					>
						{t('button.text.modal.new.connection.validator')}
					</DNBButton>
				)}
			</div>
			<DNBSnackbar
				transitionDuration={1000}
				variant={snackbarConfiguration.variant}
				isOpen={snackbarConfiguration.isOpen}
				handleClose={() => {
					setSnackbarConfiguration({ isOpen: false, text: '', variant: 'info' })
				}}
				snackbarText={snackbarConfiguration.text}
				duration={6000}
				hasCloseIcon={false}
			/>
		</>
	)
}
