import { DNBButton } from '@dnb-uux-design-system/react'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './record-limit-exceeded.module.scss'

interface RecordLimitExceededProps {
	maxFileRows: number
	totalRows: number
	onCancel: () => void
	onContinue: () => void
	testId: string
}
export const RecordLimitExceeded: FC<RecordLimitExceededProps> = ({
	maxFileRows,
	totalRows,
	onContinue,
	onCancel,
	testId
}: RecordLimitExceededProps) => {
	const { t } = useTranslation()
	return (
		<div className={styles.recordLimitModalContent}>
			<div className={styles.textWrapper}>
				<p className={styles.title}>{t('upload.chooser.modal.recordLimit.title')}</p>

				<p className={styles.description}>
					<Trans
						i18nKey="upload.chooser.modal.recordLimit.description"
						tOptions={{ recordLimit: maxFileRows.toLocaleString(), totalRows: totalRows.toLocaleString() }}
					/>
				</p>
			</div>
			<div className={styles.confirmationButtonsContainer}>
				<DNBButton
					size="default"
					variant="secondary"
					onClick={onContinue}
					data-testid={`${testId}-record-limit-modal-continue`}
				>
					{t('upload.chooser.modal.recordLimit.button.continue')}
				</DNBButton>
				<DNBButton
					size="default"
					variant="primary"
					onClick={onCancel}
					data-testid={`${testId}-record-limit-modal-cancel`}
				>
					{t('upload.chooser.modal.recordLimit.button.cancel')}
				</DNBButton>
			</div>
		</div>
	)
}
