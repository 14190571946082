import { AxiosError, AxiosInstance } from 'axios'
import { getApiClient } from '../utilities'
import { TDispatch } from './index'
import { signOut } from './session'

export const getApiClient4Thunks = (
	dispatch: TDispatch,
	errorHandler?: (error: AxiosError) => Promise<never>
): AxiosInstance => {
	const apiClient: AxiosInstance = getApiClient(() => dispatch(signOut()), dispatch, errorHandler)
	return apiClient
}
