import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import { CheckboxOption, DisplayedElement, FlattenedArrayConfiguration } from '../../../types'
import { ElementUIFacade } from '../ElementUIFacade'

const buildArrayElements = (
	entitledElements: Array<ElementUIFacade>,
	flattenedConfiguration: FlattenedArrayConfiguration,
	root: ElementUIFacade,
	selectorValueRef: MutableRefObject<number>
) => {
	return flattenedConfiguration.patternConfig.displayedElements.reduce(
		(groupedElements: Array<ElementUIFacade>, displayedElement: DisplayedElement) => {
			const includedElements = entitledElements.filter((element) =>
				displayedElement.elements.includes(element.elementId)
			)
			if (includedElements.length > 0) {
				const groupParent: ElementUIFacade = {
					parent: root,
					elementId: `${displayedElement.name.replace(/ /g, '')}Id`,
					displayName: displayedElement.name,
					description: includedElements[0].description,
					level: includedElements[0].level,
					dataType: includedElements[0].dataType,
					example: includedElements[0].example,
					showChildrenBlocks: false,
					considerChildrenForCount: false,
					maximumElementsInOutput: selectorValueRef,
					isSelectedByDefault: displayedElement.isSelectedByDefault,
					belongsToRequiredGroup: displayedElement.belongsToRequiredGroup
				}
				includedElements.sort((a: ElementUIFacade, b: ElementUIFacade) => {
					if (a.elementId > b.elementId) {
						return 1
					} else if (a.elementId < b.elementId) {
						return -1
					} else {
						return 0
					}
				})
				groupParent.childs = includedElements
				groupedElements.push(groupParent)
			}
			return groupedElements
		},
		[]
	)
}

const sortCheckboxOptions = (checkboxOptions: Array<CheckboxOption>): Array<CheckboxOption> => {
	const halfLength = Math.ceil(checkboxOptions.length / 2)
	const firstHalf = checkboxOptions.slice(0, halfLength)
	const secondHalf = checkboxOptions.slice(halfLength)
	return firstHalf.reduce((mixedOptions: Array<CheckboxOption>, option, index) => {
		mixedOptions.push(option)
		if (secondHalf.length > index) {
			mixedOptions.push(secondHalf[index])
		}
		return mixedOptions
	}, [])
}

export const buildCheckboxGroupFlattenedArray = (
	entitledElements: Array<ElementUIFacade>,
	flattenedConfiguration: FlattenedArrayConfiguration,
	t: TFunction<'translation'>,
	selectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (entitledElements.length) {
		const root: ElementUIFacade = {
			blockId: t(entitledElements[0].blockId || ''),
			elementId: flattenedConfiguration.arrayId,
			displayName: flattenedConfiguration.arrayName,
			description: flattenedConfiguration.arrayDescription,
			level: entitledElements[0].level || '',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: false,
			considerChildrenForCount: true,
			maximumElementsInOutput: selectorValueRef,
			checkboxGroupOptions: sortCheckboxOptions(flattenedConfiguration.patternConfig.checkboxOptions || []).map(
				(option) => ({
					value: option.key,
					id: option.key,
					isChecked: false,
					label: option.name,
					elements: option.elements
				})
			),
			checkboxGroupName: flattenedConfiguration.patternConfig.checkboxGroupName,
			pattern: flattenedConfiguration.pattern
		}
		tree.push(root)
		root.childs = buildArrayElements(entitledElements, flattenedConfiguration, root, selectorValueRef)
	}
	return tree
}
