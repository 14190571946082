import { ColorBlueBrandLight, ColorSlateLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBRadio } from '@dnb-uux-design-system/react'
import cx from 'classnames'
import { ChangeEvent, FC, useEffect } from 'react'
import { Divider } from '../../../../local-core-ui'
import styles from './additional-config-group.module.scss'

interface AdditionalConfigGroupProps {
	title: string
	valueSelected: string
	options: Array<{ label: string; value: string }>
	isDisabled: boolean
	onChangeFunction(newValue: string): void
	isSubSection?: boolean
}
export const AdditionalConfigGroup: FC<AdditionalConfigGroupProps> = ({
	title,
	options,
	valueSelected,
	isDisabled,
	onChangeFunction,
	isSubSection = false
}) => {
	useEffect(() => {
		if (valueSelected === 'undefined') {
			onChangeFunction('false')
		}
	}, [valueSelected])

	return (
		<div className={styles.containerAdditionalConfig}>
			{isSubSection && (
				<div className={styles.dividerLine}>
					<Divider size="medium" color={isDisabled ? ColorSlateLighter : ColorBlueBrandLight} />
				</div>
			)}
			<div className={cx(`${isDisabled ? styles.titleDisabled : ''}`)}>{title}</div>
			<div className={styles.containerOptionsRadio}>
				{options.map((option, idx) => (
					<DNBRadio
						label={option.label}
						size="small"
						value={option.value}
						checked={valueSelected === option.value}
						data-testid={`radio-option-${idx}`}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							onChangeFunction(e.target.value)
						}}
						key={`radio-option-${idx}`}
						disabled={isDisabled}
					/>
				))}
			</div>
		</div>
	)
}
