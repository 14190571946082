import { RefreshInfoConfig } from './RefreshInfoConfig'
import { RefreshRematchConfig } from './RefreshRematchConfig'

export enum IntegrationType {
	DCP = 'DCP',
	Salesforce = 'Salesforce',
	ConnectManage = 'ConnectManage'
}

export type DocumentType =
	| 'Account'
	| 'Contact'
	| 'Lead'
	| 'Opportunity'
	| 'ProductPurchase'
	| 'ProductBundle'
	| 'ProductHierarchy'
	| 'WebVisit'
	| 'WebVisitPathPattern'
	| 'WebVisitSourceMedium'
	| 'Impression'
	| 'CustomIntent'
	| 'CampaignActivity'
	| 'PerformanceReport'
	| 'ApiRecords'

export interface ConnectManageInfo {
	connectManageEnabled: boolean
	connectionName: string
	displayName?: string
	externalSourceId: string
}

export interface IntegrationInfo {
	integrationType: IntegrationType
	documentType: DocumentType
	isTransactional: boolean
	refreshRematchConfig: RefreshRematchConfig
	refreshInfo: RefreshInfoConfig
	connectManageInfo: ConnectManageInfo
	completedConnectManageSubStep: boolean
}
