import isEmpty from 'lodash/isEmpty'
import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import { isTradeUpEnabled } from '../../../helpers/validateTradeUp'
import {
	showModalConflictFamilyTreeBlocks,
	syncElementsInPreview,
	updateCurrentProjectAction,
	updateProceedConfirmationRequired
} from '../../../store/projectWizard/actions'
import { showOrderReasonModalAction } from '../../../store/projectWizard/actions/showOrderReasonModalAction'
import { updateDeprecatedModal } from '../../../store/projectWizard/actions/updateDeprecatedModal'
import { createOrUpdateEnrichmentRules, createOrUpdateLayout } from '../../../store/projectWizard/thunks'
import { EnrichingBlock } from '../../../store/projectWizard/types'
import { EnrichCrmDataSource } from './enriching-crm-source/enrich-crm-data-source'
import { WizardComposerEnrichmentApproach } from './enrichment-approach/WizardComposerEnrichmentApproach'
import { EnrichmentPreviewer } from './enrichmentPreviewer/enrichmentPreviewer'
import { WizardComposerChooseFields } from './WizardComposerChooseFields'

export const WizardComposerEnrichingStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	const hasCRMSource = context.projectWizardState.currentProject.thirdPartyIntegration !== undefined
	const enableEnrichmentRules = context.isC4S && hasCRMSource

	const haveElementSelectedOfFamily = (datablock: EnrichingBlock, levelName: string) => {
		const removedElementsInPreview = context.projectWizardState.enrichmentPreviewSubStep.removedElements
		return (
			datablock.elements.filter((element) => {
				if (element.level === levelName) {
					return !removedElementsInPreview.includes(element) || removedElementsInPreview.length === 0
				}
				return false
			}).length > 0
		)
	}
	const isValidFamilyTreeDatablocksSelection = () => {
		const selectedHierarchyConnectionsElements = context.projectWizardState.currentProject.enrichingLayout.filter(
			(datablockSelected: EnrichingBlock) => datablockSelected.blockId === 'hierarchyconnections'
		)
		if (selectedHierarchyConnectionsElements.length > 0) {
			const haveUpwardFamilyTreeElements = haveElementSelectedOfFamily(
				selectedHierarchyConnectionsElements[0],
				'Upward Family Tree'
			)
			const haveFullFamilyTreeElements = haveElementSelectedOfFamily(
				selectedHierarchyConnectionsElements[0],
				'Full Family Tree'
			)
			return !(haveUpwardFamilyTreeElements && haveFullFamilyTreeElements)
		}
		return true
	}

	const isRequiredOrderReason = () => {
		const dataBlockWithRequiredOrderReason = new Set(['financialstrengthinsight', 'paymentinsight'])
		const dataBlockSelected = context.projectWizardState.currentProject.enrichingLayout.filter(
			(dataBlock: EnrichingBlock) => dataBlockWithRequiredOrderReason.has(dataBlock.blockId)
		)
		return dataBlockSelected.length > 0 && !context.projectWizardState.currentProject.orderReason
	}

	const saveData = async () => {
		if (!isEmpty(context.projectWizardState.enrichmentPreviewSubStep.deprecatedElements)) {
			context.dispatch(updateDeprecatedModal(true))
			return Promise.reject()
		} else if (!isValidFamilyTreeDatablocksSelection()) {
			context.dispatch(showModalConflictFamilyTreeBlocks(true))
			return Promise.reject()
		} else if (isRequiredOrderReason()) {
			context.dispatch(showOrderReasonModalAction(true))
			return Promise.reject()
		} else {
			const isActiveTradeUp = isTradeUpEnabled(
				context.enableTradeUp,
				context.enableTradeUpC4S,
				context.projectWizardState.currentProject.source.integrationInfo?.integrationType,
				context.projectWizardState.currentProject.source.entityType,
				context.projectWizardState.currentProject.versionSF
			)
			const isEnabledInternationalContacts = context.enableInternationalContacts
			{
				context.dispatch(syncElementsInPreview())
				await context
					.dispatch(
						createOrUpdateLayout(context.queryClient, isActiveTradeUp, isEnabledInternationalContacts)
					)
					.then(() => {
						context.dispatch(updateProceedConfirmationRequired(false))
					})
					.catch((err) => {
						throw err
					})
			}
		}
	}

	const enrichmentLibraryFirstStep = {
		title: 'project.wizard.step.title.enriching.approach',
		state: () =>
			!isEmpty(context.projectWizardState.currentProject.enrichingApproach)
				? context.projectWizardState.currentProject.enrichingApproach === 'data-blocks'
					? WizardComposerSubStepStates.complete
					: WizardComposerSubStepStates.pending
				: WizardComposerSubStepStates.pending,
		onNext: async () => {
			const isActiveTradeUp = isTradeUpEnabled(
				context.enableTradeUp,
				context.enableTradeUpC4S,
				context.projectWizardState.currentProject.source.integrationInfo?.integrationType,
				context.projectWizardState.currentProject.source.entityType,
				context.projectWizardState.currentProject.versionSF
			)
			if (isActiveTradeUp) {
				context.dispatch(updateCurrentProjectAction({ entityTradeUp: 'hq', tradeUp: false }))
			}
		},
		onPrevious: undefined,
		isNextEnabled: () => {
			return !isEmpty(context.projectWizardState.currentProject.enrichingApproach)
		},
		route: { path: '/chooseApproach', component: WizardComposerEnrichmentApproach }
	}

	const basicSubSteps = [
		{
			title: 'project.wizard.step.title.enrich.output',
			state: () =>
				!isEmpty(context.projectWizardState.currentProject.enrichingLayout)
					? WizardComposerSubStepStates.complete
					: WizardComposerSubStepStates.pending,
			onNext: undefined,
			onPrevious: async () =>
				context.dispatch(
					updateCurrentProjectAction({
						currentTemplate: {
							...context.projectWizardState.currentProject.currentTemplate,
							templateId: '',
							templateName: '',
							generateJson: false
						}
					})
				),
			onSaveProgress: async () => {
				await saveData()
			},
			isNextEnabled: () =>
				!isEmpty(context.projectWizardState.currentProject.enrichingLayout) &&
				!context.projectWizardState.loadingNextStep,
			route: { path: '/output', component: WizardComposerChooseFields }
		},
		{
			title: 'enriching.subStep.name.previewer',
			state: () =>
				!isEmpty(context.projectWizardState.currentProject.enrichingLayout) &&
				context.projectWizardState.currentProject.layoutId
					? WizardComposerSubStepStates.complete
					: WizardComposerSubStepStates.pending,
			onNext: async () => {
				await saveData()
			},
			onPrevious: async () => {
				context.dispatch(syncElementsInPreview())
			},
			onSaveProgress: async () => {
				await saveData()
			},
			isNextEnabled: () =>
				!isEmpty(context.projectWizardState.currentProject.enrichingLayout) &&
				(!hasCRMSource || !!context.projectWizardState.currentProject.isValidIntegrationEnrichmentLayout) &&
				!context.projectWizardState.loadingNextStep,
			route: { path: '/preview', component: EnrichmentPreviewer }
		}
	]

	const getSubSteps = (): WizardComposerSubStepDefinition[] => {
		let enrichmentSubsteps: WizardComposerSubStepDefinition[] = []
		if (context.enrichmentLibraryEnabled) {
			enrichmentSubsteps = [enrichmentLibraryFirstStep, ...basicSubSteps]
		} else enrichmentSubsteps = basicSubSteps
		if (enableEnrichmentRules) {
			enrichmentSubsteps.push({
				title: 'project.wizard.step.title.enrich.output',
				state: () =>
					isEmpty(context.projectWizardState.currentProject.enrichmentRules)
						? WizardComposerSubStepStates.pending
						: WizardComposerSubStepStates.complete,
				onNext: async () => {
					await context
						.dispatch(createOrUpdateEnrichmentRules(context.queryClient))
						.then()
						.catch((err) => {
							throw err
						})
				},
				onPrevious: undefined,
				isNextEnabled: () => {
					return (
						!isEmpty(context.projectWizardState.currentProject.enrichmentRules) &&
						!context.projectWizardState.loadingNextStep
					)
				},
				route: { path: '/enrichRules', component: EnrichCrmDataSource }
			} as WizardComposerSubStepDefinition)
		}
		return enrichmentSubsteps
	}

	const evaluateProgress = (): number => {
		if (isEmpty(context.projectWizardState.currentProject.enrichingLayout)) return 0
		if (context.projectWizardState.currentProject.layoutId === undefined) return 80
		if (context.projectWizardState.enrichmentPreviewSubStep.isUpdatingOrSaving) return 90
		if (
			enableEnrichmentRules &&
			(context.projectWizardState.currentProject?.enrichmentRules?.length === undefined ||
				context.projectWizardState.currentProject?.enrichmentRules?.length <= 5)
		)
			return 96
		return 100
	}

	const subSteps = getSubSteps()

	return {
		title: 'project.wizard.step.title.enrich.output',
		progress: evaluateProgress,
		onNext: undefined,
		onPrevious: undefined,
		onSaveProgress: async () => {
			const url = new URL(window.location.href)
			const currentStep = subSteps.find((substep) => url.pathname.includes(substep.route.path))
			if (currentStep?.onSaveProgress) {
				await currentStep?.onSaveProgress()
			}
		},
		subSteps: () => subSteps
	}
}
