import { ProjectWizardState } from './ProjectWizardState'

export const InitialProjectWizardState: ProjectWizardState = {
	loadingNextStep: false,
	currentProject: {
		id: undefined,
		shouldSync: true,
		deleteOnCancel: true,
		name: '',
		projectDescription: '',
		type: 'Type3',
		team: {
			TeamId: '',
			TeamName: '',
			TeamMembers: []
		},
		sources: [],
		source: {
			id: undefined,
			name: '',
			isFile: false,
			isApi: false,
			sourceDescription: '',
			enableAme: false,
			stewardable: false,
			entityType: undefined,
			enable_partial_match: undefined,
			enable_email_verification: false,
			company_match_type: 'Traditional',
			integrationInfo: {
				isTransactional: true,
				refreshRematchConfig: {
					refresh: true,
					rematch: true
				}
			},
			directEnrich: undefined
		},
		fileInfo: undefined,
		mappingInfo: {
			mapping: {
				currentFieldDefinitionsRecord: {
					systemType: '',
					systemObject: '',
					fieldDefinitionsRecordsMap: {}
				},
				importWorkflowSpec: undefined,
				existingFieldDefinitionsMap: {},
				autodetectionResultsMap: {},
				otherTemplateDataMap: undefined
			},
			isDataMappingValidated: false,
			isDataTypesValidated: false,
			areDataGroupsValidated: false,
			haveGroupsIncomplete: false,
			counterGroupsMapping: [],
			requireAdditionalCountry: false,
			continueWithoutAddCountry: true
		},
		matchRules: [],
		matchRulesValidated: false,
		initialMatchRules: [],
		purposeOfUse: { domain: '', recordType: '' },
		enrichingLayout: [],
		layoutId: undefined,
		enrichingApproach: undefined,
		currentTemplate: undefined,
		currentMatchConfigTemplate: undefined,
		generateJson: false,
		enrichmentRules: [],
		isValidIntegrationEnrichmentLayout: undefined,
		tradeUp: false,
		entityTradeUp: 'hq',
		orderReason: ''
	},
	projects: {},
	//check if is used
	selectFileStep: {
		uploadingFile: false,
		percentageUploaded: 0,
		fileAlreadyProcessed: false
	},
	startImport: false,
	enrichmentPreviewSubStep: {
		removedElements: [],
		isUpdatingOrSaving: false,
		initialEnrichingLayoutHashes: '',
		proceedConfirmationRequired: false,
		deprecatedModal: false,
		showConflictFamilyTreeModal: false,
		showOrderReasonModal: false
	},
	enrichmentMappingStep: {
		viewed: false
	},
	overviewStep: {
		viewed: false
	},
	alternateCompanyMatch: {
		alternateCompanyExpanded: false,
		alternateAdrsExpanded: false
	}
}
