export const AUTHENTICATE_SESSION = 'AUTHENTICATE_SESSION'
export const TERMINATE_SESSION = 'TERMINATE_SESSION'
export const ATTACH_TENANT = 'ATTACH_TENANT'
export const ATTACH_FEATURE_FLAGS = 'ATTACH_FEATURE_FLAGS'
export const IDAAS_LOGIN = 'IDAAS_LOGIN'
export const INITIALIZE_SESSION = 'INITIALIZE_SESSION'
export const SET_ENVIRONMENT_PLATFORM_ORGID = 'SET_ENVIRONMENT_PLATFORM_ORGID'
export const SET_C4S_AUTH_STATUS = 'SET_C4S_AUTH_STATUS'
export const SET_INFO_CURRENT_LICENSE = 'SET_INFO_CURRENT_LICENSE'

export * from './AttachFeatureFlagsPayload'
export * from './AttachTenantPayload'
export * from './InitialSessionState'
export * from './SessionActionTypes'
export * from './SessionAuthenticator'
export * from './SessionState'
export * from './SessionStatus'
export * from './SetCurrentLicensePayload'
export * from './SignOutReason'
