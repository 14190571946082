import cx from 'classnames'
import { isEqual } from 'lodash-es'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../local-core-ui'
import { ElementUIFacade } from './ElementUIFacade'
import { isElementChecked } from './helpers/element-tree-helpers'
import styles from './multi-select.module.scss'

interface MultiSelectProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
}

export const MultiSelect = ({ element, selectedElementList, onDropSelectorChange }: MultiSelectProps): ReactElement => {
	const { t } = useTranslation()
	const [selectedOptions, setSelectedOptions] = useState<Array<string>>(
		element.multipleChildrenSelector?.selectedOptions || []
	)

	const toggleElement = (
		elem: ElementUIFacade,
		newSelectedElements: Array<ElementUIFacade>,
		newSelectedOptions: Array<string>
	) => {
		const selectedElementIndex = newSelectedElements.findIndex(
			(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
		)
		const foundSelectedOption = newSelectedOptions.find((value) => elem.elementId.startsWith(value))
		if (foundSelectedOption && selectedElementIndex < 0) {
			newSelectedElements.push(elem)
		} else if (!foundSelectedOption && selectedElementIndex >= 0) {
			newSelectedElements.splice(selectedElementIndex, 1)
		}
	}

	const getChildrenToSelect = (newSelectedOptions: Array<string>) => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		element.childs?.forEach((blockChild) => {
			if (
				blockChild.isSelectedByDefault ||
				isElementChecked(selectedElementList, blockChild.elementId, undefined, true, blockChild)
			) {
				blockChild.childs?.forEach((elem) => {
					toggleElement(elem, newSelectedElements, newSelectedOptions)
				})
			}
		})
		return newSelectedElements
	}

	useEffect(() => {
		if (
			element.multipleChildrenSelector &&
			!isEqual(selectedOptions, element.multipleChildrenSelector.selectedOptions)
		) {
			setSelectedOptions(element.multipleChildrenSelector.selectedOptions)
		}
		/**
		 * selectedOptions is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedElementList])

	const renderDropdowns = () => {
		const dropdowns: Array<ReactNode> = []
		for (let index = 0; index <= selectedOptions.length; index++) {
			const availableOptions =
				element.multipleChildrenSelector?.dropdownOptions.filter(
					(option) => selectedOptions[index] === option.value || !selectedOptions.includes(option.value)
				) || []
			if (availableOptions.length !== 0) {
				dropdowns.push(
					<div key={`multiple-dropdown-${index}`}>
						<div className={styles.childrenSelectorLabel}>{t(`${element.elementId}.dropdown.label`)}</div>
						<Dropdown
							id={`children-selector-${element.elementId}-${index}`}
							label=""
							onChangeFunction={(selection) => {
								if (element.multipleChildrenSelector) {
									const newSelectedOptions = [...element.multipleChildrenSelector.selectedOptions]
									if (selection && selectedOptions[index] && selection !== selectedOptions[index]) {
										newSelectedOptions.splice(index, 1)
										newSelectedOptions.push(selection)
									} else if (selection && !selectedOptions[index]) {
										newSelectedOptions.push(selection)
									} else if (!selection && selectedOptions[index]) {
										newSelectedOptions.splice(index, 1)
									}

									if (onDropSelectorChange) {
										onDropSelectorChange(getChildrenToSelect(newSelectedOptions))
									}
									setSelectedOptions(newSelectedOptions)
									element.multipleChildrenSelector.selectedOptions = newSelectedOptions
								}
							}}
							hint={t(`${element.elementId}.dropdown.hint`)}
							options={availableOptions}
							size="large"
							testId={`t-children-selector-${element.elementId}-${index}`}
							canUnselect={true}
							unselectText={t('none') as string}
							selected={selectedOptions[index]}
						/>
					</div>
				)
			}
		}
		return dropdowns
	}

	return (
		<div>
			{renderDropdowns()}
			{element.multipleChildrenSelector?.selectedOptions.length === 0 ? undefined : (
				<div className={cx(styles.fieldsToReturnContainer, styles.childrenSelectorLeftMargin)}>
					{t('fields.to.return', { element: element.displayName })}
				</div>
			)}
		</div>
	)
}
