import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { SignOutReason } from '../store'
import { ApiErrorState, setApiErrorAction } from '../store/apiError'

export function getApiClient(
	signOutSession: ((reason?: SignOutReason) => void) | null = null,
	dispatch?: Dispatch,
	customErrorHandler?: (error: AxiosError) => Promise<never>
): AxiosInstance {
	const apiClient: AxiosInstance = axios.create()

	//initialize get if not there
	if (!apiClient.defaults.headers.get) {
		apiClient.defaults.headers.get = {}
	}

	//disable IE ajax request caching
	apiClient.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT'
	apiClient.defaults.headers.get['Cache-Control'] = 'no-cache'
	apiClient.defaults.headers.get['Pragma'] = 'no-cache'

	const errorHandler = (error: AxiosError) => {
		// Handle errors
		console.error(error)

		// if status is 401, clear token document
		const resp = error.response
		const isInDevSite =
			window.location.hostname.includes('localhost') || window.location.hostname.includes('connect-dev')
		if (resp && resp.status === 401) {
			console.warn('SessionInfo expired!')
			if (signOutSession) signOutSession(SignOutReason.backend)
		} else if (isInDevSite && dispatch) {
			const responseData = resp?.data
			const previousURL = window.location.href
			const requestURL = resp?.config?.url || error.config?.url
			const URLIdaasMatch = requestURL?.match(/idaas/g)
			let additionalInfo = ''
			const errorMessagesMap = new Map<string, string>([
				['LEDP_61008', 'error.message.modal.error.orgID.message']
			])
			const errorMessage = errorMessagesMap.get(responseData?.errorCode)
			if (responseData?.errorCode === 'LEDP_61008') {
				additionalInfo = responseData?.errorCode
			}
			const errorCode = responseData?.errorCode
			const message = errorMessage
			const requestPayload = URLIdaasMatch && URLIdaasMatch.length > 0 ? '' : JSON.stringify(resp?.config?.data)
			const response = responseData?.title || responseData?.errorMsg || JSON.stringify(responseData) || error
			const errorData: ApiErrorState = {
				previousURL,
				requestURL,
				requestPayload,
				response,
				message,
				additionalInfo,
				errorCode
			}

			dispatch(setApiErrorAction(errorData))
		} else {
			alert(JSON.stringify(resp?.data) || error)
		}

		return Promise.reject({
			...error
		})
	}

	const successHandler = (response: AxiosResponse) => {
		return response
	}

	apiClient.interceptors.response.use(
		(response) => successHandler(response),
		(error) => (customErrorHandler ? customErrorHandler(error) : errorHandler(error))
	)

	return apiClient
}
