import { DNBSnackbar } from '@dnb-uux-design-system/react'
import { isEmpty, orderBy } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from '../../components/connections-filter-bar/connections-filter-bar'
import { ConnectionsManager } from '../../components/connections-manager/connections-manager'
import {
	getConnectionSnackbarText,
	getConnectionSnackbarVariant
} from '../../components/data-block-searcher/helpers/connectionSnackbarContent'
import { EditConnection, TabIndexValue } from '../../components/edit-connection/edit-connection'
import { useApi } from '../../hooks'
import { useOrgId } from '../../hooks/useOrgId'
import { LoadingState } from '../../local-core-ui'
import { ConnectionStatus, CreateQueryStatus } from '../../queries/api/getC4SActiveConnection'
import { ConnectionDetail, getConnection } from '../../queries/api/getConnection'
import { Connection } from '../../queries/api/getConnections'
import { useConnections } from '../../queries/useConnections'
import { useConnectionsStatus } from '../../queries/useConnectionsStatus'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import {
	clearAuthOrigin,
	clearCreateQueryStatus,
	clearSelectedConnectionName,
	clearTypeConnection,
	setCurrentConnectionStatus,
	setSelectedConnectionName
} from '../../store/connection/connectionSlice'
import styles from './dashboard-connections.module.scss'

export function DashboardConnections(): ReactElement {
	const { t } = useTranslation()
	const [showEditConnection, setShowEditConnection] = useState(false)
	const [editTab, setEditTab] = useState<TabIndexValue>()
	const [isStatusLoading, setIsStatusLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const apiClient = useApi()
	const [connectionDetail, setConnectionDetail] = useState<ConnectionDetail>()
	const dispatch = useAppDispatch()
	const [displayConnectionSnackbar, setDisplayConnectionSnackbar] = useState(false)
	const orgId = useOrgId()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const currentTenantArray = session.tenants?.find((tenant) => tenant.Identifier === session.tenant)

	const connectionsQuery = useConnections(true, connection.createQueryStatus)
	const [connectionNames, setConnectionNames] = useState<string[]>([])
	const connectionsStatusQuery = useConnectionsStatus(connectionNames)
	const [connectionsList, setConnectionsList] = useState<Connection[]>([])
	const [viewedConnectionStatus, setViewedConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.Unknown)
	const [connectionCreated, setConnectionCreated] = useState(false)
	const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus | null>(null)

	const onEditConnection = (connectionDetail: ConnectionDetail) => {
		dispatch(setSelectedConnectionName(connectionDetail.name))
		setConnectionDetail(connectionDetail)
		setShowEditConnection(true)
	}

	const onCancelConnection = () => {
		dispatch(clearSelectedConnectionName())
		setShowEditConnection(false)
		setDisplayConnectionSnackbar(false)
	}
	const reload = () => {
		connectionsQuery.refetch()
		setIsStatusLoading(true)
		setIsLoading(true)
	}

	const updateConnectionList = (updatedConnectionData: ConnectionDetail) => {
		setConnectionsList((prevConnectionsList) =>
			prevConnectionsList.map((connection) =>
				connection.name === updatedConnectionData.name
					? {
							...connection,
							displayName: updatedConnectionData.displayName || '',
							updated: updatedConnectionData.updated || connection.updated
					  }
					: connection
			)
		)
	}

	function applyStatusToConnections() {
		if (connectionsStatusQuery.isFetched && connectionsStatusQuery.data && connectionsList) {
			let activeOrgIdHasConnection = false
			setConnectionsList((connections) => {
				return connections.map((connection) => {
					const newStatus =
						connectionsStatusQuery.data.find((con) => con.name === connection.name)?.status ||
						ConnectionStatus.Unknown

					//This should display update the connection banner for C4S depending on the status
					if (connection.displayName === orgId) {
						activeOrgIdHasConnection = true
						dispatch(setCurrentConnectionStatus(newStatus))
					}

					return { ...connection, status: newStatus }
				})
			})
			if (orgId && !activeOrgIdHasConnection) dispatch(setCurrentConnectionStatus(ConnectionStatus.Failed))
			setIsStatusLoading(false)
		} else {
			setIsStatusLoading(true)
		}
	}

	useEffect(() => {
		if (connectionsQuery.isFetched && connectionsQuery.data) {
			//Ignore the status the BE returns in this API call, it's a lie
			setConnectionsList(
				connectionsQuery.data.map((connection) => {
					const tempStatus = ConnectionStatus.Unknown

					return { ...connection, status: tempStatus }
				})
			)
			setConnectionNames(connectionsQuery.data.map((connection) => connection.name))
			setIsLoading(false)
		}
		/**
		 * We only want to run this effect when the data, length from query changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionsQuery.data, connectionsQuery.data?.length, connectionsQuery.isFetched])

	useEffect(() => {
		applyStatusToConnections()
		if (connectionCreated && connectionsStatusQuery.data) {
			setDisplayConnectionSnackbar(true)
			setConnectionCreated(false)
		}
		/**
		 * We want to run this effect to update the status of each connection.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionsStatusQuery.data, connectionsStatusQuery.isFetched])

	useEffect(() => {
		if (!isEmpty(connectionsList)) {
			const orderedConnections = orderBy(connectionsList, (connection: Connection) => connection.created, 'desc')
			setConnectionStatus(
				!isEmpty(connectionDetail) ? connectionDetail.status ?? null : orderedConnections[0].status
			)
		}
	}, [connectionDetail, connectionsList])

	useEffect(() => {
		if (connection.selectedConnectionName) {
			setShowEditConnection(true)
			setIsStatusLoading(true)
			getConnection(apiClient, connection.selectedConnectionName)
				.then((response) => {
					setConnectionDetail(response)
				})
				.catch(() => {
					dispatch(clearSelectedConnectionName())
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (
			connection.createQueryStatus === CreateQueryStatus.Success ||
			connection.createQueryStatus === CreateQueryStatus.Error
		) {
			if (connection.createQueryStatus === CreateQueryStatus.Success) {
				setConnectionCreated(true)
			}
			dispatch(clearCreateQueryStatus())
			dispatch(clearAuthOrigin())
			reload()
		} else if (connection.createQueryStatus === CreateQueryStatus.Loading) {
			setIsStatusLoading(true)
			setIsLoading(true)
		}
		/**
		 * We want run this effect when create C4S connection query response successfully or with error
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection.createQueryStatus])

	useEffect(() => {
		if (
			connectionsStatusQuery.isFetched &&
			connectionsStatusQuery.data &&
			connectionDetail &&
			connectionDetail.name
		) {
			setViewedConnectionStatus(
				connectionsStatusQuery.data.find((con) => con.name === connectionDetail.name)?.status ||
					ConnectionStatus.Unknown
			)
		}
		/**
		 * We want this to update the status of the active connection that is being edited once it is known
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionDetail?.name, connectionsStatusQuery.isFetched])

	useEffect(() => {
		const url = new URL(window.location.href)
		const searchParams = new URLSearchParams(url.search)
		if (searchParams.get('view') === View.detail && searchParams.get('tab')) {
			setShowEditConnection(true)
			setEditTab(TabIndexValue.edit)
		}
		/**
		 * We only want to run this effect to redirect to edit connection view
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<DNBSnackbar
				transitionDuration={1000}
				variant={getConnectionSnackbarVariant(connectionDetail ? viewedConnectionStatus : connectionStatus)}
				isOpen={displayConnectionSnackbar}
				handleClose={() => {
					dispatch(clearTypeConnection())
					setDisplayConnectionSnackbar(false)
					setConnectionStatus(null)
				}}
				snackbarText={getConnectionSnackbarText({
					connectionType: connection.typeConnection,
					connectionStatus: connectionDetail ? viewedConnectionStatus : connectionStatus,
					tenantDisplayName: currentTenantArray?.DisplayName,
					t
				})}
				duration={10000}
				hasCloseIcon={false}
			/>
			<div className={styles.viewContainer}>
				<div className={styles.dashboardConnectionsContainer}>
					{showEditConnection ? (
						<div>
							{connectionDetail ? (
								<EditConnection
									onCancelEdit={() => onCancelConnection()}
									testId={'test id'}
									connectionData={connectionDetail}
									isConnectionStatusLoading={isStatusLoading}
									currentConnectionStatus={viewedConnectionStatus}
									onDelete={() => reload()}
									onEditedSuccessfully={(updatedConnectionDetail) =>
										updateConnectionList(updatedConnectionDetail)
									}
									defaultTab={editTab}
								/>
							) : (
								<LoadingState />
							)}
						</div>
					) : (
						<div>
							<h1 data-testid="dashboard-title-connections" className={styles.dashboardOverviewHeader}>
								{t('dashboard.connections.title')}
							</h1>
							<h1
								data-testid="dashboard-description-connections"
								className={styles.dashboardOverviewDescription}
							>
								{/*	description goes here*/}
							</h1>
							<ConnectionsManager
								onEditConnection={onEditConnection}
								onReload={reload}
								isLoading={isLoading}
								isStatusLoading={isStatusLoading}
								connections={connectionsList}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
