import { AxiosInstance, AxiosResponse } from 'axios'

export interface ConnectionSource {
	name: string
	schemaTYpe: string
	projectName: string
	created: number
	object: string
	createdBy: string
	externalSourceId: string
	archived?: boolean
}

export const getConnectionSources = async (
	apiClient: AxiosInstance,
	connectionName: string,
	inactiveSourcesDisplay?: boolean
): Promise<ConnectionSource[]> => {
	const url =
		inactiveSourcesDisplay !== undefined
			? `/pls/integration/connection/${connectionName}/sources?all=${inactiveSourcesDisplay}`
			: `/pls/integration/connection/${connectionName}/sources`

	return apiClient.get(url).then((response: AxiosResponse<ConnectionSource[]>) => response.data)
}
