import { DNBCheckbox } from '@dnb-uux-design-system/react'
import cx from 'classnames'
import { t } from 'i18next'
import { ChangeEvent, ReactElement } from 'react'
import { AccordionPanel } from '../../local-core-ui'
import { DataBlockElementSelector } from '../data-block-element-selector/data-block-element-selector'
import mandatoryElements from './arrayMandatoryElesAndAlerts.json'
import { CheckboxSubelements } from './checkbox-subelements'
import styles from './children-tree.module.scss'
import { ElementUIFacade } from './ElementUIFacade'
import { countSelectedElementChildren, isElementChecked, isElementDisabled } from './helpers/element-tree-helpers'
import { Select } from './select'

interface NestedSelectorChildrenTreeProps {
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	mandatoryIdArray: Array<string>
	counterUpdated?: (selectedInBlock: number) => void
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const getChildName = (blockChild: ElementUIFacade) => {
	let label = blockChild.displayName
	if (blockChild.dataTable) {
		label += `(${blockChild.dataTable?.length} ${t('text.columns')})`
	}
	return label
}

export const NestedSelectorChildrenTree = ({
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	mandatoryIdArray,
	counterUpdated,
	onDropSelectorChange,
	setMandatoryIdArray,
	errorBannerVisibility
}: NestedSelectorChildrenTreeProps): ReactElement => {
	if (element.showChildrenBlocks) {
		let selectedChildrenCount = 0
		const blockChildren = element.childs || []
		if (element.containsChildrenSelector || element.multipleChildrenSelector) {
			selectedChildrenCount = countSelectedElementChildren(blockChildren, selectedElementList, defaultElements)
		}

		const checkMandatorySubelements = blockChildren.some((child) =>
			mandatoryElements.mandatoryIds.includes(child.elementId)
		)

		if (counterUpdated) counterUpdated(selectedChildrenCount)

		return (
			<div
				className={cx({
					[styles.childrenSelectorLeftMargin]:
						element.containsChildrenSelector || element.multipleChildrenSelector
				})}
			>
				{element.maximumElementsInOutput?.current === 0
					? undefined
					: checkMandatorySubelements && (
							<div
								data-testid="subelements-mandatory-title"
								className={cx(styles.wantToKnowQuestionContainer)}
							>
								{t('subelements.mandatory.title')}
							</div>
					  )}
				{blockChildren.map(
					(blockChild, indexChild) =>
						mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) > -1 && (
							<CheckboxSubelements
								key={blockChild.elementId + 'parentsubpanel-' + index}
								blockChild={blockChild}
								indexChild={indexChild}
								index={index}
								element={element}
								testId={testId}
								selectedElementList={selectedElementList}
								onChange={onChange}
								defaultElements={defaultElements}
								showBlockInformation={showBlockInformation}
								mandatoryIdArray={mandatoryIdArray}
							/>
						)
				)}
				{blockChildren.map((blockChild, indexChild) => {
					const { outputDropdownOptions, hideElementDetail } = blockChild
					{
						if (mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) === -1) {
							return (
								<>
									<div
										className={`${
											indexChild !== 0 && !blockChild.childs
												? styles.containerItemSmall
												: styles.containerItem
										} ${
											indexChild === blockChildren.length - 1 && !blockChild.childs
												? styles.lastChildContainerItem
												: ''
										}`}
										key={blockChild.elementId + 'subpanel-' + index}
										data-testid="container-item-subelements"
									>
										{outputDropdownOptions && outputDropdownOptions.length > 0 ? (
											<Select
												element={blockChild}
												selectedElementList={selectedElementList}
												onDropSelectorChange={onDropSelectorChange}
												mandatoryIdArray={mandatoryIdArray}
												setMandatoryIdArray={setMandatoryIdArray}
												errorBannerVisibility={errorBannerVisibility}
											/>
										) : (
											<DNBCheckbox
												size="small"
												id={t(blockChild.elementId) as string}
												label={getChildName(blockChild)}
												checked={
													blockChild.isSelectedByDefault ||
													(blockChild.childs
														? isElementChecked(
																selectedElementList,
																blockChild.elementId,
																defaultElements,
																true,
																blockChild
														  )
														: isElementChecked(
																selectedElementList,
																blockChild.elementId,
																defaultElements
														  ))
												}
												disabled={
													blockChild.isSelectedByDefault ||
													isElementDisabled(blockChild.elementId)
												}
												data-testid={testId + '-dbes-cb-child' + index}
												onChange={(e) => onChange(e, blockChild)}
												error={
													mandatoryIdArray && mandatoryIdArray.length > 0
														? mandatoryIdArray.find(
																(ele) => ele === blockChild?.parent?.displayName
														  )
															? 'Selection missing'
															: ''
														: ''
												}
											/>
										)}
										{hideElementDetail && outputDropdownOptions === undefined ? undefined : (
											<DataBlockElementSelector
												element={blockChild}
												dataBlockName={blockChild.blockId || ''}
												level={
													!blockChild.childs || !blockChild.showChildrenBlocks
														? blockChild.level
														: undefined
												}
												dataTable={blockChild.dataTable}
												showBlockInformation={showBlockInformation && !!blockChild.dataTable}
											/>
										)}
										{blockChild.childs && blockChild.showChildrenBlocks ? (
											<AccordionPanel
												id={testId + '-accordion-panel' + index}
												isOpen={true}
												existControlElement={true}
											>
												{blockChild.childs.map(
													(blockChild, indexChild) =>
														mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) ===
															-1 && (
															<CheckboxSubelements
																key={blockChild.elementId + 'parentsubpanel-' + index}
																blockChild={blockChild}
																indexChild={indexChild}
																index={index}
																element={element}
																testId={testId}
																selectedElementList={selectedElementList}
																onChange={(e, option) => {
																	if (
																		option.parent &&
																		option.parent.parent &&
																		option.parent?.parent?.maximumElementsInOutput
																			?.current === 0
																	) {
																		option.parent.parent.maximumElementsInOutput.current = 1
																	}

																	onChange(e, option)
																}}
																defaultElements={defaultElements}
																showBlockInformation={showBlockInformation}
																mandatoryIdArray={[]}
															/>
														)
												)}
											</AccordionPanel>
										) : (
											<></>
										)}
									</div>
								</>
							)
						}
					}
				})}
			</div>
		)
	} else return <></>
}
