import { MutableRefObject } from 'react'
// import { TFunction } from 'react-i18next'
import { TFunction } from 'i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import subjectCommentsPrefixes from '../subject-comments-element-prefixes.json'
import { TreeElementObject } from './constructArrayFlattenElementsBuildTree'

const buildLevel1Tree = (
	flattenedElementsList: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	level1SelectorValueRef: MutableRefObject<number>,
	level2SelectorValueRef: MutableRefObject<number>,
	elementObj: TreeElementObject
) => {
	const prefixes = subjectCommentsPrefixes.level1Prefixes

	const dropdownOptionsList = [...Array(elementObj.optionsLength).keys()]
	const dropdownOptions = dropdownOptionsList.map((i) => {
		return {
			value: (i + 1).toString(),
			label: (i + 1).toString()
		}
	})

	const groupElements = prefixes.reduce((acc: Array<ElementUIFacade>, prefix: string) => {
		const elementList = flattenedElementsList.filter((element) => element.elementId.startsWith(prefix))
		const isCommentariesElement = prefix === subjectCommentsPrefixes.level1Prefixes[1]
		let groupParent: ElementUIFacade | null = null
		const firstElement = elementList[0]

		if (elementList && elementList.length > 0) {
			if (isCommentariesElement) {
				groupParent = {
					parent: root,
					elementId: prefix,
					displayName: t(`${prefix}.element`),
					description: firstElement.description,
					level: '2',
					dataType: t('ARRAY') as string,
					example: firstElement.example,
					showChildrenBlocks: true,
					containsChildrenSelector: false,
					considerChildrenForCount: true,
					maximumElementsInOutput: level2SelectorValueRef,
					outputDropdownOptions: dropdownOptions
				}
			} else {
				groupParent = {
					parent: root,
					elementId: prefix,
					displayName: t(`${prefix}.element`),
					description: firstElement.description,
					level: firstElement.level,
					dataType: firstElement.dataType,
					example: firstElement.example,
					showChildrenBlocks: false,
					considerChildrenForCount: true,
					isSelectedByDefault: true
				}
			}

			if (isCommentariesElement) {
				groupParent.childs = buildLevel2Tree({
					root: groupParent,
					t,
					selectorValueRef: level2SelectorValueRef,
					elementObj: {
						id: 'commentariesId',
						level: '2',
						optionsLength: 5,
						showChildrenBlocks: false,
						containsChildrenSelector: false,
						considerChildrenForCount: true,
						isRequiredByDefault: []
					},
					parentElementList: elementList
				})
			} else {
				groupParent.childs = elementList
			}
			acc.push(groupParent)
		}
		return acc
	}, [])

	return groupElements
}

const buildLevel2Tree = ({
	root,
	t,
	selectorValueRef,
	elementObj,
	parentElementList
}: {
	root: ElementUIFacade
	t: TFunction<'translation'>
	selectorValueRef: MutableRefObject<number>
	elementObj: TreeElementObject
	parentElementList: Array<ElementUIFacade>
}) => {
	const prefixes: string[] = subjectCommentsPrefixes.level2Prefixes
	const elementList = [...parentElementList]
	const { showChildrenBlocks, containsChildrenSelector, considerChildrenForCount } = elementObj

	const test = Object()
	prefixes.forEach((prefix) => {
		test[`${prefix}`] = elementList.filter((element) => element.elementId.includes(prefix))
	})

	const groupElements = prefixes.reduce((acc: Array<ElementUIFacade>, prefix: string) => {
		const groupParent: ElementUIFacade = {
			parent: root,
			elementId: prefix,
			displayName: t(`${subjectCommentsPrefixes.level1Prefixes[1]}${prefix}.element`),
			description: elementList[0].description,
			level: elementList[0].level,
			dataType: elementList[0].dataType,
			example: elementList[0].example,
			showChildrenBlocks: showChildrenBlocks,
			containsChildrenSelector: containsChildrenSelector,
			considerChildrenForCount: considerChildrenForCount,
			maximumElementsInOutput: selectorValueRef
		}

		groupParent.childs = test[prefix]
		acc.push(groupParent)
		return acc
	}, [])

	return groupElements
}

const buildSubjectCommentsTree = (
	flattenedElementsList: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	level1SelectorValueRef: MutableRefObject<number>,
	level2SelectorValueRef: MutableRefObject<number>,
	elementObj: TreeElementObject
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	const { id, level, optionsLength, isRequiredByDefault } = elementObj

	const dropdownOptionsList = [...Array(optionsLength).keys()]
	const dropdownOptions = dropdownOptionsList.map((i) => {
		return {
			value: (i + 1).toString(),
			label: (i + 1).toString()
		}
	})

	if (flattenedElementsList.length) {
		const root: ElementUIFacade = {
			blockId: t(`${flattenedElementsList[0].blockId}`) as string,
			elementId: id,
			displayName: t(`${id}.element`) as string,
			description: t(`${id}.description`) as string,
			level,
			dataType: t('ARRAY') as string,
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			hideElementDetail: false,
			maximumElementsInOutput: level1SelectorValueRef,
			outputDropdownOptions: dropdownOptions,
			dropdownDisabled: false,
			isNestedSelector: true
		}

		tree.push(root)

		root.childs = buildLevel1Tree(flattenedElementsList, root, t, level1SelectorValueRef, level2SelectorValueRef, {
			id: 'subjectCommentsId',
			level: '2',
			optionsLength: 5,
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			isRequiredByDefault: isRequiredByDefault
		})
	}

	return tree
}

export { buildSubjectCommentsTree }
