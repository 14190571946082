import { toNumber } from 'lodash-es'
import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import tradeUpList from '../../../project/steps/enriching/select-your-data/tradeup-elements.json'
import { Element, FlattenedArrayConfiguration, Pattern } from '../../../types'
import mandatoryElements from '../arrayMandatoryElesAndAlerts.json'
import disabledArrayElements from '../disabledArrayElements.json'
import { ElementUIFacade } from '../ElementUIFacade'
import industriesCodeList from '../industries-codes.json'
import subjectCommentsPrefixes from '../subject-comments-element-prefixes.json'

export type OutputElementsSelection = 'default' | 'max'

export const searchDisplayNameInElementTree = (
	element: ElementUIFacade,
	filterValue: string,
	selectedLevels: Array<string>
): boolean => {
	const elementDisplayName = element.displayName.toLowerCase().trim()
	let foundElement =
		elementDisplayName.includes(filterValue.toLowerCase().trim()) &&
		!!element.level &&
		selectedLevels.includes(element.level)
	if (
		!foundElement &&
		element.childs &&
		element.showChildrenBlocks &&
		(element.maximumElementsInOutput === undefined || element.maximumElementsInOutput.current !== 0) &&
		(element.multipleChildrenSelector === undefined ||
			element.multipleChildrenSelector.selectedOptions.length !== 0)
	) {
		foundElement = element.childs.some((child) =>
			searchDisplayNameInElementTree(child, filterValue, selectedLevels)
		)
	}
	return foundElement
}

export const generateAdditionalTextString = (
	child: Array<ElementUIFacade>,
	selectedElementList: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	defaultElements?: Array<ElementUIFacade>,
	considerChildrenForCount?: boolean
): string => {
	const selectedChildren = countSelectedElementChildrenForDisplay(child, selectedElementList, defaultElements)
	let childrenNumber
	if (considerChildrenForCount) {
		childrenNumber = countElementChildren(child)
	} else {
		childrenNumber = child.length
	}
	return t('data.block.tile.selected', { selected: selectedChildren + '/' + childrenNumber })
}

const countSelectedElementChildrenForDisplay = (
	elements: Array<ElementUIFacade>,
	selectedElementList: Array<ElementUIFacade>,
	defaultElements?: Array<ElementUIFacade>
) => {
	let sum = 0
	elements.forEach((element) => {
		if (element.childs && element.considerChildrenForCount) {
			sum += countSelectedElementChildren(element.childs, selectedElementList, defaultElements)
		} else if (
			element.childs &&
			isElementChecked(selectedElementList, element.elementId, defaultElements, true, element)
		) {
			sum++
		} else if (isElementChecked(selectedElementList, element.elementId, defaultElements)) {
			sum++
		}
	})
	return sum
}

export const countSelectedElementChildren = (
	elements: Array<ElementUIFacade>,
	selectedElementList: Array<ElementUIFacade>,
	defaultElements?: Array<ElementUIFacade>
): number => {
	let sum = 0
	elements.forEach((element) => {
		if (element.childs) {
			sum += countSelectedElementChildren(element.childs, selectedElementList, defaultElements)
		} else if (isElementChecked(selectedElementList, element.elementId, defaultElements)) {
			sum++
		}
	})
	return sum
}

const countElementChildren = (elements: Array<ElementUIFacade>): number => {
	let sum = 0
	elements.forEach((element) =>
		element.childs && element.considerChildrenForCount ? (sum += countElementChildren(element.childs)) : sum++
	)
	return sum
}

export const isElementChecked = (
	selectedElementList: Array<ElementUIFacade>,
	checkboxElementId: string,
	defaultElements?: Array<ElementUIFacade>,
	isParent?: boolean,
	elementSelected?: ElementUIFacade
) => {
	if (defaultElements && isElementCheckedByDefault(checkboxElementId, defaultElements)) {
		return true
	} else {
		if (isParent && elementSelected?.childs) {
			return areChildrenSelected(selectedElementList, elementSelected)
		} else {
			return !!selectedElementList.find(
				(selectedElement) =>
					selectedElement.elementId === checkboxElementId ||
					(industriesCodeList?.includes(checkboxElementId) &&
						selectedElement.elementId.includes(checkboxElementId))
			)
		}
	}
}

const isElementCheckedByDefault = (
	checkboxElementId: string,
	defaultElements?: Array<ElementUIFacade>,
	enableEmailVerification?: boolean,
	EVIDS?: Array<string>
) => {
	const isEVIDMatches = EVIDS?.find((element: string) => element === checkboxElementId)
	if (enableEmailVerification === false && isEVIDMatches) {
		return true
	} else return !!defaultElements?.find((element) => element.elementId === checkboxElementId)
}

const isElementTradeUp = (checkboxElementId: string, enableEmailVerification?: boolean, EVIDS?: Array<string>) => {
	const isEVIDMatches = EVIDS?.find((element: string) => element === checkboxElementId)
	if (enableEmailVerification === false && isEVIDMatches) {
		return true
	} else return !!tradeUpList?.find((elem) => elem.elements.find((el) => el.elementId === checkboxElementId))
}

const isElementSelected = (selectedElements: Array<ElementUIFacade>, element: ElementUIFacade) => {
	return !!selectedElements.find((selectedElement) => selectedElement.elementId === element.elementId)
}

export const isElementDisabled = (
	checkboxElementId: string,
	defaultElements?: Array<ElementUIFacade>,
	activeTradeUp?: boolean,
	enableEmailVerification?: boolean,
	EVIDS?: Array<string>,
	disabledArrayElements?: Array<string>
) => {
	if (disabledArrayElements?.includes(checkboxElementId)) {
		return true
	} else if (defaultElements) {
		return activeTradeUp
			? isElementTradeUp(checkboxElementId) ||
					isElementCheckedByDefault(checkboxElementId, defaultElements, enableEmailVerification, EVIDS)
			: isElementCheckedByDefault(checkboxElementId, defaultElements, enableEmailVerification, EVIDS)
	} else return false
}

export const allFilteredElementsAreSelected = (
	filteredElements: Array<ElementUIFacade>,
	selectedElementList: Array<ElementUIFacade>,
	enableEmailVerification?: boolean,
	EVIDS?: Array<string>,
	tradeToggle?: boolean
) => {
	if (filteredElements.length > 0) {
		let filteredElementsUpd = filteredElements
		const filterIDS = filteredElements?.map(({ elementId }) => elementId)
		const checkDifferences = filterIDS?.every((element) => EVIDS?.includes(element))
		if (checkDifferences && enableEmailVerification === false) return false
		else {
			if (!tradeToggle) {
				filteredElementsUpd = filteredElements?.filter((element: ElementUIFacade) => {
					return !isElementTradeUp(element.elementId)
				})
			}
			if (enableEmailVerification === false) {
				filteredElementsUpd = filteredElementsUpd?.filter((element: ElementUIFacade) => {
					return !EVIDS?.includes(element.elementId)
				})
			}
			return filteredElementsUpd.every((cbElement) => {
				if (cbElement.childs) {
					return areChildrenSelected(selectedElementList, cbElement)
				} else {
					return !!selectedElementList.find(
						(selectedElement) => selectedElement.elementId === cbElement.elementId
					)
				}
			})
		}
	} else {
		return false
	}
}

const evaluateChildrenBySelector = ({
	newSelectedElements,
	option,
	selectAll,
	outputElementsSelection
}: {
	newSelectedElements: Array<ElementUIFacade>
	option: ElementUIFacade
	selectAll?: boolean
	outputElementsSelection?: OutputElementsSelection
}) => {
	if (option.childs && option.maximumElementsInOutput) {
		if (selectAll) {
			if (outputElementsSelection === 'default') {
				option.maximumElementsInOutput.current = 1
			} else if (outputElementsSelection === 'max') {
				option.maximumElementsInOutput.current = option.parent?.outputDropdownOptions?.length ?? 1
			}
		} else if (option.maximumElementsInOutput.current === 0) {
			option.maximumElementsInOutput.current = 1
		}
		if (option.parent?.parent?.isNestedSelector) {
			const maxElementsInRootOutput = option.parent.parent.maximumElementsInOutput?.current ?? 0
			const maxElementsInParent = option.parent.maximumElementsInOutput?.current ?? 0
			for (let i = 1; i <= maxElementsInRootOutput; i++) {
				for (let j = 1; j <= maxElementsInParent; j++) {
					option.childs.forEach((deepChild) => {
						const elementIdStr = `${option.parent?.elementId}${i}_${option.elementId}${j}`
						if (
							deepChild.elementId.includes(elementIdStr) &&
							!isElementSelected(newSelectedElements, deepChild)
						) {
							newSelectedElements.push(deepChild)
						}
					})
				}
			}
		} else {
			option.childs.forEach((childToAdd, index) => {
				const selectedElement = newSelectedElements.find(
					(currentSelectedElement) => currentSelectedElement.elementId === childToAdd.elementId
				)
				const selectedElementIndex = newSelectedElements.findIndex(
					(currentSelectedElement) => currentSelectedElement.elementId === childToAdd.elementId
				)
				if (option.maximumElementsInOutput?.current && option.maximumElementsInOutput.current - 1 >= index) {
					if (!selectedElement) {
						newSelectedElements.push(childToAdd)
					}
				} else {
					if (selectedElement) newSelectedElements.splice(selectedElementIndex, 1)
				}
			})
		}
	}
}

const evaluateElementByRangesAndFiguresSelect = (
	newSelectedElements: Array<ElementUIFacade>,
	option: ElementUIFacade,
	selectAll?: boolean
) => {
	if (option.childs && option.maximumElementsInOutput) {
		if (option.maximumElementsInOutput.current === 0 || selectAll || !option?.parent) {
			option.maximumElementsInOutput.current = option?.checkboxGroupOptions?.length || 0
			if (
				option.checkboxGroupOptions &&
				option.maximumElementsInOutput.current === option.checkboxGroupOptions?.length
			) {
				option.checkboxGroupOptions = option.checkboxGroupOptions.map((checkboxOption) => {
					return { ...checkboxOption, isChecked: true }
				})
			}
			option.childs?.forEach((elem) => {
				elem.childs?.forEach((childToAdd) => {
					const selectedElement = newSelectedElements.find(
						(currentSelectedElement) => currentSelectedElement.elementId === childToAdd.elementId
					)
					if (!selectedElement) {
						newSelectedElements.push(childToAdd)
					}
				})
			})
		}
		// New Implementation of flattened arrays configuration
		else if (option.parent?.pattern === Pattern.CHECKBOX_GROUP_PATTERN) {
			const elementsToAdd =
				option.parent.checkboxGroupOptions?.reduce((elementList: Array<ElementUIFacade>, checkboxOption) => {
					if (checkboxOption.isChecked) {
						elementList.push(
							...(option.childs?.filter((child) => checkboxOption.elements?.includes(child.elementId)) ||
								[])
						)
					}
					return elementList
				}, []) || []
			newSelectedElements.push(...elementsToAdd)
		}
		// New Implementation of flattened arrays configuration
		// TODO remove else statement with suffix and prefix after all checkbox patterns have been moved to new implementation
		else {
			option.childs.forEach((childToAdd) => {
				const elementSuffix = getElementIdSuffix(childToAdd.elementId)
				const elementPrefix = getElementIdPrefix(childToAdd.elementId)
				if (elementSuffix !== '' && elementPrefix !== '') {
					const selectedElement = newSelectedElements.findIndex(
						(currentSelectedElement) =>
							currentSelectedElement.elementId.includes(elementSuffix) &&
							currentSelectedElement.elementId.includes(elementPrefix)
					)
					const matchingElement = newSelectedElements.find(
						(currentSelectedElement) => currentSelectedElement.elementId === childToAdd.elementId
					)
					if (selectedElement >= 0 && matchingElement) {
						newSelectedElements.splice(selectedElement, 1)
					} else if (selectedElement > -1) {
						newSelectedElements.push(childToAdd)
					}
				}
			})
		}
	}
}

const evaluateElementByClassif = ({
	newSelectedElements,
	option,
	selectAll,
	outputElementsSelection
}: {
	newSelectedElements: Array<ElementUIFacade>
	option: ElementUIFacade
	selectAll?: boolean
	outputElementsSelection?: OutputElementsSelection
}) => {
	if (option.maximumElementsInOutput) {
		if (selectAll) {
			if (outputElementsSelection === 'default' || outputElementsSelection === undefined) {
				option.maximumElementsInOutput.current = 1
			} else if (outputElementsSelection === 'max') {
				option.maximumElementsInOutput.current = option.parent?.outputDropdownOptions?.length ?? 1
			}
		} else if (option.maximumElementsInOutput.current === 0) {
			option.maximumElementsInOutput.current = 1
		}
	}
	if (option.childs && option.maximumElementsInOutput) {
		option.childs.forEach((newChildSelected) => {
			const stringSplit = newChildSelected.elementId.split('_')
			if (option.maximumElementsInOutput?.current > 0) {
				const selectedElement = newSelectedElements.findIndex(
					(currentSelectedElement) => currentSelectedElement.elementId === newChildSelected.elementId
				)
				let isClassifSelected = false
				if (option.parent?.classifications)
					isClassifSelected =
						option.parent?.classifications?.find((classif) =>
							newChildSelected.elementId.startsWith(`${classif.id}`)
						)?.isChecked || false
				if (selectedElement === -1) {
					if (
						option.maximumElementsInOutput.current >= toNumber(stringSplit[stringSplit.length - 1]) &&
						(isClassifSelected || selectAll)
					)
						newSelectedElements.push(newChildSelected)
				} else if (option.maximumElementsInOutput.current < toNumber(stringSplit[stringSplit.length - 1])) {
					newSelectedElements.splice(selectedElement, 1)
				}
			}
		})
	}
}
const evaluateElementByMultiSelect = (
	newSelectedElements: Array<ElementUIFacade>,
	option: ElementUIFacade,
	selectAll?: boolean
) => {
	if (option.childs && option.parent?.multipleChildrenSelector) {
		if (
			selectAll ||
			(option.parent.multipleChildrenSelector.selectedOptions.length === 0 &&
				option.parent.multipleChildrenSelector.dropdownOptions.length !== 0)
		) {
			option.parent.multipleChildrenSelector.dropdownOptions.forEach((dropdownOption) => {
				if (!option.parent?.multipleChildrenSelector?.selectedOptions.includes(dropdownOption.value)) {
					option.parent?.multipleChildrenSelector?.selectedOptions.push(dropdownOption.value)
				}
			})
		}

		option.parent.multipleChildrenSelector.selectedOptions.forEach((selected) => {
			const childToAdd = option.childs?.find((child) => child.elementId.startsWith(selected))
			if (childToAdd) {
				const selectedElement = newSelectedElements.find(
					(currentSelectedElement) => currentSelectedElement.elementId === childToAdd.elementId
				)
				if (!selectedElement) {
					newSelectedElements.push(childToAdd)
				}
			}
		})
	}
}

const evaluateElementsByNested = ({
	newSelectedElements,
	option,
	selectAll,
	outputElementsSelection
}: {
	newSelectedElements: Array<ElementUIFacade>
	option: ElementUIFacade
	selectAll?: boolean
	outputElementsSelection?: OutputElementsSelection
}) => {
	if (option.childs) {
		if (option.maximumElementsInOutput) {
			if (selectAll && outputElementsSelection === 'max') {
				option.maximumElementsInOutput.current = option.outputDropdownOptions?.length ?? 0
			} else {
				option.maximumElementsInOutput.current = 1
			}
		}
		option.childs.forEach((level1Child) => {
			level1Child.childs?.forEach((level2Child, index) => {
				if (level1Child.isSelectedByDefault) {
					if (
						selectAll &&
						outputElementsSelection === 'max' &&
						isElementSelected(newSelectedElements, level2Child)
					) {
						newSelectedElements.push(level2Child)
					} else {
						if (index === 0 || (selectAll && outputElementsSelection === 'max')) {
							newSelectedElements.push(level2Child)
						}
					}
				} else {
					if (level1Child.maximumElementsInOutput) {
						if (selectAll && outputElementsSelection === 'max') {
							level1Child.maximumElementsInOutput.current = level1Child.outputDropdownOptions?.length ?? 0
						} else {
							level1Child.maximumElementsInOutput.current = 1
						}
					}
					if (level2Child.childs) {
						if (selectAll) {
							if (outputElementsSelection === 'max') {
								level2Child.childs.forEach((deepChild) => {
									newSelectedElements.push(deepChild)
								})
							} else {
								level2Child.childs.forEach((deepChild) => {
									subjectCommentsPrefixes.level2Prefixes.forEach((prefix) => {
										const elementIdStr = `${subjectCommentsPrefixes.level1Prefixes[1]}1_${prefix}1`
										if (deepChild.elementId.includes(elementIdStr)) {
											newSelectedElements.push(deepChild)
										}
									})
								})
							}
						} else {
							const elementIdStr = `${subjectCommentsPrefixes.level1Prefixes[1]}1_${subjectCommentsPrefixes.level2Prefixes[0]}1`
							const elementToSelect = level2Child.childs.find((deepChild) =>
								deepChild.elementId.includes(elementIdStr)
							)
							if (elementToSelect) {
								newSelectedElements.push(elementToSelect)
							}
						}
					}
				}
			})
		})
	}
}

export const evaluateChildsToAddToSelected = ({
	newSelectedElements,
	option,
	selectAll,
	outputElementsSelection
}: {
	newSelectedElements: Array<ElementUIFacade>
	option: ElementUIFacade
	selectAll?: boolean
	outputElementsSelection?: OutputElementsSelection
}) => {
	if (option.childs && option.dataTable) {
		newSelectedElements.push(...option.childs)
	} else if (
		option.childs &&
		option.maximumElementsInOutput &&
		!option.containsChildrenSelector &&
		!option.isRangesAndFiguresPattern &&
		option.pattern !== Pattern.CHECKBOX_GROUP_PATTERN &&
		option.parent?.pattern !== Pattern.CHECKBOX_GROUP_PATTERN &&
		!option.parent?.isClassificationsPattern
	) {
		evaluateChildrenBySelector({ newSelectedElements, option, selectAll, outputElementsSelection })
	} else if (
		option.childs &&
		(option.isRangesAndFiguresPattern ||
			option.pattern === Pattern.CHECKBOX_GROUP_PATTERN ||
			option.parent?.pattern === Pattern.CHECKBOX_GROUP_PATTERN)
	) {
		evaluateElementByRangesAndFiguresSelect(newSelectedElements, option, selectAll)
	} else if (option.childs && option.parent?.multipleChildrenSelector) {
		evaluateElementByMultiSelect(newSelectedElements, option, selectAll)
	} else if (option.childs && option.parent?.isClassificationsPattern) {
		evaluateElementByClassif({ newSelectedElements, option, selectAll, outputElementsSelection })
	} else if (option.isNestedSelector) {
		evaluateElementsByNested({ newSelectedElements, option, selectAll, outputElementsSelection })
	} else {
		if (option.isClassificationsPattern && option.childs) {
			option.classifications?.forEach((classif, idx) => {
				if (option.classifications) {
					option.classifications[idx].isChecked = true
				}
			})
			option.childs.forEach((element) =>
				evaluateChildsToAddToSelected({
					newSelectedElements,
					option: element,
					selectAll,
					outputElementsSelection
				})
			)
		} else {
			option.childs?.map((blockChild) => {
				if (blockChild.childs && blockChild.dataTable) {
					const existChildInSelectedArray = newSelectedElements.find((selectedElement) =>
						selectedElement.elementId.includes(blockChild.elementId)
					)
					if (!existChildInSelectedArray) newSelectedElements.push(...blockChild.childs)
				} else {
					const existChildInSelectedArray = newSelectedElements.find((selectedElement) => {
						let stringCompare = blockChild.elementId
						if (option.isClassificationsPattern) {
							stringCompare = `_${blockChild.elementId}_`
						}
						return selectedElement.elementId.includes(stringCompare)
					})
					if (!existChildInSelectedArray) {
						if (blockChild.childs) {
							evaluateChildsToAddToSelected({
								newSelectedElements,
								option: blockChild,
								selectAll,
								outputElementsSelection
							})
						} else {
							newSelectedElements.push(blockChild)
						}
					}
				}
			})
		}
	}
}

export const evaluateChildrenToDeleteSelected = (
	newSelectedElements: Array<ElementUIFacade>,
	option: ElementUIFacade,
	defaultElements?: Array<ElementUIFacade>
): Array<ElementUIFacade> => {
	let tempSelectedElements: Array<ElementUIFacade> = [...newSelectedElements]
	if (option.childs) {
		const alwaysSelectedArrays = [...disabledArrayElements]
		option.childs.forEach((blockChild) => {
			if (!alwaysSelectedArrays.includes(option.elementId) || !blockChild.isSelectedByDefault) {
				tempSelectedElements = evaluateChildrenToDeleteSelected(tempSelectedElements, blockChild)
			}
		})
		if (
			!alwaysSelectedArrays.includes(option.elementId) &&
			option.maximumElementsInOutput &&
			(option.containsChildrenSelector ||
				(option.parent &&
					option.parent.childs &&
					countSelectedElementChildren(option.parent.childs, tempSelectedElements, defaultElements) === 0))
		) {
			option.maximumElementsInOutput.current = 0
		} else if (option.multipleChildrenSelector) {
			option.multipleChildrenSelector.selectedOptions = []
		}
		if (option.pattern === Pattern.CHECKBOX_GROUP_PATTERN) {
			option.checkboxGroupOptions = option.checkboxGroupOptions?.map((checkboxOption) => {
				return { ...checkboxOption, isChecked: false }
			})
		}
	} else {
		const idxElement = tempSelectedElements.findIndex((selectedElement) => {
			return selectedElement.elementId === option.elementId
		})
		if (idxElement !== -1) {
			tempSelectedElements.splice(idxElement, 1)
		}
	}
	return tempSelectedElements
}

export const getAllElementsToSelect = (
	checked: boolean,
	filteredElements: Array<ElementUIFacade>,
	selectedElementList: Array<ElementUIFacade>,
	defaultElements?: Array<ElementUIFacade>,
	activeTradeUp?: boolean,
	enableEmailVerification?: boolean,
	EVIDS?: Array<string>,
	outputElementsSelection?: OutputElementsSelection
) => {
	let newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
	filteredElements?.forEach((cbElement) => {
		if (
			!isElementCheckedByDefault(cbElement.elementId, defaultElements) &&
			(!activeTradeUp || (activeTradeUp && !isElementTradeUp(cbElement.elementId))) &&
			!(enableEmailVerification === false && EVIDS?.includes(cbElement.elementId))
		) {
			let foundSelectedElementIndex = newSelectedElements.findIndex(
				(currentSelectedElement) => currentSelectedElement.elementId === cbElement.elementId
			)
			if (checked) {
				if (foundSelectedElementIndex < 0) {
					if (cbElement.childs) {
						const alwaysMaxSelectionArrays = [...disabledArrayElements]
						const shouldBeMax = alwaysMaxSelectionArrays.includes(cbElement.elementId)
						evaluateChildsToAddToSelected({
							newSelectedElements,
							option: cbElement,
							selectAll: true,
							outputElementsSelection: shouldBeMax ? 'max' : outputElementsSelection
						})
					} else {
						newSelectedElements.push(cbElement)
					}
				}
			} else {
				if (foundSelectedElementIndex >= 0) {
					foundSelectedElementIndex = newSelectedElements.findIndex(
						(currentSelectedElement) => currentSelectedElement.elementId === cbElement.elementId
					)
					newSelectedElements.splice(foundSelectedElementIndex, 1)
				} else {
					newSelectedElements = evaluateChildrenToDeleteSelected(
						newSelectedElements,
						cbElement,
						defaultElements
					)
				}
			}
		}
	})
	return newSelectedElements
}

export const areAllElementsInTreeSelected = (
	selectedElementList: Array<ElementUIFacade>,
	element: ElementUIFacade
): boolean => {
	if (element.childs) {
		return element.childs.every((level1Child) => {
			if (level1Child.outputDropdownOptions && level1Child.outputDropdownOptions.length > 0) {
				return level1Child.childs?.every((level2Child) => {
					return level2Child.childs?.every((deepChild) => {
						return isElementSelected(selectedElementList, deepChild)
					})
				})
			} else {
				return level1Child.childs?.every((deepChild) => {
					return isElementSelected(selectedElementList, deepChild)
				})
			}
		})
	} else {
		return false
	}
}

const areChildrenSelected = (selectedElementList: Array<ElementUIFacade>, element?: ElementUIFacade): boolean => {
	if (
		element?.childs &&
		((element?.maximumElementsInOutput && !element?.containsChildrenSelector && element?.parent) ||
			element.parent?.multipleChildrenSelector ||
			element.parent?.isClassificationsPattern)
	) {
		return element.childs.some((childElement) =>
			selectedElementList.some((selectedElement) => selectedElement.elementId.includes(childElement.elementId))
		)
	} else if (element?.isNestedSelector) {
		if (element?.elementId.includes('subject')) {
			if (element.childs) {
				return element.childs.every((level1Child) => {
					if (level1Child.containsChildrenSelector) {
						if (level1Child.childs) {
							return level1Child.childs?.every((level2Child) => {
								return areChildrenSelected(selectedElementList, level2Child)
							})
						}
					} else {
						return areChildrenSelected(selectedElementList, level1Child)
					}
				})
			}
		}
	} else {
		let areChildsSelected = false
		if (element?.childs) {
			areChildsSelected = element?.childs?.every((elementChild) => {
				if (elementChild.childs) {
					return areChildrenSelected(selectedElementList, elementChild)
				} else {
					return selectedElementList.some((selectedElement) =>
						selectedElement.elementId.includes(elementChild.elementId)
					)
				}
			})
		}
		if (!areChildsSelected && element?.parent?.isNestedSelector) {
			areChildsSelected = selectedElementList.some((selectedElement) =>
				selectedElement.elementId.includes(element?.elementId)
			)
		}

		if (
			(element?.isRangesAndFiguresPattern || element?.pattern === Pattern.CHECKBOX_GROUP_PATTERN) &&
			element?.maximumElementsInOutput
		) {
			element.maximumElementsInOutput.current = 0
			element.checkboxGroupOptions?.map((checkboxOption) => {
				//TODO remove findIndex and join with find after all checkbox patterns have been moved to new implementation
				const selectedElement = selectedElementList.findIndex(
					(currentSelectedElement) =>
						currentSelectedElement.elementId.includes(checkboxOption.id) ||
						checkboxOption.elements?.includes(currentSelectedElement.elementId)
				)
				if (selectedElement > -1 && element.maximumElementsInOutput) {
					element.maximumElementsInOutput.current += 1
				}
				const foundElement = selectedElementList.find((currentSelectedElement) =>
					checkboxOption.elements?.includes(currentSelectedElement.elementId)
				)
				if (foundElement) {
					checkboxOption.isChecked = true
				}
			})
			return (
				element?.maximumElementsInOutput?.current == element.checkboxGroupOptions?.length && areChildsSelected
			)
		} else {
			return areChildsSelected
		}
	}
}

export const setRefInitialValue = (
	selectorValue: MutableRefObject<number>,
	prefixes: Array<string>,
	selectedElements?: Array<Element>,
	infixes?: Array<string>
) => {
	if (selectedElements) {
		const selectedMap = new Map<string, number>()
		if (infixes) {
			let elem = 0
			for (const prefix of prefixes) {
				elem = 0
				for (const infix of infixes) {
					selectedElements.map((selectedElement: Element) => {
						if (selectedElement.elementId.includes(`${infix}${prefix}`)) {
							elem++
						}
					})
					if (elem > 0) {
						break
					}
				}
				if (elem > 0) {
					break
				}
			}
			selectorValue.current = elem
		} else {
			for (const prefix of prefixes) {
				for (const selectedElement of selectedElements) {
					if (selectedElement.elementId.startsWith(prefix)) {
						selectedMap.set(prefix, (selectedMap.get(prefix) || 0) + 1)
					}
				}
				if (selectedMap.size > 0) {
					selectorValue.current = selectedMap.values().next().value
					break
				}
			}
			if (selectedMap.size === 0 && selectorValue.current !== 0) {
				selectorValue.current = 0
			}
		}
	}
}

export const setInitialRefNestedValues = ({
	selectorValue1,
	selectorValue2,
	selectedElements,
	level1Prefixes,
	level2Prefixes
}: {
	selectorValue1: MutableRefObject<number>
	selectorValue2: MutableRefObject<number>
	selectedElements?: Array<Element>
	level1Prefixes: Array<string>
	level2Prefixes: Array<string>
}) => {
	const subjectCommentDateElements = selectedElements?.filter((element) =>
		element.elementId.includes(level1Prefixes[0])
	)
	selectorValue1.current = subjectCommentDateElements?.length ?? 0

	/* LEVEL 2 */
	let subjectCommentariesElements: ElementUIFacade[] = []
	if (selectedElements) {
		subjectCommentariesElements = selectedElements.filter((element) =>
			element.elementId.includes(level1Prefixes[1])
		)
	}

	const selectedPrefixes: string[] = []
	level2Prefixes.forEach((prefix) => {
		let elementIncluded = false
		subjectCommentariesElements.forEach((element) => {
			if (element.elementId.includes(prefix)) {
				elementIncluded = true
			}
		})

		if (elementIncluded) {
			selectedPrefixes.push(prefix)
		}
	})

	const filteredLevel2Elements = subjectCommentariesElements.filter((level2Element) => {
		const elementIdStr = `${level1Prefixes[1]}1_${selectedPrefixes[0]}`
		if (level2Element.elementId.includes(elementIdStr)) {
			return level2Element
		}
	})

	selectorValue2.current = filteredLevel2Elements.length
}

export const getElementIdSuffix = (elementId: string) => {
	const elementSuffixArray = elementId.split('_')
	if (elementSuffixArray.length > 3) return `${elementSuffixArray[2]}_${elementSuffixArray[3]}`
	else return ''
}

export const getElementIdPrefix = (elementId: string) => {
	const elementPrefixArray = elementId.split('_')
	if (elementPrefixArray.length > 3) return `${elementPrefixArray[0]}`
	else return ''
}

/* isElementIdSubstringExists is used for getting substring of elementId without aging bucket values.
	-3 refers to 030, 060, etc aging values. */
export const isElementIdSubstringExists = (prefixes: any, elementId: string) => {
	if (elementId.indexOf('_') >= 0)
		return prefixes.indexOf(elementId.substring(0, elementId.lastIndexOf('_') - 3)) >= 0
	else return false
}

export const isMandatoryElementadded = (elementId: string, selectedElementList: Array<ElementUIFacade>) => {
	const mandatoryElementAry = mandatoryElements[elementId as keyof typeof mandatoryElements]
	if (mandatoryElementAry?.length) {
		return selectedElementList.some((currentObj: ElementUIFacade) => {
			return (
				mandatoryElementAry.indexOf(
					currentObj.elementId.substring(0, currentObj.elementId.lastIndexOf('_')) + '_'
				) > -1
			)
		})
	} else {
		return false
	}
}

// New Implementation of flattened arrays configuration
export const getAllElementsBelongingToRequiredGroup = (
	flattenedArrayConfigurationList: Array<FlattenedArrayConfiguration>
) => {
	return flattenedArrayConfigurationList.reduce(
		(elementsBelongingToRequiredGroup: Array<string>, flattenedArrayConfiguration) => {
			const displayedElementsInRequiredGroup = flattenedArrayConfiguration.patternConfig.displayedElements.reduce(
				(elementsInRequiredGroup: Array<string>, displayedElement) => {
					if (displayedElement.belongsToRequiredGroup) {
						elementsInRequiredGroup.push(...displayedElement.elements)
					}
					return elementsInRequiredGroup
				},
				[]
			)
			return [...elementsBelongingToRequiredGroup, ...displayedElementsInRequiredGroup]
		},
		[]
	)
}

export const getAllElementsSelectedByDefault = (
	flattenedArrayConfigurationList: Array<FlattenedArrayConfiguration>
) => {
	return flattenedArrayConfigurationList.reduce(
		(elementsSelectedByDefault: Array<string>, flattenedArrayConfiguration) => {
			const displayedElementsSelectedByDefault =
				flattenedArrayConfiguration.patternConfig.displayedElements.reduce(
					(displayElSelectedByDefault: Array<string>, displayedElement) => {
						if (displayedElement.isSelectedByDefault) {
							displayElSelectedByDefault.push(...displayedElement.elements)
						}
						return displayElSelectedByDefault
					},
					[]
				)
			return [...elementsSelectedByDefault, ...displayedElementsSelectedByDefault]
		},
		[]
	)
}

export const getElementsBelongingToRequiredGroup = (arrayElement: ElementUIFacade) => {
	return (
		arrayElement.childs?.reduce((requiredElementIds: Array<string>, child: ElementUIFacade) => {
			if (child.belongsToRequiredGroup) {
				const primeColumnIds =
					child.childs?.reduce((elementIds: Array<string>, primeColumnChild: ElementUIFacade) => {
						elementIds.push(primeColumnChild.elementId)
						return elementIds
					}, []) || []
				requiredElementIds.push(...primeColumnIds)
			}
			return requiredElementIds
		}, []) || []
	)
}

export const setRefInitialValueByArrayConfiguration = (
	selectorValue: MutableRefObject<number>,
	flattenedConfiguration: FlattenedArrayConfiguration,
	selectedElementList?: Array<Element>
) => {
	if (selectedElementList) {
		let selectedChildrenCount = 0
		for (const displayedElement of flattenedConfiguration.patternConfig.displayedElements) {
			const elementsFoundInSelection = selectedElementList.filter((selectedElement) =>
				displayedElement.elements.includes(selectedElement.elementId)
			)
			if (elementsFoundInSelection.length > 0) {
				selectedChildrenCount = elementsFoundInSelection.length
				selectorValue.current = selectedChildrenCount
				break
			}
		}
		if (selectedChildrenCount === 0 && selectorValue.current !== 0) {
			selectorValue.current = 0
		}
	}
}
// New Implementation of flattened arrays configuration
