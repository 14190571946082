import Papaparse, { ParseResult } from 'papaparse'

class UploadParser {
	totalRows = async (file: File, delimiter = '') => {
		let totalRows = 0
		await this.parse(file, 0, delimiter).then((result) => {
			if (result.length > 0) {
				totalRows = result.length - 1
			}
		})
		return totalRows
	}
	parse = async (file: File, rowLimit = 0, delimiter = ''): Promise<string[][]> => {
		let rows: [][] = []
		const parserPromise = new Promise((resolve, reject) => {
			Papaparse.parse(file, {
				complete: (result) => resolve(result),
				error: (err) => reject(err),
				preview: rowLimit,
				chunk: (chunkRows: ParseResult<[]>) => {
					rows = rows.concat(chunkRows.data).filter((row: string[]) => row.some((item) => item.trim() !== ''))
				},
				worker: true,
				delimiter: delimiter,
				fastMode: false
			})
		})

		await parserPromise

		return rows
	}

	delimiter = async (file: File) => {
		let detectedDelimiter = ''
		const delimiterPromise = new Promise<string>((resolve, reject) => {
			Papaparse.parse(file, {
				complete: (result) => resolve(result?.meta?.delimiter),
				error: (err) => reject(err),
				preview: 1,
				step: (results) => {
					if (results.errors.length > 0) {
						reject(results.errors.toString())
					} else {
						resolve(results.meta.delimiter)
					}
				},
				worker: true,
				fastMode: false
			})
		})
		try {
			detectedDelimiter = await delimiterPromise
			if (detectedDelimiter != ',' && detectedDelimiter != ';') {
				throw new Error('Delimiter not supported')
			} else {
				return detectedDelimiter
			}
		} catch (error) {
			throw new Error("Can't process file")
		}
	}
}

export default new UploadParser()
