import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAccessLevel } from '../../hooks/useEntitlements'
import { useAppDispatch } from '../../store'
import { setSelectedConnectionName } from '../../store/connection/connectionSlice'
import { View } from '../connections-filter-bar/connections-filter-bar'
import { TabIndexValue } from '../edit-connection/edit-connection'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import styles from './sourceConnection.module.scss'

interface SourceConnectionProps {
	connectionDisplayName: string
}

export const SourceConnection: FC<SourceConnectionProps> = ({ connectionDisplayName }: SourceConnectionProps) => {
	const { t } = useTranslation()
	const isAdmin = useAccessLevel(['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'])
	const bannerMsj = isAdmin ? 'connection.source.disconnected.admin' : 'connection.source.disconnected.no.admin'
	const history = useHistory()
	const dispatch = useAppDispatch()

	const onReauth = () => {
		dispatch(setSelectedConnectionName(connectionDisplayName))
		history.push(`/connections?view=${View.detail}&tab=${TabIndexValue.edit}`)
	}

	return (
		<div className={styles.bannerContainer}>
			<Snackbar
				title={t('connection.source.broken')}
				type={SnackType.warning}
				message={t(bannerMsj) || ''}
				linkText={isAdmin ? t('connection.reauthenticate') || '' : undefined}
				onClickLink={onReauth}
				isBanner
			/>
		</div>
	)
}
