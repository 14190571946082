export interface FlattenedArrayConfiguration {
	arrayId: string
	arrayName: string
	arrayDescription: string
	createDate: Date
	blockId: string
	pattern: Pattern
	elements: Array<string>
	patternConfig: PatternConfig
	deprecated: boolean
}

export enum Pattern {
	SINGLE_DROPDOWN_PATTERN = 'SINGLE_DROPDOWN_PATTERN',
	CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN = 'CHECKBOX_GROUP_WITH_DROPDOWN_PATTERN',
	CHECKBOX_GROUP_PATTERN = 'CHECKBOX_GROUP_PATTERN'
}

export interface PatternConfig {
	displayedElements: Array<DisplayedElement>
	dropdownMaxValue?: number
	checkboxGroupName?: string
	checkboxOptions?: Array<CheckboxOption>
}

export interface DisplayedElement {
	name: string
	elements: Array<string>
	belongsToRequiredGroup: boolean
	isSelectedByDefault: boolean
}

export interface CheckboxOption {
	key: string
	name: string
	elements: Array<string>
}
