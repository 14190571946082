import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionSource, getConnectionSources } from './api/getConnectionSources'

export const useConnectionSources = (
	connectionName: string,
	inactiveSourcesDisplay?: boolean
): UseQueryResult<ConnectionSource[]> => {
	const apiClient = useApi()
	const queryKey = ['getConnectionSources', connectionName]
	const queryFunction = () => getConnectionSources(apiClient, connectionName, inactiveSourcesDisplay)

	return useQuery(queryKey, queryFunction)
}
