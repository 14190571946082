import { DNBCheckbox } from '@dnb-uux-design-system/react'
import { t } from 'i18next'
import { ChangeEvent, ReactElement } from 'react'
import { AccordionPanel } from '../../local-core-ui'
import { DataBlockElementSelector } from '../data-block-element-selector/data-block-element-selector'
import { ChildrenTree } from './children-tree'
import styles from './children-tree.module.scss'
import { ElementUIFacade } from './ElementUIFacade'
import { isElementChecked, isElementDisabled } from './helpers/element-tree-helpers'

interface CheckboxSubelementsProps {
	indexChild: number
	blockChild: ElementUIFacade
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	mandatoryIdArray: Array<string>
}

export const getChildName = (blockChild: ElementUIFacade) => {
	let label = blockChild.displayName
	if (blockChild.dataTable) {
		label += `(${blockChild.dataTable?.length} ${t('text.columns')})`
	}
	return label
}

export const CheckboxSubelements = ({
	indexChild,
	blockChild,
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	mandatoryIdArray
}: CheckboxSubelementsProps): ReactElement => {
	const blockChildren = element.childs || []

	const haveSomeSubElemInArray = (element: ElementUIFacade, elementId: string) => {
		if (element.isClassificationsPattern) {
			if (element?.childs && element?.classifications) {
				const numProviders = element.maximumElementsInOutput?.current || 1
				const classifSelected = element.classifications?.filter((classif) => classif.isChecked).length
				let elementsWithSubelementSelected = 0
				elementsWithSubelementSelected = selectedElementList.filter((selectedElement) =>
					selectedElement.elementId.includes('_' + elementId)
				).length
				if (
					elementsWithSubelementSelected > 0 &&
					elementsWithSubelementSelected < numProviders * classifSelected
				) {
					return true
				}
			}
			return false
		}
		return false
	}
	return (
		<div
			className={`${indexChild !== 0 && !blockChild.childs ? styles.containerItemSmall : styles.containerItem} ${
				indexChild === blockChildren.length - 1 && !blockChild.childs ? styles.lastChildContainerItem : ''
			}`}
			key={blockChild.elementId + 'subpanel-' + index}
			data-testid="container-item-subelements"
		>
			<DNBCheckbox
				size="small"
				id={t(blockChild.elementId)}
				label={getChildName(blockChild)}
				checked={
					blockChild.isSelectedByDefault ||
					(blockChild.childs
						? isElementChecked(selectedElementList, blockChild.elementId, defaultElements, true, blockChild)
						: isElementChecked(selectedElementList, blockChild.elementId, defaultElements))
				}
				disabled={blockChild.isSelectedByDefault || isElementDisabled(blockChild.elementId)}
				data-testid={testId + '-dbes-cb-child' + index}
				onChange={(e) => onChange(e, blockChild)}
				error={
					mandatoryIdArray && mandatoryIdArray.length > 0
						? mandatoryIdArray.find((ele) => ele === blockChild?.parent?.displayName)
							? 'Selection missing'
							: ''
						: ''
				}
				indeterminate={haveSomeSubElemInArray(element, blockChild.elementId)}
			/>
			{blockChild.hideElementDetail ? undefined : (
				<DataBlockElementSelector
					element={blockChild}
					dataBlockName={blockChild.blockId || ''}
					level={!blockChild.childs || !blockChild.showChildrenBlocks ? blockChild.level : undefined}
					dataTable={blockChild.dataTable}
					showBlockInformation={showBlockInformation && !!blockChild.dataTable}
				/>
			)}
			{blockChild.childs && blockChild.showChildrenBlocks ? (
				<AccordionPanel id={testId + '-accordion-panel' + index} isOpen={true} existControlElement={true}>
					<ChildrenTree
						index={indexChild}
						element={blockChild}
						testId={testId}
						selectedElementList={selectedElementList}
						onChange={onChange}
						defaultElements={defaultElements}
						showBlockInformation={showBlockInformation}
						mandatoryIdArray={mandatoryIdArray}
					/>
				</AccordionPanel>
			) : (
				<></>
			)}
		</div>
	)
}
