import { DNBTooltip } from '@dnb-uux-design-system/react'
import { ChangeEvent, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import information from '../../../../../assets/images/i-grey.svg'
import { RadioButton, ToolTip } from '../../../../../local-core-ui'
import styles from './match-recommendation-profiles.module.scss'

interface MappedRule {
	value: string
	label: string
}

interface MatchRecommendationProfilesProps {
	rulesOf: string
	profilesOptions: Array<MappedRule>
	onSelectProfile(option: string): void
	profileSelected: string
	group: string
	disabled?: boolean
}
export function MatchRecommendationProfiles({
	rulesOf,
	profilesOptions,
	onSelectProfile,
	profileSelected,
	group,
	disabled = false
}: MatchRecommendationProfilesProps): ReactElement {
	const { t } = useTranslation()
	const rulesOfKey = rulesOf.replaceAll(' ', '')
	const onChangeProfile = (event: ChangeEvent<HTMLInputElement>) => {
		onSelectProfile(event.target.value)
	}

	return (
		<div className={styles.matchRecommendationProfilesContainer}>
			<div>
				{t(`title.profile.${rulesOfKey}`)}
				<ToolTip
					customContent={
						<div style={{ fontSize: '1.2rem', minWidth: '220px' }}>
							<Trans i18nKey={`tooltip.text.${rulesOfKey}`} />
						</div>
					}
					effect="solid"
					position={'right'}
					testId={`match-profiles-tool-tip.${rulesOfKey}`}
				>
					<span data-testid={`match-profiles-tool-tip.${rulesOfKey}`} className={styles.svgSpan}>
						<img
							data-testid={`match-profiles-svg-image.${rulesOfKey}`}
							className={styles.svgImage}
							src={information}
							alt={'information'}
						/>
					</span>
				</ToolTip>
			</div>
			<DNBTooltip
				key={`tooltip-${rulesOf}`}
				arrow
				content={disabled ? t('wizard.matching.step.parallel.pass.license.basic') : null}
				placement="right"
			>
				<div className={styles.containerGroupRadio}>
					{profilesOptions.map(({ value, label }) => {
						const valueId = `${rulesOf}-${value}`

						return (
							<div className={styles.containerRadio} key={`radioContainer-${rulesOfKey}-${value}`}>
								<RadioButton
									value={value}
									group={group}
									id={valueId}
									label={t(label)}
									onChange={(e) => onChangeProfile(e)}
									checked={profileSelected === value}
									testId={valueId}
									disabled={disabled}
								/>
							</div>
						)
					})}
				</div>
			</DNBTooltip>
		</div>
	)
}
