import cx from 'classnames'
import { t } from 'i18next'
import { ChangeEvent, ReactElement, useRef, useState } from 'react'
import { CheckBox, Grid } from '../../local-core-ui'
import arrayPrefixes from '../data-block-searcher/array-prefixes.json'
import mandatoryElements from '../data-block-searcher/arrayMandatoryElesAndAlerts.json'
import styles from './checkbox-group-pattern.module.scss'
import { CheckboxGroupOption, ElementUIFacade } from './ElementUIFacade'
import {
	getElementIdPrefix,
	getElementIdSuffix,
	getElementsBelongingToRequiredGroup,
	isElementChecked,
	isElementIdSubstringExists
} from './helpers/element-tree-helpers'

interface CheckboxGroupProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const CheckboxGroup = ({
	element,
	selectedElementList,
	onDropSelectorChange,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: CheckboxGroupProps): ReactElement => {
	const [checkboxOptions, setCheckboxOptions] = useState(element.checkboxGroupOptions)
	const checkboxOptionsRef = useRef<Array<CheckboxGroupOption>>([])
	if (element.checkboxGroupOptions && element.checkboxGroupOptions?.length > 0) {
		checkboxOptionsRef.current = element.checkboxGroupOptions
	}

	//TODO remove suffix and prefix after all checkbox patterns have been moved to new implementation
	const isCheckboxOptionChecked = (
		selectedElementList: Array<ElementUIFacade>,
		checkboxOption: CheckboxGroupOption
	) => {
		const checkboxElementIdPrefix = checkboxOption.value.split('_')[0]
		const selectedIndex = selectedElementList.findIndex((selectedElement) => {
			const elementSuffix = getElementIdSuffix(selectedElement.elementId)
			const elementPrefix = getElementIdPrefix(selectedElement.elementId)
			return (
				(elementSuffix === checkboxOption.id && elementPrefix === checkboxElementIdPrefix) ||
				checkboxOption.elements?.includes(selectedElement.elementId)
			)
		})
		return selectedIndex > -1
	}

	const addChildrenByCheckboxOption = (checkboxOption: CheckboxGroupOption): Array<ElementUIFacade> => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const childrenSelectorValueRef = element.maximumElementsInOutput

		if (childrenSelectorValueRef) {
			const blockChildren = element.childs || []
			blockChildren.forEach((blockChild) => {
				const isChildChecked = isElementChecked(
					selectedElementList,
					blockChild.elementId,
					undefined,
					true,
					blockChild
				)
				if (isChildChecked || blockChild.isSelectedByDefault) {
					blockChild.childs?.forEach((elem) => {
						//TODO remove the check by checkboxOption.id after all checkbox patterns have been moved to new implementation
						if (
							elem.elementId.includes(checkboxOption.id) ||
							checkboxOption.elements?.includes(elem.elementId)
						) {
							const selectedElementIndex = newSelectedElements.findIndex(
								(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
							)
							if (selectedElementIndex < 0) {
								newSelectedElements.push(elem)
							} else if (selectedElementIndex >= 0) {
								newSelectedElements.splice(selectedElementIndex, 1)
							}
						}
					})
				}
			})
		}
		return newSelectedElements
	}

	const checkboxOptionChangeFunction = (
		event: ChangeEvent<HTMLInputElement>,
		checkboxOption: CheckboxGroupOption
	) => {
		checkboxOptionsRef?.current?.forEach((val) => {
			if (val.value === event.target.value) val.isChecked = event.target.checked
		})
		setCheckboxOptions(checkboxOptionsRef.current)
		if (element.maximumElementsInOutput) {
			element.maximumElementsInOutput.current =
				checkboxOptionsRef?.current?.filter((elem) => elem.isChecked === true).length || 0
			element.checkboxGroupOptions = checkboxOptionsRef?.current
			if (onDropSelectorChange) {
				onDropSelectorChange(addChildrenByCheckboxOption(checkboxOption))
			}
		}
		const mandatoryValidation = checkboxOptionsRef?.current?.some((elem) => {
			return elem.isChecked === true
		})
		if (mandatoryValidation) {
			checkMandatoryStatus(element)
		} else {
			removeMandatoryCheck(element.displayName)
		}
	}

	const checkMandatoryStatus = (option: ElementUIFacade) => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const prefixes: Array<string> = mandatoryElements[option?.elementId as keyof typeof mandatoryElements]
		const totalPrefixes: Array<string> = arrayPrefixes[option?.elementId as keyof typeof arrayPrefixes]
		const mandatoryIdExists = totalPrefixes?.some((element) => prefixes?.includes(element))
		if (option?.elementId && mandatoryIdExists && prefixes) {
			const mandatoryError = newSelectedElements.some((element) =>
				isElementIdSubstringExists(prefixes, element.elementId)
			)
			if (!mandatoryError && mandatoryIdArray.indexOf(option.displayName) < 0) {
				setMandatoryIdArray([...mandatoryIdArray, option.displayName])
				errorBannerVisibility([...mandatoryIdArray, option.displayName])
			} else {
				if (mandatoryError && mandatoryIdArray.indexOf(option.displayName) >= 0) {
					const updatedMandatoryArray = mandatoryIdArray.filter((ele) => {
						return ele !== option?.displayName
					})
					setMandatoryIdArray(updatedMandatoryArray)
					errorBannerVisibility(updatedMandatoryArray)
				}
			}
		}
		// New Implementation of flattened arrays configuration
		const elementsInRequiredGroup = getElementsBelongingToRequiredGroup(option)
		if (
			elementsInRequiredGroup.length !== 0 &&
			!newSelectedElements.some((selectedElement) =>
				elementsInRequiredGroup.includes(selectedElement.elementId)
			) &&
			!mandatoryIdArray.includes(option.displayName)
		) {
			setMandatoryIdArray([...mandatoryIdArray, option.displayName])
			errorBannerVisibility([...mandatoryIdArray, option.displayName])
		}
		// New Implementation of flattened arrays configuration
	}
	const removeMandatoryCheck = (name: string) => {
		if (mandatoryIdArray.indexOf(name) >= 0) {
			mandatoryIdArray.splice(mandatoryIdArray.indexOf(name), 1)
			errorBannerVisibility(mandatoryIdArray)
			setMandatoryIdArray(mandatoryIdArray)
		}
	}
	return (
		<div>
			<div className={cx(styles.checkboxOptionsGroupContainer)}>
				{element.checkboxGroupName || t('aging.bucket')}
			</div>
			<Grid testId="container-checkbox-options-group" container>
				{checkboxOptions?.map((checkboxOption) => {
					return (
						<Grid testId="checkbox-option-padding" sm={6} key={checkboxOption.id}>
							<div className={styles.checkboxPaddingDiv}>
								<CheckBox
									id={checkboxOption.id}
									checked={isCheckboxOptionChecked(selectedElementList, checkboxOption)}
									value={checkboxOption.value}
									label={checkboxOption.label}
									testId={checkboxOption.id}
									onChange={(event) => checkboxOptionChangeFunction(event, checkboxOption)}
								/>
							</div>
						</Grid>
					)
				})}
			</Grid>
		</div>
	)
}
