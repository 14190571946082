import flatArrayPrefixObject from '../../../../components/data-block-searcher/array-prefixes.json'
import { ElementUIFacade } from '../../../../components/data-block-searcher/ElementUIFacade'
import {
	getAllElementsBelongingToRequiredGroup,
	isMandatoryElementadded
} from '../../../../components/data-block-searcher/helpers/element-tree-helpers'
import { TDispatch } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element, FlattenedArrayConfiguration } from '../../../../types'

export const removeElementsForArrayMandatory = (
	currentEnrichingLayout: Array<EnrichingBlock>,
	flattenedArraysConfiguration: Array<FlattenedArrayConfiguration>
) => {
	const newEnrichingLayout: Array<EnrichingBlock> = JSON.parse(JSON.stringify(currentEnrichingLayout))
	const ArrayBlockidsIncludesMandatory = {
		companyinfo: ['competitorsId', 'TSRCommodityCodesId', 'unspscId', 'customersId', 'supplierId']
	}
	let elementsToFilter: Array<string> = []

	elementsToFilter = currentEnrichingLayout.reduce(function (elementsToFilter: Array<string>, enrichEleObj) {
		const mandatoryBlockidsArray =
			ArrayBlockidsIncludesMandatory[enrichEleObj.blockId as keyof typeof ArrayBlockidsIncludesMandatory]
		if (mandatoryBlockidsArray?.length) {
			mandatoryBlockidsArray?.forEach((str) => {
				if (!isMandatoryElementadded(str, enrichEleObj.elements)) {
					elementsToFilter = elementsToFilter.concat(
						flatArrayPrefixObject[str as keyof typeof flatArrayPrefixObject]
					)
				}
			})
		}
		return elementsToFilter
	}, [])

	currentEnrichingLayout.forEach((enrichEleObj, index) => {
		const mandatoryBlockidsArray =
			ArrayBlockidsIncludesMandatory[enrichEleObj.blockId as keyof typeof ArrayBlockidsIncludesMandatory]
		if (mandatoryBlockidsArray?.length) {
			enrichEleObj.elements.forEach((elementObj) => {
				elementsToFilter.forEach((filterString) => {
					if (elementObj.elementId.startsWith(filterString)) {
						const idxToRemove = newEnrichingLayout[index].elements.findIndex(function (childObj) {
							return childObj.elementId === elementObj.elementId
						})
						idxToRemove > -1 ? newEnrichingLayout[index].elements.splice(idxToRemove, 1) : ''
					}
				})
			})
		}
	})

	// New Implementation of flattened arrays configuration
	currentEnrichingLayout.forEach((enrichEleObj, index) => {
		const arraysConfigByBlock = flattenedArraysConfiguration.filter(
			(arrayConfiguration) => arrayConfiguration.blockId.split('_', 1)[0] === enrichEleObj.blockId
		)
		const elementsToRemoveList = arraysConfigByBlock.reduce((elementsInArray: Array<string>, arrayConfig) => {
			const elementsInRequiredGroup = getAllElementsBelongingToRequiredGroup([arrayConfig])
			if (
				elementsInRequiredGroup.length > 0 &&
				!enrichEleObj.elements.some((element) => elementsInRequiredGroup.includes(element.elementId))
			) {
				elementsInArray.push(...arrayConfig.elements)
			}
			return elementsInArray
		}, [])
		for (const elementToRemove of elementsToRemoveList) {
			for (const selectedElement of enrichEleObj.elements) {
				if (selectedElement.elementId === elementToRemove) {
					const idxToRemove = newEnrichingLayout[index].elements.findIndex((childObj) => {
						return childObj.elementId === elementToRemove
					})
					if (idxToRemove > -1) {
						newEnrichingLayout[index].elements.splice(idxToRemove, 1)
					}
					break
				}
			}
		}
	})
	// New Implementation of flattened arrays configuration
	return newEnrichingLayout
}

export const updateAllEnrichingLayoutBlocks = (
	dispatch: TDispatch,
	selectedElements: Array<Element>,
	flattenedArraysConfiguration: Array<FlattenedArrayConfiguration>
): void => {
	const newEnrichingLayout: Array<EnrichingBlock> = []
	if (selectedElements.length > 0) {
		selectedElements.forEach((element: ElementUIFacade) => {
			const blockIdx = newEnrichingLayout.findIndex((block) => block.blockId === element.blockId)
			if (blockIdx !== -1) {
				newEnrichingLayout[blockIdx].elements.push({
					elementId: element.elementId,
					displayName: element.displayName,
					level: element.level,
					deprecated: element.deprecated,
					replacements: element.replacements
				} as ElementUIFacade)
			} else {
				const newBlock = {
					blockId: element.blockId || '',
					elements: [
						{
							elementId: element.elementId,
							displayName: element.displayName,
							level: element.level,
							deprecated: element.deprecated,
							replacements: element.replacements
						}
					]
				}
				newEnrichingLayout.push(newBlock)
			}
		})
	}
	const newEnrichingLayoutFiltered = removeElementsForArrayMandatory(newEnrichingLayout, flattenedArraysConfiguration)
	dispatch(updateCurrentProjectAction({ enrichingLayout: newEnrichingLayoutFiltered }))
}
